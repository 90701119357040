import { makeRestCall, restListHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import { createEffect } from "effector";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { TaskItemResponseDto } from "@state/tasks/dto/response/task.item.response.dto";

const baseUrl = configuration.backendBaseUrl;
const taskUrl = baseUrl + "/tasks";

export const requestGetTasks = createEffect({
  handler: restListHandler<TaskItemResponseDto>(taskUrl),
});

export const restExecuteTaskHandler = <T>(): ((
  id: string,
) => Promise<ParsedResponse<T>>) => {
  return (taskId: string) =>
    makeRestCall<void, T>(`${taskUrl}/execute?taskId=${taskId}`, "POST");
};

export const requestExecuteTask = createEffect({
  handler: restExecuteTaskHandler(),
});
