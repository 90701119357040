import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restDetailsHandler,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { RecordItemResponseDto } from "@state/records/dto/response/record.item.response.dto";
import { RecordSearchCriteria } from "@state/records/dto/request/record.search.criteria";
import { RecordDetailResponseDto } from "@state/records/dto/response/record.detail.response.dto";

const baseUrl = configuration.backendBaseUrl + "/admin";
const recordsUrl = baseUrl + "/records";

export const requestGetRecords = createEffect({
  handler: restListHandlerPagination<
    Pageable<RecordItemResponseDto>,
    RecordSearchCriteria
  >(recordsUrl),
});

export const requestGetRecord = createEffect({
  handler: restDetailsHandler<RecordDetailResponseDto>(recordsUrl),
});
