import { FunctionComponent } from "react";
import { UsersImportSynthesisDto } from "@state/users/dto/response/users.import.synthesis.dto";
import { useTranslation } from "react-i18next";

interface UsersImportSynthesisProps {
  synthesis: UsersImportSynthesisDto;
}

const UsersImportSynthesis: FunctionComponent<UsersImportSynthesisProps> = ({
  synthesis,
}) => {
  const { t } = useTranslation();

  return (
    <div className="import-synthesis">
      <h3
        data-alt={t("users.import.synthesis.title")}
        data-test-for="users-import-synthesis"
      >
        {t("users.import.synthesis.title")}
      </h3>
      <ul data-test="users-import-synthesis">
        <li data-test="synthesis-line">
          {t("users.import.synthesis.created", { quantity: synthesis.created })}
        </li>
        <li data-test="synthesis-line">
          {t("users.import.synthesis.updated", { quantity: synthesis.updated })}
        </li>
        <li data-test="synthesis-line">
          {t("users.import.synthesis.errors", {
            quantity: synthesis.errors.length,
          })}
        </li>
      </ul>
      {synthesis && synthesis.errors && synthesis.errors.length > 0 && (
        <>
          <h3>{t("users.import.synthesis.errors-title")}</h3>
          <ul>
            {synthesis.errors.map((error, index) => (
              <li key={`error_${index}`}>{error.message}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
export default UsersImportSynthesis;
