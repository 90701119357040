import { FunctionComponent, useEffect, useState } from "react";
import FileFormField from "@components/inputs/FileFormField";
import { DOMAINS } from "@utils/enums/domain.enum";
import BasicButton from "@components/buttons/BasicButton";
import { Form } from "antd";
import { requestUsersImport } from "@state/users/UserEffects";
import { useTranslation } from "react-i18next";
import { File } from "@type/file/file.types";
import { UsersImportSynthesisDto } from "@state/users/dto/response/users.import.synthesis.dto";
import UsersImportSynthesis from "@components/imports/UsersImportSynthesis";
import { USERS_IMPORT_EXTENSIONS } from "@utils/enums/authorized.extensions.enum";
import { toastError, toastSuccess, toastWarning } from "@utils/toast-helper";

const UsersImportForm: FunctionComponent = () => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);
  const [synthesis, setSynthesis] = useState<UsersImportSynthesisDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    return requestUsersImport.done.watch(({ result }) => {
      if (result.ok && result.data) {
        if (result.data) {
          setSynthesis(result.data);

          setFiles([]);

          if (result.data.errors.length === 0) {
            toastSuccess(t("users.import.messages.success"));
          } else {
            if (result.data.created > 0 || result.data.updated > 0) {
              toastWarning(t("users.import.messages.warning"));
            } else {
              toastError(t("users.import.messages.error"));
            }
          }
        }
      } else {
        toastError(t("users.import.messages.error"));
      }
      setLoading(false);
    });
  });

  const handleUsersImportSubmit = (): void => {
    setLoading(true);
    void requestUsersImport({ dto: { file: files[0] } });
  };

  return (
    <>
      <h3 className="text-secondary mb-4">{t("users.import.users-title")}</h3>
      <Form onFinish={handleUsersImportSubmit} form={form}>
        <FileFormField
          field="importFile"
          files={files}
          setFiles={setFiles}
          domain={DOMAINS.userImports}
          module={"users.import"}
          text={"users.import.file.import"}
          editMode
          loading={loading}
          types={USERS_IMPORT_EXTENSIONS}
        />
        <div className="d-flex justify-content-center">
          <BasicButton
            variant="primary"
            type="submit"
            text={t("users.import.buttons.validate")}
            className="my-3"
          />
        </div>
        {synthesis && <UsersImportSynthesis synthesis={synthesis} />}
      </Form>
    </>
  );
};
export default UsersImportForm;
