import { Button, Col, Form, Modal, Popconfirm, Row, Spin } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "@type/form/field.types";
import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { requestSelfUpdateUser } from "@state/users/UserEffects";
import { toast } from "react-toastify";
import { formHelper } from "@utils/form-helper";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import MailFormField from "@components/inputs/MailFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { PROFILES } from "@utils/enums/profile.enum";
import BasicButton from "@components/buttons/BasicButton";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { AuthentifiedUser } from "@type/auth/auth.types";
import { UserSelfUpdateRequestDto } from "@state/users/dto/request/user.self.update.request.dto";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

export interface Props {
  originAuthUser: AuthentifiedUser;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const { confirm } = Modal;

const prefix = "users.edit.form.main";

interface MyAccountFormData {
  firstName: string;
  lastName: string;
  email: string;
}

const MyAccountForm: FunctionComponent<Props> = (props: Props) => {
  // PROPS
  const { originAuthUser, editMode, setEditMode } = props;

  // GENERIC HOOKS
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  // STATES
  const [user, setUser] = useState<AuthentifiedUser>(originAuthUser);

  const [fields, setFields] = useState<Field[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      const firstNameField = form.getFieldValue("firstName") as string;
      const lastNameField = form.getFieldValue("lastName") as string;
      const profileField = t(
        `users.labels.profiles.${form.getFieldValue("profile") as string}`,
      );

      confirm({
        title: t("users.edit.modals.save.title"),
        content: t("users.edit.modals.save.content", {
          user: `${firstNameField} ${lastNameField}`,
          profile: profileField,
        }),
        okText: <span data-test="confirm-button">{t("buttons.yes")}</span>,
        cancelText: <span data-test="cancel-button">{t("buttons.no")}</span>,
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  // EFFECTS
  useEffect(() => {
    if (originAuthUser.id) {
      void requestGetUserInfo({});
    }
  }, [originAuthUser]);

  useEffect(() => {
    return requestGetUserInfo.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        const user = result.data;
        setUser(user);
        setFields([
          {
            name: ["firstName"],
            value: user.firstName,
          },
          {
            name: ["lastName"],
            value: user.lastName,
          },
          {
            name: ["email"],
            value: user.email,
          },
          {
            name: ["profile"],
            value: user.profile,
          },
        ]);
      } else {
        void toast.error(t("users.errors.not-found"));
      }
    });
  });

  useEffect(() => {
    return requestSelfUpdateUser.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        setUser({
          ...user,
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          email: result.data.email,
        });
        void toast.success(t("users.edit.messages.success"));
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "users.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  const handleSubmit = (values: MyAccountFormData) => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setLoading(true);
        const userToSave: UserSelfUpdateRequestDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        };
        void requestSelfUpdateUser({
          id: originAuthUser.id,
          dto: userToSave,
        });
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    void toast.error(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <>
      {!getResponseReceived && originAuthUser ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <Form
          {...formValidateTriggers}
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <h3 className="text-secondary mb-4">{t(`${prefix}.title`)}</h3>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="firstName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="lastName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <MailFormField
                showLabel
                type="email"
                module={prefix}
                field="email"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <SelectFormField
                showLabel
                module={prefix}
                field="profile"
                required={true}
                readOnly={true}
                options={PROFILES}
              />
            </Col>
          </Row>
          {user && (
            <Row gutter={16}>
              <Col xs={24}>
                <BasicButton
                  text={t("users.edit.changePassword.button.label")}
                  variant="primary-outlined"
                  onClick={() =>
                    navigate(ROUTES.admin.account.updatePassword.generate())
                  }
                />
              </Col>
            </Row>
          )}
          <div className="d-flex align-items-center justify-content-end flex-wrap">
            {editMode && setEditMode && (
              <>
                <Popconfirm
                  title={t("forms.actions.modals.cancel.content")}
                  okText={t("buttons.yes")}
                  cancelText={t("buttons.no")}
                  onConfirm={() => {
                    setEditMode(false);
                  }}
                  placement="topRight"
                  icon={<QuestionCircleOutlined />}
                  className="mt-2"
                >
                  <Button>
                    <StopOutlined /> {t("buttons.cancel")}
                  </Button>
                </Popconfirm>
              </>
            )}
            {editMode ? (
              <BasicButton
                type="submit"
                variant="primary"
                icon={<SaveOutlined />}
                text={t("buttons.save")}
                isLoading={loading}
                className="ms-2 mt-2"
              />
            ) : (
              <>
                {setEditMode && (
                  <BasicButton
                    variant="primary"
                    icon={<EditOutlined />}
                    text={t("buttons.edit")}
                    onClick={() => setEditMode(true)}
                    className="ms-2 mt-2"
                  />
                )}
              </>
            )}
          </div>
        </Form>
      )}
    </>
  );
};
export default MyAccountForm;
