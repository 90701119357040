import { AuthentifiedContext } from "@type/auth/auth.types";
import { ColumnsType } from "antd/lib/table/interface";
import i18n from "i18next";
import BasicTag from "@components/tags/BasicTag";
import { UserStatus, UserStatusEnum } from "@utils/enums/user.status.enum";
import { alignRight } from "@utils/Constant";
import React from "react";
import TableButton from "@components/buttons/TableButton";
import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { showConfirm } from "@components/modals/ConfirmModal";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { EmployeeItemResponseDto } from "@state/employees/dto/response/employee.item.response.dto";
import { requestUpdateEmployeeStatus } from "@state/employees/EmployeeEffects";

export const columns = (
  authentifiedContext: AuthentifiedContext | null,
): ColumnsType<EmployeeItemResponseDto> => [
  {
    title: i18n.t<string>("users.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
    sorter: true,
    render: (text: string) => {
      return <span data-test="table-item">{text}</span>;
    },
  },
  {
    title: i18n.t<string>("employees.list.columns.email"),
    key: "email",
    dataIndex: "email",
    ellipsis: false,
    sorter: true,
  },
  {
    title: i18n.t<string>("employees.list.columns.phoneNumber"),
    key: "phoneNumber",
    dataIndex: "phoneNumber",
    ellipsis: false,
    sorter: true,
  },
  {
    title: i18n.t<string>("employees.list.columns.profile"),
    key: "profile",
    dataIndex: "profile",
    ellipsis: false,
    sorter: true,
    render: (text: string) => {
      return <span>{i18n.t(`users.labels.profiles.${text}`)}</span>;
    },
  },
  {
    title: i18n.t<string>("employees.list.columns.status"),
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    render: (status: string) => (
      <BasicTag
        variant={UserStatus[status] && UserStatus[status].variant}
        label={i18n.t(UserStatus[status] && UserStatus[status].label)}
        className="d-flex justify-content-center"
      />
    ),
  },
  {
    title: i18n.t<string>("employees.list.columns.actions"),
    key: "actions",
    fixed: alignRight,
    className: "it-actions",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: EmployeeItemResponseDto) => (
      <div className="d-inline-flex">
        {authentifiedContext?.user?.id !== record.id && (
          <TableButton
            icon={
              record.status !== UserStatusEnum.ACTIVE ? (
                <CheckOutlined />
              ) : (
                <StopOutlined />
              )
            }
            tooltipTitle={i18n.t(
              `employees.list.actions.${
                record.status !== UserStatusEnum.ACTIVE ? "enable" : "disable"
              }`,
            )}
            onClick={() => {
              showConfirm(
                i18n.t(
                  `employees.edit.modals.${
                    record.status !== UserStatusEnum.ACTIVE
                      ? "enable"
                      : "disable"
                  }.title`,
                  {
                    user: record.name,
                  },
                ),
                () => {
                  requestUpdateEmployeeStatus({
                    id: record.id,
                    dto: {
                      status:
                        record.status !== UserStatusEnum.ACTIVE
                          ? UserStatusEnum.ACTIVE
                          : UserStatusEnum.DISABLED,
                    },
                  })
                    .then((resp) => {
                      if (resp.ok) {
                        toastSuccess(
                          i18n.t("users.list.messages.change-status-done"),
                        );
                        // void notifyUserStatusUpdate({
                        //   user: record,
                        //   status: resp.data ? resp.data.status : "",
                        // });
                      }
                    })
                    .catch(() => toastError("An error occurred"));
                },
              );
            }}
            buttonClassName={`btn-icon ${
              record.status !== UserStatusEnum.ACTIVE
                ? "btn-success"
                : "btn-danger"
            }`}
          />
        )}
      </div>
    ),
  },
];
