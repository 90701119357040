import { createEvent } from "effector";
import {
  AuthentifiedContext,
  AuthentifiedUser,
  AuthResult,
} from "@type/auth/auth.types";

export const authentifiedContextEvent = createEvent<
  AuthentifiedContext | undefined
>();

export const login = createEvent<AuthResult>();

export const setUser = createEvent<AuthentifiedUser>("SET_USER");

export const clearUser = createEvent<void>("CLEAR_USER");

export const logout = createEvent<void>();
