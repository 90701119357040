import { FunctionComponent } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { ROUTES } from "@utils/Routes";
import { useTranslation } from "react-i18next";
import EmployeeForm from "../details/EmployeeForm";

const EmployeeAddScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t("employees.add.title")}
      returnRoute={ROUTES.merchant.employees.list.generate()}
    >
      <>
        <EmployeeForm editMode />
      </>
    </PageLayout>
  );
};

export default EmployeeAddScreen;
