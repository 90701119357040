import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import parse from "html-react-parser";
import BasicButton from "@components/buttons/BasicButton";
import { ROUTES } from "@utils/Routes";

const CookiesScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  const translate = (...values: string[]): string => {
    const fields = values.map((value: string): string => `.${value}`).join("");
    return t(`footer.cookies${fields}`);
  };

  return (
    <PageLayout
      noHeader
      noSideMenu
      title={t("footer.cookies.title")}
      noContentInCard={false}
      bodyClassName="PublicPage"
    >
      <>
        <h1>{translate("subtitle")}</h1>
        <hr />

        <h3 className="mt-5">{translate("what", "title")}</h3>
        <p>{translate("what", "definition")}</p>

        <p>
          <a
            href={translate("what", "link", "value")}
            target="_blank"
            rel="noreferrer"
          >
            {translate("what", "link", "name")}
          </a>
        </p>

        <h3 className="mt-5">{translate("which", "title")}</h3>

        <p>{parse(translate("which", "body"))}</p>
        {parse(translate("which", "content"))}

        <p className="d-flex align-items-center justify-content-center flex-wrap">
          <BasicButton
            text={t("buttons.back")}
            variant="tertiary"
            className="mt-2 me-2"
            onClick={() => history(ROUTES.admin.home.generate())}
          />
        </p>
      </>
    </PageLayout>
  );
};

export default CookiesScreen;
