import { FunctionComponent } from "react";
import { passwordHelper } from "@utils/password-helper";
import { useTranslation } from "react-i18next";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";

interface PasswordValidationTooltipProps {
  password: string;
  strengthConstraints: PasswordStrengthConstraintsDetailsResponseDto;
}

const PasswordValidationTooltip: FunctionComponent<
  PasswordValidationTooltipProps
> = ({ password, strengthConstraints }: PasswordValidationTooltipProps) => {
  const { t } = useTranslation();

  return (
    <div className="custom-error constant">
      {t("set-password.fields.credential.errors.title")}
      <ul>
        <li
          className={
            passwordHelper.validateCharacter(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            strengthConstraints.minCharacters == 1
              ? "set-password.fields.credential.errors.character"
              : "set-password.fields.credential.errors.character_plural",
            {
              count: strengthConstraints.minCharacters,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateUpperCase(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            strengthConstraints.minUpperCaseLetters == 1
              ? "set-password.fields.credential.errors.upperCase"
              : "set-password.fields.credential.errors.upperCase_plural",
            {
              count: strengthConstraints.minUpperCaseLetters,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateLowerCase(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            strengthConstraints.minLowerCaseLetters == 1
              ? "set-password.fields.credential.errors.lowerCase"
              : "set-password.fields.credential.errors.lowerCase_plural",
            {
              count: strengthConstraints.minLowerCaseLetters,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateNumber(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            strengthConstraints.minDigits == 1
              ? "set-password.fields.credential.errors.number"
              : "set-password.fields.credential.errors.number_plural",
            {
              count: strengthConstraints.minDigits,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateSpecial(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            strengthConstraints.minSpecialCharacters == 1
              ? "set-password.fields.credential.errors.special"
              : "set-password.fields.credential.errors.special_plural",
            {
              count: strengthConstraints.minSpecialCharacters,
            },
          )}
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidationTooltip;
