import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { requestGetUserInfo, requestULink } from "@state/auth/AuthEffects";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { AuthResult } from "@type/auth/auth.types";
import { setUser } from "@state/auth/AuthEvents";
import { ROUTES } from "@utils/Routes";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicButton from "@components/buttons/BasicButton";
import { toastError } from "@utils/toast-helper";

const ULinkScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>();

  const token = new URLSearchParams(location.search).get("token");
  const userId = new URLSearchParams(location.search).get("userId");

  const handleAuthentication = useCallback(() => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
        navigate(ROUTES.admin.home.generate());
      }
    });
  }, [navigate]);

  useEffect(() => {
    if (userId && token) {
      requestULink({
        dto: {
          userId,
          token,
        },
      })
        .then((authResult: ParsedResponse<AuthResult>) => {
          if (authResult.responseCode === 200 && authResult.data) {
            handleAuthentication();
          } else {
            if (authResult.errorCode) {
              toastError(t(`login.messages.errors.${authResult.errorCode}`));
              setErrorCode(authResult.errorCode);
            } else {
              toastError(t("login.messages.error"));
            }
          }
        })
        .catch(() => {
          toastError(t("login.messages.error"));
        })
        .finally(() => setIsLoading(false));
    }
  }, [userId, token, t, handleAuthentication]);

  return (
    <PageLayout
      noHeader
      noSideMenu
      noFooter
      title={t("login.title")}
      container={false}
      bodyClassName="app-error-screen pt-5 d-flex flex-column flex-xl-row align-items-center justify-content-center"
    >
      <>
        {isLoading && <span>Loading...</span>}
        {errorCode && (
          <>
            <div className="error-text-container d-flex flex-column align-items-center justify-content-center">
              <div className="error-text-404 mb-10">
                <WarningOutlined />
              </div>
              <h3>{t("ulink.title")}</h3>
              <div>{t(`ulink.messages.errors.${errorCode}`)}</div>
              <BasicButton
                text={t("ulink.buttons.login")}
                onClick={() => navigate(ROUTES.auth.login.generate())}
                className="mt-30"
              />
            </div>
          </>
        )}
      </>
    </PageLayout>
  );
};

export default ULinkScreen;
