import { FunctionComponent } from "react";

interface SectionProps {
  title: string | JSX.Element;
  children: string | JSX.Element;
  className?: string;
  titleClassName?: string;
  dataTestFor?: string;
}

const Section: FunctionComponent<SectionProps> = ({
  title,
  children,
  className = "",
  titleClassName = "",
  dataTestFor,
}) => {
  return (
    <section className={`app-section ${className}`}>
      <h2
        className={`mb-3 ${titleClassName}`}
        data-test="section-title"
        data-alt={title}
        data-test-for={dataTestFor}
      >
        {title}
      </h2>
      <div className="section-content" data-test={dataTestFor}>
        {children}
      </div>
    </section>
  );
};

export default Section;
