import { ROUTES } from "@utils/Routes";
import { BreadcrumbsItem } from "@components/breadcrumbs/Breadcrumbs";
import { HomeOutlined, TeamOutlined } from "@ant-design/icons";

export const homeBreadcrumbStep: BreadcrumbsItem = {
  link: ROUTES.admin.home.generate(),
  icon: <HomeOutlined />,
};

export const componentBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.components",
  },
];

export const taskBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.tasks",
  },
];

export const usersBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.users",
  },
];

export const userDetailBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    link: ROUTES.admin.users.list.generate(),
    icon: <TeamOutlined />,
    label: "breadcrumbs.users",
  },
  {
    label: "breadcrumbs.user-detail",
  },
];

export const employeeDetailBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    link: ROUTES.merchant.employees.list.generate(),
    icon: <TeamOutlined />,
    label: "breadcrumbs.employees",
  },
  {
    label: "breadcrumbs.employee-detail",
  },
];

export const employeesBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    link: ROUTES.merchant.employees.list.generate(),
    icon: <TeamOutlined />,
    label: "breadcrumbs.employees",
  },
];

export const bankAdminsBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.bankAdmins",
  },
];

export const bankAdminDetailBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    link: ROUTES.bankAdmins.list.generate(),
    icon: <TeamOutlined />,
    label: "breadcrumbs.bankAdmins",
  },
  {
    label: "breadcrumbs.bankAdmin-detail",
  },
];

export const merchantsBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.merchants",
  },
];

export const employeesManagersBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.employeesManagers",
  },
];

export const employeesManagerDetailBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    link: ROUTES.employeesManager.list.generate(),
    icon: <TeamOutlined />,
    label: "breadcrumbs.employeesManagers",
  },
  {
    label: "breadcrumbs.employeesManager-detail",
  },
];

export const recordsBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    label: "breadcrumbs.records",
  },
];

export const recordDetailBreadcrumb: BreadcrumbsItem[] = [
  homeBreadcrumbStep,
  {
    link: ROUTES.records.list.generate(),
    icon: <TeamOutlined />,
    label: "breadcrumbs.records",
  },
  {
    label: "breadcrumbs.record-detail",
  },
];
