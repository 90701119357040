import React from "react";
import { Tag } from "antd";

export interface Props {
  right: string;
  className?: string;
}

interface RightParams {
  code: string;
  color: string;
}

export const FEATURE_RIGHTS: { [index: string]: RightParams } = {
  CREATE: { code: "CREATE", color: "#49cc90" },
  GET: { code: "GET", color: "#61affe" },
  GETSELF: { code: "GET_SELF", color: "#61affe" },
  UPDATE: { code: "UPDATE", color: "#fca130" },
  SEARCH: { code: "SEARCH", color: "#61affe" },
  DELETE: { code: "DELETE", color: "#f93e3e" },
  DEFAULT: { code: "DEFAULT", color: "#aaaaaa" },
};

const FeatureRightTag: React.FunctionComponent<Props> = (props: Props) => {
  const { right, className } = props;

  const locateRight = (code: string): RightParams => {
    const result = Object.keys(FEATURE_RIGHTS)
      .filter((f) => FEATURE_RIGHTS[f].code === code)
      .map((f) => FEATURE_RIGHTS[f])[0];

    if (result) {
      return result;
    }

    return FEATURE_RIGHTS["DEFAULT"];
  };

  return (
    <Tag className={className} color={locateRight(right).color}>
      {right}
    </Tag>
  );
};

export default FeatureRightTag;
