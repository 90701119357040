import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { columns } from "@components/lists/TasksColumns";
import { requestGetTasks } from "@state/tasks/TaskEffects";
import { TaskItemResponseDto } from "@state/tasks/dto/response/task.item.response.dto";
import PageLayout from "@components/layouts/PageLayout";
import { taskBreadcrumb } from "@utils/Breadcrumbs";

const TasksScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [taskExecutions, setTaskExecutions] = useState<TaskItemResponseDto[]>(
    [],
  );

  useEffect(() => {
    void requestGetTasks({}).then((result) => {
      if (result.ok && result.data) {
        const taskExecutions = result.data;
        setTaskExecutions(taskExecutions);
      }
    });
  }, []);

  return (
    <PageLayout
      breadcrumbs={taskBreadcrumb}
      container
      noContentInCard={false}
      title={t("tasks.title")}
    >
      <>
        <Table
          locale={{ emptyText: t("table.emptyText.noData") }}
          columns={columns()}
          dataSource={taskExecutions}
          showSorterTooltip={false}
          rowKey={(record) => record.taskId}
          rowClassName="cursor-pointer"
        />
      </>
    </PageLayout>
  );
};

export default TasksScreen;
