import { FunctionComponent } from "react";

interface CardSectionProps {
  title: string | JSX.Element;
  children: string | JSX.Element;
  className?: string;
  titleClassName?: string;
}

const CardSection: FunctionComponent<CardSectionProps> = ({
  title,
  children,
  className = "",
  titleClassName = "",
}) => {
  return (
    <section className={`app-section section-card ${className}`}>
      <h2 className={`mb-3 ${titleClassName}`}>{title}</h2>
      <div>{children}</div>
    </section>
  );
};

export default CardSection;
