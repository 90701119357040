import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { employeesManagerDetailBreadcrumb } from "@utils/Breadcrumbs";
import EmployeesManagerForm from "./EmployeesManagerForm";
import { useParams } from "react-router-dom";

const EmployeesManagerScreen: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>();

  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout
      title={t(editMode ? "users.edit.title" : "users.details.title")}
      breadcrumbs={employeesManagerDetailBreadcrumb}
      container
      noContentInCard={false}
    >
      <EmployeesManagerForm
        userId={userId ? userId : ""}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </PageLayout>
  );
};

export default EmployeesManagerScreen;
