import { FunctionComponent } from "react";

interface BasicCardProps {
  children?: string | JSX.Element;
  onClick?: () => any;
  className?: string;
  dataTest?: string;
}

const BasicCard: FunctionComponent<BasicCardProps> = ({
  children,
  onClick,
  className = "",
  dataTest,
}) => {
  return (
    <div
      className={`app-card ${onClick ? "card-hover" : ""} ${className}`}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
    </div>
  );
};

export default BasicCard;
