import { OptionType } from "@type/form/field.types";

/* eslint-disable @typescript-eslint/naming-convention */
export enum RecordStatusEnum {
  CREATED = "CREATED",
  UPLOADED = "UPLOADED",
  KYC_OK = "KYC_OK",
  KYC_KO = "KYC_KO",
  PROCESSING = "PROCESSING",
  CONTACT_INFO_PROVIDED = "CONTACT_INFO_PROVIDED",
  PAYING = "PAYING",
  PAID = "PAID",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
}

export interface RecordStatusType {
  [key: string]: {
    value: string;
    label: string;
    variant: string;
  };
}

export const RecordStatus: RecordStatusType = {
  CREATED: {
    value: RecordStatusEnum.CREATED,
    label: "records.labels.status.CREATED",
    variant: "primary",
  },
  UPLOADED: {
    value: RecordStatusEnum.UPLOADED,
    label: "records.labels.status.UPLOADED",
    variant: "success",
  },
  KYC_OK: {
    value: RecordStatusEnum.KYC_OK,
    label: "records.labels.status.KYC_OK",
    variant: "primary",
  },
  KYC_KO: {
    value: RecordStatusEnum.KYC_KO,
    label: "records.labels.status.KYC_KO",
    variant: "danger",
  },
  PROCESSING: {
    value: RecordStatusEnum.PROCESSING,
    label: "records.labels.status.PROCESSING",
    variant: "primary",
  },
  CONTACT_INFO_PROVIDED: {
    value: RecordStatusEnum.CONTACT_INFO_PROVIDED,
    label: "records.labels.status.CONTACT_INFO_PROVIDED",
    variant: "primary",
  },
  PAYING: {
    value: RecordStatusEnum.PAYING,
    label: "records.labels.status.PAYING",
    variant: "primary",
  },
  PAID: {
    value: RecordStatusEnum.PAID,
    label: "records.labels.status.PAID",
    variant: "primary",
  },
  FINISHED: {
    value: RecordStatusEnum.FINISHED,
    label: "records.labels.status.FINISHED",
    variant: "success",
  },
  CANCELED: {
    value: RecordStatusEnum.CANCELED,
    label: "records.labels.status.CANCELED",
    variant: "grey",
  },
};

export const RECORDS_STATUS = [
  {
    value: RecordStatusEnum.CREATED,
    label: "records.labels.status.CREATED",
  },
  {
    value: RecordStatusEnum.UPLOADED,
    label: "records.labels.status.UPLOADED",
  },
  {
    value: RecordStatusEnum.PROCESSING,
    label: "records.labels.status.PROCESSING",
  },
  {
    value: RecordStatusEnum.KYC_OK,
    label: "records.labels.status.KYC_OK",
  },
  {
    value: RecordStatusEnum.KYC_KO,
    label: "records.labels.status.KYC_KO",
  },
  {
    value: RecordStatusEnum.CONTACT_INFO_PROVIDED,
    label: "records.labels.status.CONTACT_INFO_PROVIDED",
  },
  {
    value: RecordStatusEnum.PAYING,
    label: "records.labels.status.PAYING",
  },
  {
    value: RecordStatusEnum.FINISHED,
    label: "records.labels.status.FINISHED",
  },
  {
    value: RecordStatusEnum.CANCELED,
    label: "records.labels.status.CANCELED",
  },
] as OptionType[];
