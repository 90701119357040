import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SpinIcon } from "@components/icons/SpinIcon";
import { Table } from "antd";
import { columns } from "@components/lists/EndpointsColumns";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import { requestGetEndpoints } from "@state/app/AppEffects";
import { EndpointGroupDetailsResponseDto } from "@state/app/response/endpoint.group.details.response.dto";
import { toastError } from "@utils/toast-helper";

interface EndpointsScreenProps {
  profiles: ProfileDetailsResponseDto[];
}

const EndpointsScreen: FunctionComponent<EndpointsScreenProps> = ({
  profiles,
}) => {
  const { t } = useTranslation();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [endpoints, setEndpoints] = useState<EndpointGroupDetailsResponseDto[]>(
    [],
  );

  const fetchEndpoints = useCallback(() => {
    setDataHasLoaded(false);
    requestGetEndpoints({})
      .catch(() => {
        toastError(t("accesscontrol.endpoints.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t]);

  useEffect(() => {
    fetchEndpoints();
  }, [fetchEndpoints]);

  useEffect(() => {
    return requestGetEndpoints.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDataHasLoaded(true);
        setEndpoints(result.data);
      }
    });
  });

  return (
    <>
      {endpoints.map((endpointGroup, index) => (
        <React.Fragment key={index}>
          <h3 className="text-secondary mt-4 mb-3">{endpointGroup.label}</h3>
          <Table
            key={endpointGroup.label}
            style={{ whiteSpace: "break-spaces" }}
            locale={{
              emptyText: dataHasLoaded
                ? t("table.emptyText.noData")
                : t("table.emptyText.loading"),
            }}
            loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
            columns={columns(profiles)}
            pagination={false}
            dataSource={endpointGroup.endpoints}
            showSorterTooltip={false}
            rowKey={(record) =>
              `${record.urls.toString()}_${record.methods.toString()}`
            }
            rowClassName="cursor-pointer"
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default EndpointsScreen;
