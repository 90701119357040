import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardScreen from "@views/home/DashboardScreen";
import "./styles/styles.scss";
import "./utils/i18n/i18n";
import LoginScreen from "@views/auth/LoginScreen";
import UsersScreen from "@views/users/list/UsersScreen";
import UserAddScreen from "@views/users/add/UserAddScreen";
import UserScreen from "@views/users/details/UserScreen";
import ForgotPasswordScreen from "@views/auth/ForgotPasswordScreen";
import InitPasswordScreen from "@views/auth/InitPasswordScreen";
import ResetPasswordScreen from "@views/auth/ResetPasswordScreen";
import RegistrationScreen from "@views/auth/registration/RegistrationScreen";
import TasksScreen from "@views/tasks/TasksScreen";
import ComponentsScreen from "@views/components/ComponentsScreen";
import ToastAlert from "@components/alerts/ToastAlert";
import { ROUTES } from "@utils/Routes";
import AccessControlScreen from "@views/accesscontrol/AccessControlScreen";
import UsersImportScreen from "@views/users/imports/UsersImportScreen";
import CookiesBanner from "@components/banners/CookiesBanner";
import CookiesScreen from "@views/footer/CookiesScreen";
import MaintenanceScreen from "@views/maintenance/MaintenanceScreen";
import Error404Screen from "@views/error404/Error404Screen";
import ULinkScreen from "@views/auth/ULinkScreen";
import SelectUserScreen from "@views/e2e/SelectUserScreen";
import configuration from "@utils/Config";
import MyAccountScreen from "@views/users/details/MyAccountScreen";
import UpdatePasswordScreen from "@views/auth/UpdatePasswordScreen";
import AppInfosRibbon from "@components/ribbons/AppInfosRibbon";
import ConfirmRegistrationScreen from "@views/auth/registration/ConfirmRegistrationScreen";
import EventsScreen from "@views/events/EventsScreen";
import AuthLayout from "@components/routes/AuthLayout";
import ProfileLayout from "@components/routes/ProfileLayout";
import { ProfileEnum } from "@utils/enums/profile.enum";
import EmployeesScreen from "@views/employees/list/EmployeesScreen";
import EmployeeAddScreen from "@views/employees/add/EmployeeAddScreen";
import EmployeeScreen from "@views/employees/details/EmployeeScreen";
import BankAdminsScreen from "@views/bankAdmin/list/BankAdminsScreen";
import MerchantsScreen from "@views/merchants/list/MerchantsScreen";
import RecordsScreen from "@views/records/list/RecordsScreen";
import RecordScreen from "@views/records/details/RecordScreen";
import BankAdminScreen from "@views/bankAdmin/details/BankAdminScreen";
import BankAdminAddScreen from "@views/bankAdmin/add/BankAdminAddScreen";
import ServerUnreachableScreen from "@views/serverUnreachable/ServerUnreachableScreen";
import EmployeesManagerAddScreen from "@views/employeesManagers/add/EmployeesManagerAddScreen";
import EmployeesManagerScreen from "@views/employeesManagers/details/EmployeesManagerScreen";
import EmployeesManagersScreen from "@views/employeesManagers/list/EmployeesManagersScreen";

export const App: FC = () => {
  return (
    <div className="global">
      <CookiesBanner />
      {configuration.environment !== "production" && <AppInfosRibbon />}
      <ToastAlert />
      <Routes>
        <Route
          path={ROUTES.admin.components.generate()}
          element={<ComponentsScreen />}
        />

        <Route path={ROUTES.auth.login.generate()} element={<LoginScreen />} />
        <Route
          path={ROUTES.auth.forgotPassword.generate()}
          element={<ForgotPasswordScreen />}
        />
        <Route
          path={ROUTES.auth.initPassword.generate()}
          element={<InitPasswordScreen />}
        />
        <Route
          path={ROUTES.auth.resetPassword.generate()}
          element={<ResetPasswordScreen />}
        />

        <Route
          path={ROUTES.auth.registration.generate()}
          element={<RegistrationScreen />}
        />
        <Route
          path={ROUTES.auth.registrationConfirmation.generate()}
          element={<ConfirmRegistrationScreen />}
        />
        <Route path={ROUTES.cookie.generate()} element={<CookiesScreen />} />
        <Route path={ROUTES.auth.uLink.generate()} element={<ULinkScreen />} />

        <Route
          path={ROUTES.admin.account.infos.generate()}
          element={<MyAccountScreen />}
        />

        <Route element={<AuthLayout />}>
          <Route
            path={ROUTES.admin.account.updatePassword.generate()}
            element={<UpdatePasswordScreen />}
          />

          <Route
            path={ROUTES.admin.home.generate()}
            element={<DashboardScreen />}
          />

          <Route
            element={
              <ProfileLayout allowedProfiles={[ProfileEnum.BANK_ADMIN]} />
            }
          >
            <Route
              path={ROUTES.bankAdmins.list.generate()}
              element={<BankAdminsScreen />}
            />
            <Route
              path={ROUTES.bankAdmins.add.generate()}
              element={<BankAdminAddScreen />}
            />
            <Route
              path={ROUTES.bankAdmins.details.generate(":userId")}
              element={<BankAdminScreen />}
            />
            <Route
              path={ROUTES.employeesManager.add.generate()}
              element={<EmployeesManagerAddScreen />}
            />
            <Route
              path={ROUTES.employeesManager.details.generate(":userId")}
              element={<EmployeesManagerScreen />}
            />
          </Route>

          <Route
            element={
              <ProfileLayout
                allowedProfiles={[
                  ProfileEnum.BANK_ADMIN,
                  ProfileEnum.REPORTING,
                ]}
              />
            }
          >
            <Route
              path={ROUTES.merchant.list.generate()}
              element={<MerchantsScreen />}
            />
            <Route
              path={ROUTES.records.list.generate()}
              element={<RecordsScreen />}
            />
            <Route
              path={ROUTES.records.details.generate(":recordId")}
              element={<RecordScreen />}
            />
            <Route
              path={ROUTES.employeesManager.list.generate()}
              element={<EmployeesManagersScreen />}
            />
          </Route>

          <Route
            element={
              <ProfileLayout
                allowedProfiles={[
                  ProfileEnum.MERCHANT,
                  ProfileEnum.EMPLOYEES_MANAGER,
                ]}
              />
            }
          >
            <Route
              path={ROUTES.merchant.employees.list.generate()}
              element={<EmployeesScreen />}
            />
            <Route
              path={ROUTES.merchant.employees.add.generate()}
              element={<EmployeeAddScreen />}
            />
            <Route
              path={ROUTES.merchant.employees.details.generate(":userId")}
              element={<EmployeeScreen />}
            />
          </Route>

          <Route
            element={
              <ProfileLayout allowedProfiles={[ProfileEnum.SUPER_ADMIN]} />
            }
          >
            <Route
              path={ROUTES.admin.events.list.generate()}
              element={<EventsScreen />}
            />
            <Route
              path={ROUTES.admin.tasks.list.generate()}
              element={<TasksScreen />}
            />
            <Route
              path={ROUTES.admin.endpoints.generate()}
              element={<AccessControlScreen />}
            />
            <Route
              path={ROUTES.admin.users.list.generate()}
              element={<UsersScreen />}
            />
            <Route
              path={ROUTES.admin.users.add.generate()}
              element={<UserAddScreen />}
            />
            <Route
              path={ROUTES.admin.users.imports.generate()}
              element={<UsersImportScreen />}
            />
            <Route
              path={ROUTES.admin.users.details.generate(":userId")}
              element={<UserScreen />}
            />
          </Route>
        </Route>

        {["e2e", "integration"].includes(configuration.environment) && (
          <Route
            path={ROUTES.e2e.users.generate()}
            element={<SelectUserScreen />}
          />
        )}

        <Route
          path={ROUTES.maintenance.generate()}
          element={<MaintenanceScreen />}
        />
        <Route
          path={ROUTES.unreachable.generate()}
          element={<ServerUnreachableScreen />}
        />
        <Route
          path={ROUTES.public.home.generate()}
          element={<Navigate to={ROUTES.admin.home.generate()} />}
        />
        <Route element={<Error404Screen />} />
      </Routes>
    </div>
  );
};

export default App;
