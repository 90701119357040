import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

interface FilterCardProps {
  children?: string | JSX.Element;
  resetFields?: () => any;
  className?: string;
}

const FilterCard: FunctionComponent<FilterCardProps> = ({
  children,
  resetFields,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <BasicCard className={`card-filter ${className}`}>
      <>
        <div className="d-flex align-items-start flex-wrap">
          <h3 className="filter-title mb-0">{t("table.filter.title")}</h3>
          {resetFields && (
            <div className="filter-reset flex-grow-1 d-flex justify-content-end">
              <BasicButton
                variant="tertiary"
                text={t("table.filter.resetFilters")}
                onClick={resetFields}
                className="ms-3"
                data-test="reset-filters"
              />
            </div>
          )}
        </div>
        <div className="filter-content">{children}</div>
      </>
    </BasicCard>
  );
};

export default FilterCard;
