import { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authentifiedStore } from "@state/auth/AuthStore";
import { useStore } from "effector-react";
import { ROUTES } from "@utils/Routes";
import BasicButton from "@components/buttons/BasicButton";
import { logout } from "@state/auth/AuthEvents";

interface HeaderProps {
  className?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const navigate = useNavigate();

  return (
    <header
      className={`d-flex align-items-center justify-content-between ${className}`}
    >
      <div className="logo-container h-100 d-flex align-items-center">
        <Link to={ROUTES.admin.home.generate()}>
          <img alt={t("header.logo.alt")} className="logo" src="/logo.png" />
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-wrap me-4">
        {authentifiedContext && authentifiedContext.user ? (
          <div className="user-container d-flex flex-column align-items-end">
            <div className="user-container d-flex align-items-center">
              <div className="d-flex align-items-center h-100 pr-3 me-1">
                <BasicButton
                  variant="transparent-black"
                  text={
                    <strong>{`${t("login.hello")}, ${
                      authentifiedContext.user.firstName
                    } ${authentifiedContext.user.lastName}`}</strong>
                  }
                  className="p-0 text-normal"
                  onClick={() => {
                    navigate(ROUTES.admin.account.infos.generate());
                  }}
                />
              </div>
              <div className="d-flex align-items-center h-100 pl-3">
                (
                {t(`users.labels.profiles.${authentifiedContext.user.profile}`)}
                )
              </div>
            </div>
            <div className="user-container d-flex align-items-center h-100 pl-3 pr-3">
              <Link to={ROUTES.auth.login.generate()} onClick={() => logout()}>
                {t("login.actions.logout")}
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  );
};

export default Header;
