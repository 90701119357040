import shortid from "shortid";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { InternalNamePath } from "rc-field-form/lib/interface";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  FileFilled,
} from "@ant-design/icons";
import { getBase64 } from "@utils/base64-utils";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { File } from "src/type/file/file.types";
import { DOMAINS } from "@utils/enums/domain.enum";
import { ExtensionType } from "@type/form/field.types";
import { toastError } from "@utils/toast-helper";

interface PropsType {
  field: string | InternalNamePath;
  module?: string;
  unique?: boolean;
  files?: File[] | undefined;
  setFiles?: (files: File[]) => void;
  types?: ExtensionType[];
  text: string;
  // MaxSize en Mo
  maxSize?: number;
  formatLabel?: string;
  disabled?: boolean;
  onChange?: (files: File[]) => void;
  domain: DOMAINS;
  editMode?: boolean;
  loading: boolean;
}

const FileFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    field,
    module = "default",
    files,
    setFiles,
    types,
    text,
    maxSize = 10,
    unique = true,
    disabled = false,
    formatLabel,
    onChange,
    domain,
    loading,
  } = props;

  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (files) {
      setFileList(
        files.map((f, index) => ({
          uid: `${f.id || index}`,
          name: f.name,
          resourceId: f.resourceId,
          status: "done",
          url: `data:${f.type};base64,${f.base64Data ? f.base64Data : ""}`,
          size: f.size,
          type: f.type,
        })),
      );
    }
  }, [files]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {t(t(`${text}`), {
          formatLabel,
        })}
      </div>
    </div>
  );

  const uploadProps = {
    showUploadList: false,
    customRequest({ file }: UploadRequestOption) {
      getBase64(file as RcFile, (fileUrl) => {
        const request: File = {
          id: shortid.generate(),
          name: (file as RcFile).name,
          base64Data: fileUrl as string,
          size: (file as RcFile).size,
          type: (file as RcFile).type,
          domain: domain,
        };

        const newFiles = files ? [...files] : [];
        newFiles.push(request);
        setFiles && setFiles(newFiles);
        if (onChange) {
          onChange(newFiles);
        }
      });
      return {};
    },
  };

  const beforeUpload = (
    file: RcFile,
    maxSize: number,
    types?: string[],
    typesLabel?: string[],
  ) => {
    const isLt2M = file.size / 1024 / 1024 < maxSize;
    if (!isLt2M) {
      toastError(t(`${module}.file.sizeError`));
    }
    const isTypeOk = types ? types.includes(file.type) : true;
    if (!isTypeOk) {
      toastError(
        t("forms.errors.extension-invalid", {
          okExtensions: typesLabel,
        }),
      );
    }
    return isLt2M && isTypeOk;
  };

  const handlePreview = (file: UploadFile) => {
    if (!file.url && !file.preview) {
      getBase64(file.originFileObj as Blob, (fileUrl) => {
        file.preview = fileUrl as string;
      });
    }
  };

  const handleRemoveFile = (fileId: string) => {
    if (files) {
      const newArray = [...files].filter((f) => f.id !== fileId);
      setFiles && setFiles(newArray);
      if (onChange) {
        onChange(newArray);
      }
    }
  };

  return (
    <>
      {fileList.map((file) => (
        <div
          key={file.name}
          className="ant-upload ant-upload-drag avatar-uploader ant-upload-file"
        >
          <span
            tabIndex={0}
            className="ant-upload ant-upload-btn"
            role="button"
          >
            <input type="file" accept="" style={{ display: "none" }} />
            <div className="ant-upload-drag-container">
              <div>
                <FileFilled />
                <div data-test="filename" className="filename ms-2 me-4">
                  {file.name}
                </div>
                <DeleteOutlined
                  className="delete-icon"
                  onClick={() => handleRemoveFile(file.uid)}
                />
              </div>
            </div>
          </span>
        </div>
      ))}
      {(!unique || fileList.length < 1) && (
        <Upload
          name={`${field ? String(field) : "file"}`}
          listType="picture-card"
          className="file-uploader"
          {...uploadProps}
          fileList={fileList}
          beforeUpload={(file: RcFile) =>
            beforeUpload(
              file,
              maxSize,
              types && types.flatMap((t) => t.type),
              types && types.map((t) => t.label),
            )
          }
          onPreview={handlePreview}
          disabled={disabled || loading}
          type="drag"
        >
          <div data-test="file-input">{uploadButton}</div>
        </Upload>
      )}
    </>
  );
};

export default FileFormField;
