import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import { EventItemResponseDto } from "@state/events/dto/response/event.item.response.dto";
import DateFormat from "@utils/DateFormat";

export const columns = (): ColumnsType<EventItemResponseDto> => [
  {
    title: i18n.t<string>("events.list.columns.entity-type"),
    key: "entityType",
    dataIndex: "entityType",
    ellipsis: false,
    sorter: true,
    render: (value: string) =>
      i18n.t<string>(`events.labels.categories.${value}`),
  },
  {
    title: i18n.t<string>("events.list.columns.event-type"),
    key: "eventType",
    dataIndex: "eventType",
    ellipsis: false,
    sorter: true,
    render: (value: string) => {
      return {
        children: (
          <span data-test="table-item">
            {i18n.t<string>(`events.labels.event-types.${value}`)}
          </span>
        ),
      };
    },
  },
  {
    title: i18n.t<string>("events.list.columns.entity-name"),
    key: "entityName",
    dataIndex: "entityName",
    ellipsis: false,
    sorter: true,
  },
  {
    title: i18n.t<string>("events.list.columns.operator-name"),
    key: "operatorName",
    dataIndex: "operatorName",
    ellipsis: false,
    sorter: true,
  },
  {
    title: i18n.t<string>("events.list.columns.date"),
    key: "date",
    dataIndex: "date",
    ellipsis: false,
    sorter: true,
    render: (value: Date) => DateFormat.standardDate(new Date(value)),
  },
];
