import { Col, Form, Row, Spin } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "@type/form/field.types";
import { LoadingOutlined } from "@ant-design/icons";
import { toastError } from "@utils/toast-helper";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import InputFormField from "../../../components/inputs/InputFormField";
import { requestGetRecord } from "@state/records/RecordEffects";
import { RecordDetailResponseDto } from "@state/records/dto/response/record.detail.response.dto";
import { formUtils } from "@utils/form-utils";
import DateFormat from "@utils/DateFormat";
import CheckboxFormField from "@components/inputs/CheckboxFormField";

export interface Props {
  recordId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const prefix = "records.edit.form.main";

const mapRecordFields = (record: RecordDetailResponseDto): Field[] => {
  const fields = formUtils.mapFieldsFrom(record);

  const createdAtField = fields.find((field) =>
    field.name.includes("createdAt"),
  );
  const birthDateField = fields.find((field) =>
    field.name.includes("birthDate"),
  );
  const updatedAtField = fields.find((field) =>
    field.name.includes("updatedAt"),
  );
  if (createdAtField?.value) {
    createdAtField.value = DateFormat.standardDateIso(
      new Date(createdAtField.value as string),
    );
  }
  if (updatedAtField?.value) {
    updatedAtField.value = DateFormat.standardDateIso(
      new Date(updatedAtField.value as string),
    );
  }
  if (birthDateField?.value) {
    birthDateField.value = DateFormat.standardDateIso(
      new Date(birthDateField.value as string),
    );
  }

  return fields;
};

const RecordForm: FunctionComponent<Props> = (props: Props) => {
  // PROPS
  const { recordId, editMode } = props;

  // GENERIC HOOKS
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // STATES
  const [record, setRecord] = useState<RecordDetailResponseDto | null>(null);

  const [fields, setFields] = useState<Field[]>([]);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // EFFECTS
  useEffect(() => {
    if (recordId) {
      void requestGetRecord({ id: recordId });
    }
  }, [recordId]);

  useEffect(() => {
    return requestGetRecord.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        const record = result.data;
        setRecord(record);
        setFields(mapRecordFields(record));
      } else {
        toastError(t("record.errors.not-found"));
      }
    });
  });

  return (
    <>
      {!getResponseReceived && recordId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !recordId || record ? (
        <Form
          {...formValidateTriggers}
          layout="vertical"
          form={form}
          fields={fields}
        >
          <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
            <h3 className="flex-grow-1 text-secondary mb-3">
              {t(`${prefix}.titleRecord`)}
            </h3>
          </div>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="ticketNumber"
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="contactMethod"
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="kycControlId"
                readOnly={!editMode}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="status"
                readOnly={!editMode}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="createdAt"
                readOnly={!editMode}
                type="date"
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="updatedAt"
                readOnly={!editMode}
                type="date"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <CheckboxFormField
                module={prefix}
                field="firebaseMessageId"
                initialValue={!!record?.firebaseMessage}
                readOnly={!editMode}
              />
            </Col>
          </Row>

          <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
            <h3 className="flex-grow-1 text-secondary mb-3">
              {t(`${prefix}.titleCustomer`)}
            </h3>
          </div>
          <Row gutter={16}>
            <Col xs={24} sm={4}>
              <InputFormField
                showLabel
                module={prefix}
                field="civility"
                readOnly={!editMode}
              />
            </Col>
            <Col xs={24} sm={10}>
              <InputFormField
                showLabel
                module={prefix}
                field="firstName"
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={10}>
              <InputFormField
                showLabel
                module={prefix}
                field="lastName"
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="birthPlace"
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="birthDate"
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
                type="date"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="email"
                readOnly={!editMode}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="phone"
                readOnly={!editMode}
              />
            </Col>
          </Row>
          <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
            <h3 className="flex-grow-1 text-secondary mb-3">
              {t(`${prefix}.titleMerchant`)}
            </h3>
          </div>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="corporateName"
                readOnly={!editMode}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="contractReference"
                readOnly={!editMode}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="merchantFirstName"
                readOnly={!editMode}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="merchantLastName"
                readOnly={!editMode}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="merchantEmail"
                readOnly={!editMode}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="merchantPhone"
                readOnly={!editMode}
              />
            </Col>
          </Row>
        </Form>
      ) : (
        <div>{t("record.errors.not-found")}</div>
      )}
    </>
  );
};

export default RecordForm;
