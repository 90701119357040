import { ROUTES } from "@utils/Routes";
import { ProfileEnum } from "@utils/enums/profile.enum";
import {
  BranchesOutlined,
  ClockCircleOutlined,
  FolderOutlined,
  GroupOutlined,
  ShopOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

export const DASHBOARD_MENU = [
  {
    key: "components",
    link: ROUTES.admin.components.generate(),
    icon: <GroupOutlined />,
    text: "dashboard.cards.components",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
  {
    key: "users",
    link: ROUTES.admin.users.list.generate(),
    icon: <TeamOutlined />,
    text: "dashboard.cards.users",
    profiles: [ProfileEnum.SUPER_ADMIN, ProfileEnum.USERS_MANAGER],
  },
  {
    key: "employees",
    link: ROUTES.merchant.employees.list.generate(),
    icon: <TeamOutlined />,
    text: "dashboard.cards.employees",
    profiles: [ProfileEnum.MERCHANT, ProfileEnum.EMPLOYEES_MANAGER],
  },
  {
    key: "bankAdmins",
    link: ROUTES.bankAdmins.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.bankAdmins",
    profiles: [ProfileEnum.BANK_ADMIN],
  },
  {
    key: "merchants",
    link: ROUTES.merchant.list.generate(),
    icon: <ShopOutlined />,
    text: "menu.merchants",
    profiles: [ProfileEnum.BANK_ADMIN, ProfileEnum.REPORTING],
  },
  {
    key: "employeesManagers",
    link: ROUTES.employeesManager.list.generate(),
    icon: <ShopOutlined />,
    text: "menu.employeesManagers",
    profiles: [ProfileEnum.BANK_ADMIN, ProfileEnum.REPORTING],
  },
  {
    key: "records",
    link: ROUTES.records.list.generate(),
    icon: <FolderOutlined />,
    text: "menu.records",
    profiles: [ProfileEnum.BANK_ADMIN, ProfileEnum.REPORTING],
  },
  {
    key: "tasks",
    link: ROUTES.admin.tasks.list.generate(),
    icon: <ClockCircleOutlined />,
    text: "dashboard.cards.tasks",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
  {
    key: "endpoints",
    link: ROUTES.admin.endpoints.generate(),
    icon: <UnorderedListOutlined />,
    text: "dashboard.cards.endpoints",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
  {
    key: "events",
    link: ROUTES.admin.events.list.generate(),
    icon: <BranchesOutlined />,
    text: "dashboard.cards.events",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
] as {
  key: string;
  link: string;
  icon: JSX.Element;
  text: string;
  profiles: ProfileEnum[];
}[];
