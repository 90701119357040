import { FunctionComponent } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";

const ServerUnreachableScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      noSideMenu
      noFooter
      container={false}
      noContentInCard={false}
      bodyClassName="app-maintenance-screen h-100 d-flex align-items-center justify-content-center"
    >
      <>
        <div className="maintenance-text-container">
          <h1>{t("unreachable.siteUnreachable")}</h1>
          <div>{t("unreachable.Unreachable1")}</div>
          <div>{t("unreachable.Unreachable2")}</div>
        </div>
      </>
    </PageLayout>
  );
};

export default ServerUnreachableScreen;
