import { OptionType } from "@type/form/field.types";

// TODO Récupérer la liste des banques du back-end
/* eslint-disable @typescript-eslint/naming-convention */
export enum BankEnum {
  BRED = "bred",
  DEMO = "demo",
}

export const BANK = [
  {
    value: BankEnum.BRED,
    label: "BRED",
  },
  {
    value: BankEnum.DEMO,
    label: "Démo",
  },
] as OptionType[];
