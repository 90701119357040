import React from "react";
import { Tag } from "antd";

export interface Props {
  version: string;
  className?: string;
}

interface VersionParams {
  value: string;
  color: string;
}

export const ENDPOINT_VERSIONS: { [index: string]: VersionParams } = {
  v10: { value: "V1_0", color: "#abcedf" },
};

const EndpointVersionTag: React.FunctionComponent<Props> = (props: Props) => {
  const { version, className } = props;

  const selectedKey: string | undefined = Object.keys(ENDPOINT_VERSIONS).find(
    (key) =>
      ENDPOINT_VERSIONS[key].value.toLowerCase() === version.toLowerCase(),
  );

  return (
    <Tag
      className={className}
      color={selectedKey ? ENDPOINT_VERSIONS[selectedKey].color : "#aaaaaa"}
    >
      {version}
    </Tag>
  );
};

export default EndpointVersionTag;
