import React from "react";
import { DatePicker, Form, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { InternalNamePath, Rule } from "rc-field-form/lib/interface";
import { dateFormat } from "@utils/Constant";
import locale from "antd/es/date-picker/locale/fr_FR";
import "moment/locale/fr";
import { formHelper } from "@utils/form-helper";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  rules?: Rule[];
  required?: boolean;
  emptyErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  onChange?: (value: Moment | null) => void;
  picker?: "week" | "month" | "quarter" | "year" | "date";
  disabledDate?: (date: Moment) => boolean;
  fieldPrefix?: string;
  disabled?: boolean;
}

const DatePickerFormField: React.FunctionComponent<PropsType> = ({
  showLabel,
  labelTooltip,
  module,
  field,
  readOnly,
  className = "",
  labelClassName = "",
  fieldClassName = "",
  rules = [],
  required = false,
  emptyErrorMessage,
  disabledDate = () => false,
  picker = "date",
  fieldPrefix = "",
  disabled,
  placeholder,
}) => {
  const { t } = useTranslation();

  const i18nField = formHelper.geti18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  return (
    <Form.Item
      name={`${fieldPrefix}${String(field)}`}
      key={key}
      label={
        readOnly || showLabel ? (
          <span className={labelClassName}>
            {t(`${module}.fields.${i18nField}.label`)}&nbsp;
            {labelTooltip && <Tooltip title={labelTooltip} />}
          </span>
        ) : (
          ""
        )
      }
      rules={
        readOnly
          ? []
          : [
              ...rules,
              {
                required,
                message: emptyErrorMessage
                  ? emptyErrorMessage
                  : t("forms.errors.mandatory"),
              },
            ]
      }
      className={className}
    >
      <DatePicker
        picker={picker}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ? triggerNode.parentElement : triggerNode
        }
        disabled={readOnly || disabled}
        id={key}
        placeholder={
          placeholder
            ? placeholder
            : t(`${module}.fields.${i18nField}.placeholder`)
        }
        showTime={false}
        disabledDate={(current) => {
          return disabledDate
            ? disabledDate(current)
            : current && current > moment();
        }}
        format={dateFormat}
        locale={locale}
        dropdownAlign={{
          overflow: {
            adjustY: 0, // do not auto flip in y-axis
          },
        }}
        className={fieldClassName}
      />
    </Form.Item>
  );
};

export default DatePickerFormField;
