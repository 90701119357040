import { FunctionComponent } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

const MaintenanceScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout
      noSideMenu
      noFooter
      container={false}
      noContentInCard={false}
      bodyClassName="app-maintenance-screen h-100 d-flex align-items-center justify-content-center"
    >
      <>
        <div className="maintenance-text-container">
          <h1>{t("maintenance.siteInMaintenance")}</h1>
          <div>{t("maintenance.notAccessible1")}</div>
          <div>{t("maintenance.notAccessible2")}</div>
          <BasicButton
            text={t("maintenance.reload")}
            onClick={() => navigate(ROUTES.admin.home.generate())}
            className="mt-30"
          />
        </div>
      </>
    </PageLayout>
  );
};

export default MaintenanceScreen;
