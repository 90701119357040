import { FunctionComponent, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { requestConfirmRegistration } from "@state/auth/AuthEffects";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { ROUTES } from "@utils/Routes";

const ConfirmRegistrationScreen: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = new URLSearchParams(location.search).get("token");
  const userId = new URLSearchParams(location.search).get("userId");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId && token) {
      requestConfirmRegistration({
        dto: {
          userId,
          token,
        },
      })
        .then((authResult: ParsedResponse<void>) => {
          if (authResult.ok) {
            toastSuccess(t("registration.confirmation.messages.success"));
            navigate(ROUTES.auth.login.generate());
          } else {
            if (authResult.errorCode) {
              toastError(
                t(
                  `registration.confirmation.messages.errors.${authResult.errorCode}`,
                ),
              );
              //setErrorCode(authResult.errorCode);
            } else {
              toastError(t("registration.confirmation.messages.error"));
            }
          }
        })
        .catch(() => {
          toastError(t("login.messages.error"));
        })
        .finally(() => setIsLoading(false));
    }
  }, [userId, token, t, navigate]);

  return (
    <PageLayout
      noSideMenu
      title={t("registration.title")}
      container
      containerSize="sm"
      noContentInCard={false}
    >
      <>{isLoading && <span>Loading...</span>}</>
    </PageLayout>
  );
};

export default ConfirmRegistrationScreen;
