import PageLayout from "@components/layouts/PageLayout";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { requestSelectUser } from "@state/e2e/E2eEffects";
import {
  SettingOutlined,
  TeamOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import BasicCard from "@components/cards/BasicCard";
import { toast } from "react-toastify";
import { ROUTES } from "@utils/Routes";

const SelectUserScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  interface UserProps {
    key: string;
    name: string;
    profile: string;
    icon: JSX.Element;
  }

  const availableUsers: UserProps[] = [
    {
      key: "USER_PROJET_INIT",
      name: "Thomas Edison",
      profile: "SUPER_ADMIN",
      icon: <SettingOutlined />,
    },
    {
      key: "MARIE_CURIE",
      name: "Marie Curie",
      profile: "USERS_MANAGER",
      icon: <TeamOutlined />,
    },
    {
      key: "RENE_DAGRON",
      name: "Rene Dagron",
      profile: "MERCHANT",
      icon: <EuroCircleOutlined />,
    },
  ];

  const onClick = (user: string) => {
    void requestSelectUser({ id: user }).then((result) => {
      if (result.ok) {
        const selectedUser = availableUsers.filter((u) => u.key === user)[0];
        toast.success(
          `Connexion en tant que ${selectedUser.name} (${t(
            `users.labels.profiles.${selectedUser.profile}`,
          )}) réussie !`,
        );
        navigate(ROUTES.admin.home.generate());
      }
    });
  };

  return (
    <PageLayout
      noHeader
      noSideMenu
      title={t("e2e.users.title")}
      noContentInCard={false}
      bodyClassName="PublicPage"
    >
      <>
        <div className="text-center">
          Sélectionner un utilisateur afin de vous connecter avec son profil
        </div>
        <div className="d-flex align-items-center justify-content-center flex-wrap mt-2">
          {availableUsers.map((user) => (
            <BasicCard
              className="m-3 card-dashboard"
              key={user.key}
              onClick={() => onClick(user.key)}
              dataTest={`user-${user.key}`}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mt-4">{user.icon}</div>
                <div className="my-4 dashboard-card-text">{user.name}</div>
                <div className="mb-4">
                  {t(`users.labels.profiles.${user.profile}`)}
                </div>
              </div>
            </BasicCard>
          ))}
        </div>
      </>
    </PageLayout>
  );
};

export default SelectUserScreen;
