import { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { AuthentifiedContext, AuthResult } from "@type/auth/auth.types";
import { setUser } from "@state/auth/AuthEvents";
import { useTranslation } from "react-i18next";
import { requestGetUserInfo, requestLogin } from "@state/auth/AuthEffects";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import PageLayout from "@components/layouts/PageLayout";
import { CALLBACK_URL_PARAM, formValidateTriggers } from "@utils/Constant";
import OtpMailModal from "@components/modals/OtpMailModal";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

interface LoginFormData {
  email: string;
  password: string;
}

const LoginScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthentified = useStore<AuthentifiedContext | null>(
    authentifiedStore,
  );

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [userId, setUserId] = useState<string>("");
  const [displayOtpMailModal, setDisplayOtpMailModal] =
    useState<boolean>(false);

  const InitialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (!!isAuthentified && !!isAuthentified.user) {
      const callback = new URLSearchParams(location.search).get("c");
      navigate(callback || ROUTES.admin.home.generate());
    }
  }, [navigate, isAuthentified, location]);

  const handleAuthentication = () => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
        const callback = new URLSearchParams(location.search).get(
          CALLBACK_URL_PARAM,
        );
        if (callback && callback.length > 0) {
          navigate(callback);
        } else {
          navigate(ROUTES.admin.home.generate());
        }
      }
    });
  };

  const handleSubmit = (values: LoginFormData) => {
    setButtonLoading(true);

    requestLogin({
      dto: {
        email: values.email,
        password: values.password,
      },
    })
      .then((authResponse: ParsedResponse<AuthResult>) => {
        if (authResponse.responseCode === 200 && authResponse.data) {
          handleAuthentication();
        } else if (
          authResponse.responseCode === 202 &&
          authResponse.data &&
          authResponse.data.connectedUser?.userId
        ) {
          if (authResponse.data?.twoFactorMode === "OTP_MAIL") {
            setUserId(authResponse.data?.connectedUser?.userId || "");
            setDisplayOtpMailModal(true);
          } else if (authResponse.data?.twoFactorMode === "U_LINK") {
            toastSuccess(t("ulink.messages.email"));
          }
        } else {
          if (authResponse.errorCode) {
            if (
              authResponse.errorCode === 1005 &&
              authResponse.maxLoginAttempts &&
              authResponse.loginAttempts
            ) {
              toastError(
                t(`login.messages.errors.${authResponse.errorCode}`, {
                  remainingAccessAttempts:
                    authResponse.maxLoginAttempts - authResponse.loginAttempts,
                }),
              );
            } else {
              toastError(t(`login.messages.errors.${authResponse.errorCode}`));
            }
          } else {
            toastError(t("login.messages.error"));
          }

          return false;
        }

        return true;
      })
      .catch(() => {
        toastError(t("login.messages.error"));
        return false;
      })
      .finally(() => {
        setButtonLoading(false);
        return true;
      });
  };

  return (
    <PageLayout
      noSideMenu
      title={t("login.login")}
      container
      containerSize="sm"
      noContentInCard={false}
    >
      <>
        <Form
          onFinish={handleSubmit}
          initialValues={InitialValues}
          {...formValidateTriggers}
        >
          <InputFormField
            module="login.form"
            field="email"
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /^([\w-.\+]+)@((?:[\w-]+\.)+)([a-zA-Z]{2,4})/i,
                ),
                message: t("login.form.fields.email.errors.invalid"),
              },
            ]}
          />
          <InputFormField
            module="login.form"
            type="password"
            field="password"
            required
          />
          <div className="d-flex align-items-center justify-content-center mb-3">
            <SubmitButton
              module="login"
              isSubmitting={buttonLoading}
              label="submit"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            <Link to={ROUTES.auth.forgotPassword.generate()} className="m-2">
              {t("login.form.actions.forgot-password")}
            </Link>
          </div>
        </Form>
        <OtpMailModal
          userId={userId}
          visible={displayOtpMailModal}
          onCancel={() => setDisplayOtpMailModal(false)}
        />
      </>
    </PageLayout>
  );
};

export default LoginScreen;
