import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons/lib";
import i18n from "i18next";

const { confirm } = Modal;

export function showConfirm(
  title: string,
  onOk?: () => void,
  onCancel?: () => void,
): void {
  confirm({
    title: title,
    icon: <QuestionCircleOutlined />,
    okText: (
      <span data-test="confirm-button">
        {i18n.t<string>("buttons.confirm")}
      </span>
    ),
    cancelText: (
      <span data-test="cancel-button">{i18n.t<string>("buttons.cancel")}</span>
    ),
    centered: true,
    onOk() {
      onOk && onOk();
    },
    onCancel() {
      onCancel && onCancel();
    },
  });
}
