import { FunctionComponent, ReactElement } from "react";
import SideMenu from "@components/menus/SideMenu";
import Header from "@components/headers/Header";
import Footer from "@components/footers/Footer";
import BasicCard from "@components/cards/BasicCard";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "@components/breadcrumbs/Breadcrumbs";
import { Spin } from "antd";
import BasicButton from "@components/buttons/BasicButton";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";

interface PageLayoutProps {
  title?: string | JSX.Element | null;
  subtitle?: string;
  headerClassName?: string;
  bodyClassName?: string;
  container?: boolean;
  containerSize?: "sm" | "md" | "lg";
  noHeader?: boolean;
  noFooter?: boolean;
  noSideMenu?: boolean;
  sideMenuClosableOnDesktop?: boolean;
  noContentInCard?: boolean;
  breadcrumbs?: BreadcrumbsItem[];
  children: string | ReactElement;
  isLoading?: boolean;
  returnRoute?: string;
  returnText?: string;
  titleCentered?: boolean;
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({
  title = null,
  subtitle,
  headerClassName = "",
  bodyClassName = "",
  container = true,
  containerSize = "lg",
  noHeader = false,
  noFooter = false,
  noSideMenu = false,
  sideMenuClosableOnDesktop = false,
  noContentInCard = false,
  breadcrumbs = null,
  children,
  isLoading = false,
  returnRoute,
  returnText,
  titleCentered = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);

  return (
    <>
      {!noHeader && (
        <Header
          className={
            sideMenuClosableOnDesktop ? "sidemenu-closable-on-desktop" : ""
          }
        />
      )}
      <main>
        {!noSideMenu && authentifiedContext && authentifiedContext.user ? (
          <SideMenu
            noHeader={noHeader}
            closableOnDesktop={sideMenuClosableOnDesktop}
          />
        ) : (
          <></>
        )}
        {isLoading ? (
          <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        ) : (
          <div
            className={`scrollable-container ${noHeader ? "" : "with-header"}`}
          >
            <div
              className={`app-content position-relative ${
                container ? `content-contained content-${containerSize}` : ""
              }`}
            >
              {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
              {returnRoute && (
                <BasicButton
                  icon={<LeftOutlined />}
                  variant="transparent"
                  text={returnText ? returnText : t("buttons.back")}
                  onClick={() => navigate(returnRoute)}
                  className="btn-return"
                />
              )}
              {title && (
                <div className={`content-header pb-4 ${headerClassName}`}>
                  <h1
                    className={`mb-0 it-title ${
                      titleCentered ? "text-center" : ""
                    }`}
                  >
                    {title}
                  </h1>
                  {subtitle && (
                    <h4
                      className={`mb-0 text-secondary ${
                        titleCentered ? "text-center" : ""
                      }`}
                    >
                      {subtitle}
                    </h4>
                  )}
                </div>
              )}
              <div className={`content-body ${bodyClassName}`}>
                {!noContentInCard ? (
                  <BasicCard>{children}</BasicCard>
                ) : (
                  children
                )}
              </div>
            </div>
            {!noFooter && <Footer />}
          </div>
        )}
      </main>
    </>
  );
};

export default PageLayout;
