import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import BasicTag from "@components/tags/BasicTag";
import { RecordItemResponseDto } from "@state/records/dto/response/record.item.response.dto";
import { RecordStatus } from "@utils/enums/record.status.enum";
import {
  tooltipDateRender,
  tooltipRender,
} from "@components/tooltips/CustomTooltip";
import { order } from "@utils/Constant";

export const columns = (): ColumnsType<RecordItemResponseDto> => [
  {
    title: i18n.t<string>("records.list.columns.ticketNumber"),
    width: "7%",
    key: "ticketNumber",
    dataIndex: "ticketNumber",
    render: tooltipRender,
    ellipsis: false,
  },
  {
    title: i18n.t<string>("records.list.columns.email"),
    width: "15%",
    key: "email",
    dataIndex: "email",
    render: tooltipRender,
    ellipsis: true,
  },
  {
    title: i18n.t<string>("records.list.columns.customerPhone"),
    width: "11%",
    key: "customerPhone",
    dataIndex: "customerPhone",
    render: tooltipRender,
    ellipsis: false,
  },
  {
    title: i18n.t<string>("records.list.columns.customerFirstName"),
    width: "10%",
    key: "customerFirstName",
    dataIndex: "customerFirstName",
    render: tooltipRender,
    ellipsis: true,
  },
  {
    title: i18n.t<string>("records.list.columns.customerLastName"),
    width: "9%",
    key: "customerLastName",
    dataIndex: "customerLastName",
    render: tooltipRender,
    ellipsis: true,
  },
  {
    title: i18n.t<string>("records.list.columns.contractReference"),
    width: "8%",
    key: "contractReference",
    dataIndex: "contractReference",
    render: tooltipRender,
    ellipsis: false,
  },
  {
    title: i18n.t<string>("records.list.columns.createdBy"),
    width: "10%",
    key: "createdBy",
    dataIndex: "createdBy",
    render: tooltipRender,
    ellipsis: false,
  },
  {
    title: i18n.t<string>("records.list.columns.createdAt"),
    width: "9%",
    key: "createdAt",
    dataIndex: "createdAt",
    ellipsis: false,
    defaultSortOrder: order,
    sorter: true,
    render: tooltipDateRender,
  },
  {
    title: i18n.t<string>("records.list.columns.status"),
    width: "10%",
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    render: (status: string) => (
      <BasicTag
        variant={RecordStatus[status] && RecordStatus[status].variant}
        label={i18n.t(RecordStatus[status] && RecordStatus[status].label)}
        className="d-flex justify-content-center"
      />
    ),
  },
];
