import { FunctionComponent } from "react";

interface BasicAlertProps {
  variant?: string;
  icon?: JSX.Element;
  text: string | JSX.Element;
  className?: string;
}

const BasicAlert: FunctionComponent<BasicAlertProps> = ({
  variant = "",
  icon = null,
  text,
  className = "",
}) => {
  return (
    <div
      className={`app-alert d-flex flex-column flex-sm-row align-items-center justify-content-center alert-${variant} ${className}`}
    >
      {icon && <div className="icon me-0 me-sm-4 mb-2 mb-sm-0">{icon}</div>}
      <div className="text flex-grow-1 font-weight-bold">{text}</div>
    </div>
  );
};

export default BasicAlert;
