import { FunctionComponent } from "react";

interface FooterProps {
  className?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ className = "" }) => {
  return <footer className={className} />;
};

export default Footer;
