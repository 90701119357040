import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
} from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Field, OptionType } from "../../../type/form/field.types";
import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { UserStatus } from "../../../utils/enums/user.status.enum";
import { toastError, toastSuccess } from "../../../utils/toast-helper";
import { ROUTES } from "../../../utils/Routes";
import { formHelper } from "../../../utils/form-helper";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { FIELD_LENGTH, formValidateTriggers } from "../../../utils/Constant";
import BasicTag from "../../../components/tags/BasicTag";
import InputFormField from "../../../components/inputs/InputFormField";
import MailFormField from "../../../components/inputs/MailFormField";
import BasicButton from "../../../components/buttons/BasicButton";
import { EmployeeDetailResponseDto } from "@state/employees/dto/response/employee.detail.response.dto";
import {
  requestCreateEmployee,
  requestGetEmployee,
  requestUpdateEmployee,
} from "@state/employees/EmployeeEffects";
import { EmployeeSaveRequestDto } from "@state/employees/dto/request/employee.save.request.dto";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { Merchant } from "@type/auth/auth.types";
import { EMPLOYEES_PROFILES } from "@utils/enums/profile.enum";
import { SelectFormField } from "@components/inputs/SelectFormField";

export interface Props {
  employeeId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const { confirm } = Modal;

const prefix = "employees.edit.form.main";

interface EmployeeFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  merchants: string[];
  profile: string;
}

const EmployeeForm: FunctionComponent<Props> = (props: Props) => {
  // PROPS
  const { employeeId, editMode, setEditMode } = props;

  // GENERIC HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = authentifiedStore.getState()?.user;
  const [form] = Form.useForm();

  // STATES
  const [employee, setEmployee] = useState<EmployeeDetailResponseDto | null>(
    null,
  );

  const authentifiedContext = useStore(authentifiedStore);

  const [fields, setFields] = useState<Field[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [merchantIds, setMerchantIds] = useState<string[]>([]);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t("employees.edit.modals.save.title"),
        content: t("employees.edit.modals.save.content"),
        okText: <span data-test="confirm-button">{t("buttons.yes")}</span>,
        cancelText: <span data-test="cancel-button">{t("buttons.no")}</span>,
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  // EFFECTS
  useEffect(() => {
    if (employeeId) {
      void requestGetEmployee({ id: employeeId });
    }
  }, [employeeId]);

  useEffect(() => {
    return requestGetEmployee.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        const employee = result.data;
        setEmployee(employee);
        setMerchantIds(
          employee.merchants.map((merchant: Merchant): string => merchant.id),
        );
        setFields([
          {
            name: ["firstName"],
            value: employee.firstName,
          },
          {
            name: ["lastName"],
            value: employee.lastName,
          },
          {
            name: ["email"],
            value: employee.email,
          },
          {
            name: ["phoneNumber"],
            value: employee.phoneNumber,
          },
          {
            name: ["profile"],
            value: employee.profile,
          },
        ]);
      } else {
        toastError(t("users.errors.not-found"));
      }
    });
  });

  useEffect(() => {
    return requestCreateEmployee.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t("employees.add.messages.success"));
        navigate(ROUTES.merchant.employees.list.generate());
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "employees.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  useEffect(() => {
    return requestUpdateEmployee.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        setEmployee(result.data);
        toastSuccess(t("employees.edit.messages.success"));
        navigate(ROUTES.merchant.employees.list.generate());
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "employees.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  const handleSubmit = (values: EmployeeFormData) => {
    console.log("values", values);
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setLoading(true);
        const employeeToSave: EmployeeSaveRequestDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          merchantIds: merchantIds,
          profile: values.profile,
        };
        if (employeeId) {
          void requestUpdateEmployee({
            id: employeeId,
            dto: employeeToSave,
          });
        } else {
          void requestCreateEmployee({
            dto: employeeToSave,
          });
        }
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  const mapMerchantToOption = (merchant: Merchant): OptionType => {
    return { label: merchant.corporateName, value: merchant.id };
  };

  const getMerchantOptions = (): OptionType[] => {
    const authUserMerchants = authentifiedContext?.user?.merchants;
    if (authUserMerchants != undefined && authUserMerchants.length > 0) {
      return authUserMerchants.map(mapMerchantToOption);
    }
    return [];
  };

  return (
    <>
      {!getResponseReceived && employeeId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !employeeId || employee ? (
        <Form
          {...formValidateTriggers}
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
            <h3 className="flex-grow-1 text-secondary mb-3">
              {t(`${prefix}.title`)}
            </h3>
            {employeeId && employee && (
              <div className="d-flex align-items-center justify-content-end flex-wrap">
                <BasicTag
                  dataTest="user-status"
                  variant={
                    UserStatus[employee.status] &&
                    UserStatus[employee.status].variant
                  }
                  label={t(
                    UserStatus[employee.status] &&
                      UserStatus[employee.status].label,
                  )}
                  className="me-3 mb-3 px-5"
                />
              </div>
            )}
          </div>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="firstName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="lastName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <MailFormField
                showLabel
                type="email"
                module={prefix}
                field="email"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="phoneNumber"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_rule, value) {
                      if (
                        /^((?:\+|00)(33|262|590|594|596)|0)\s*[6|7]\s*\d{8}$/.test(
                          value as string,
                        ) ||
                        /^((?:\+|00)(33|262|590|594|596)|0)\s*[6|7]\s*\d{8}$/.test(
                          getFieldValue("phoneNumber") as string,
                        )
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t(
                          "employees.edit.form.main.fields.phoneNumber.errors.malformed",
                        ),
                      );
                    },
                  }),
                ]}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              {editMode && currentUser && currentUser.id != employeeId ? (
                <Form.Item
                  key="employee_form_merchants"
                  name="merchants"
                  label={
                    <span data-test="input-label" data-test-for={"merchants"}>
                      {t("employees.edit.form.main.fields.merchants.label")}
                      &nbsp;
                    </span>
                  }
                  required={true}
                  initialValue={employee?.merchants?.map(
                    (merchant: Merchant): string => merchant.id,
                  )}
                >
                  <Select
                    mode="multiple"
                    disabled={!editMode}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={t(
                      "employees.edit.form.main.fields.merchants.placeholder",
                    )}
                    onChange={(value) => setMerchantIds(value)}
                    options={getMerchantOptions()}
                    defaultValue={merchantIds}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  key="employee_form_merchants_readonly"
                  label={
                    <span data-test="input-label" data-test-for={"merchants"}>
                      {t("employees.edit.form.main.fields.merchants.label")}
                      &nbsp;
                    </span>
                  }
                  initialValue={employee?.merchants
                    ?.map(
                      (merchant: Merchant): string => merchant.corporateName,
                    )
                    .join(", ")}
                >
                  <Input
                    disabled={true}
                    className="readonly-form-field"
                    value={employee?.merchants
                      .map(
                        (merchant: Merchant): string => merchant.corporateName,
                      )
                      .join(", ")}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={12}>
              {editMode && currentUser && currentUser.id != employeeId ? (
                <SelectFormField
                  key="employee_form_profiles"
                  showLabel
                  module={prefix}
                  field="profile"
                  required={true}
                  options={EMPLOYEES_PROFILES}
                />
              ) : (
                <SelectFormField
                  key="employee_form_profiles_readonly"
                  showLabel
                  module={prefix}
                  field="profile"
                  required={true}
                  readOnly={true}
                  options={EMPLOYEES_PROFILES}
                />
              )}
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-end flex-wrap">
            {editMode && setEditMode && (
              <>
                <Popconfirm
                  title={t("forms.actions.modals.cancel.content")}
                  okText={t("buttons.yes")}
                  cancelText={t("buttons.no")}
                  onConfirm={() => {
                    window.location.reload();
                  }}
                  placement="topRight"
                  icon={<QuestionCircleOutlined />}
                  className="mt-2"
                >
                  <Button>
                    <StopOutlined /> {t("buttons.cancel")}
                  </Button>
                </Popconfirm>
              </>
            )}
            {editMode ? (
              <BasicButton
                type="submit"
                variant="primary"
                icon={<SaveOutlined />}
                text={t("buttons.save")}
                isLoading={loading}
                className="ms-2 mt-2"
              />
            ) : employeeId ? (
              <>
                {setEditMode && (
                  <BasicButton
                    variant="primary"
                    icon={<EditOutlined />}
                    text={t("buttons.edit")}
                    onClick={() => setEditMode(true)}
                    className="ms-2 mt-2"
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </Form>
      ) : (
        <div>{t("users.errors.not-found")}</div>
      )}
    </>
  );
};

export default EmployeeForm;
