import {
  restCreationHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restPostHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { UserWorkflowRequestDto } from "@state/users/dto/request/user.workflow.request.dto";
import { UserDetailsResponseDto } from "@state/users/dto/response/user.details.response.dto";
import { UserSaveRequestDto } from "@state/users/dto/request/user.save.request.dto";
import { UserStatusUpdateResponseDto } from "@state/users/dto/response/user.status.update.response.dto";
import { UsersImportRequestDto } from "@state/users/dto/request/users.import.request.dto";
import { UsersImportSynthesisDto } from "@state/users/dto/response/users.import.synthesis.dto";
import { UserSelfUpdateRequestDto } from "@state/users/dto/request/user.self.update.request.dto";
import { UserSearchCriteria } from "@state/users/dto/request/user.search.criteria";

const baseUrl = configuration.backendBaseUrl;
const usersUrl = baseUrl + "/users";

export const requestGetUsers = createEffect({
  handler: restListHandlerPagination<
    Pageable<UserItemResponseDto>,
    UserSearchCriteria
  >(usersUrl),
});

export const notifyUserStatusUpdateHandler = (): ((data: {
  user: UserItemResponseDto;
  status: string;
}) => UserStatusUpdateResponseDto) => {
  return (data: { user: UserItemResponseDto; status: string }) => ({
    userId: data.user.id,
    status: data.status,
  });
};

export const notifyUserStatusUpdate = createEffect({
  handler: notifyUserStatusUpdateHandler(),
});

export const requestGetUser = createEffect({
  handler: restDetailsHandler<UserDetailsResponseDto>(usersUrl),
});

export const requestCreateUser = createEffect({
  handler: restCreationHandler<UserSaveRequestDto, UserDetailsResponseDto>(
    usersUrl,
  ),
});

export const requestUpdateUser = createEffect({
  handler: restUpdateHandler<UserSaveRequestDto, UserDetailsResponseDto>(
    usersUrl,
  ),
});

export const requestSelfUpdateUser = createEffect({
  handler: restUpdateHandler<UserSelfUpdateRequestDto, UserDetailsResponseDto>(
    usersUrl,
    "/me",
  ),
});

export const requestUpdateUserStatus = createEffect({
  handler: restUpdateHandler<UserWorkflowRequestDto, { status: string }>(
    usersUrl,
    "/status",
  ),
});

export const requestUsersImport = createEffect({
  handler: restPostHandler<UsersImportRequestDto, UsersImportSynthesisDto>(
    usersUrl + "/import",
  ),
});

export const requestReinitUserPassword = createEffect({
  handler: restDetailsHandler<void>(usersUrl, "/reinit-password"),
});

export const requestReinitPeerPassword = createEffect({
  handler: restDetailsHandler<void>(usersUrl, "/reinit-peer-password"),
});
