import { cookieHelper } from "@utils/cookie-helper";

export const removeBase64UrlPrefix = (base64File: string): string => {
  const i = base64File.indexOf("base64,");
  return base64File.slice(i + 7);
};

export const getBase64 = (
  img: Blob,
  callback: (imageUrl: string | ArrayBuffer | null) => void,
): void => {
  const reader = new FileReader();
  reader.addEventListener("load", () =>
    callback(removeBase64UrlPrefix(reader.result as string)),
  );
  reader.readAsDataURL(img);
};

export const getBase64UrlFrom = (base64: string, type: string): string => {
  return `data:${type};base64,${base64}`;
};

export const debugBase64 = (base64URL: string): void => {
  const win = window.open();
  if (win) {
    win.document.write(
      // eslint-disable-next-line max-len
      `<iframe src=\"${base64URL}"\ frameborder=\"0\" style=\"border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;\" allowfullscreen></iframe>`,
    );
  }
};

let interval: number;
let attempts = 60;

export const downloadFile = (
  url: string,
  filename: string,
  downloadToken?: string | number,
  callback?: () => void,
): void => {
  const link = document.createElement("a");
  link.href = `${url}${
    downloadToken
      ? `${url.includes("?") ? "&" : "?"}downloadToken=${downloadToken}`
      : ""
  }`;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  if (callback && downloadToken) {
    interval = window.setInterval(() => {
      attempts--;
      const cookie = cookieHelper.getCookie("downloadToken");
      if (cookie === downloadToken.toString() || attempts === 0) {
        window.clearInterval(interval);
        callback();
        attempts = 30;
      }
    }, 1000);
  }
};
