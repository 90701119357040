import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Tooltip } from "antd";
import { OptionGroupType, OptionType } from "@type/form/field.types";
import { Rule } from "antd/es/form";
import { formHelper } from "@utils/form-helper";

const { Option, OptGroup } = Select;

export interface SelectFieldProps {
  showLabel?: boolean;
  module: string;
  field: string;
  options?: OptionType[];
  optionGroups?: OptionGroupType[];
  required?: boolean;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  mode?: "multiple";
  onSelect?: (value: string) => void;
  fieldPrefix?: string;
  rules?: Rule[];
  onChange?: (value: string[] | string) => void;
  className?: string;
  disabled?: boolean;
}

const SelectFormField = ({
  showLabel,
  labelTooltip,
  module,
  field,
  required,
  readOnly,
  labelClassName = "",
  fieldClassName = "",
  options,
  optionGroups,
  rules = [],
  mode,
  onSelect,
  onChange,
  className = "",
  fieldPrefix = "",
  disabled,
  placeholder,
}: SelectFieldProps): ReactElement => {
  const { t } = useTranslation();

  const fieldRules =
    !readOnly && required
      ? [
          ...rules,
          {
            required: true,
            message: (
              <span data-test={`error-${String(field)}`}>
                {t("forms.errors.mandatory")}
              </span>
            ),
          },
        ]
      : rules;

  const i18nField = formHelper.geti18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  return (
    <Form.Item
      name={`${fieldPrefix}${String(field)}`}
      key={key}
      label={
        readOnly || showLabel ? (
          <span
            data-test="input-label"
            data-test-for={String(field)}
            className={labelClassName}
          >
            {t(`${module}.fields.${i18nField}.label`)}&nbsp;
            {labelTooltip && <Tooltip title={labelTooltip} />}
          </span>
        ) : (
          ""
        )
      }
      rules={fieldRules}
      className={className}
    >
      <Select
        mode={mode}
        disabled={readOnly || disabled}
        id={key}
        placeholder={
          placeholder
            ? placeholder
            : t(`${module}.fields.${i18nField}.placeholder`)
        }
        onSelect={onSelect}
        onChange={onChange}
        data-test="select-list"
        data-test-for={field}
        allowClear
        className={fieldClassName}
      >
        {options &&
          options.map((option) => (
            <Option
              key={`value_${option.value}`}
              value={option.value}
              data-test="select-list-option"
            >
              {t(option.label)}
            </Option>
          ))}
        {optionGroups &&
          optionGroups.map((group) => (
            <OptGroup key={group.id} label={t(group.label)}>
              {group.options.map((option) => (
                <Option
                  key={`value_${option.value}`}
                  value={option.value}
                  data-test="select-list-option"
                >
                  {t(option.label)}
                </Option>
              ))}
            </OptGroup>
          ))}
      </Select>
    </Form.Item>
  );
};

export { SelectFormField };
