import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { employeeDetailBreadcrumb } from "@utils/Breadcrumbs";
import EmployeeForm from "@views/employees/details/EmployeeForm";

const EmployeeScreen: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>();

  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout
      title={t(editMode ? "employees.edit.title" : "employees.details.title")}
      breadcrumbs={employeeDetailBreadcrumb}
      container
      noContentInCard={false}
      //returnRoute={ROUTES.admin.users.list.generate()}
    >
      <EmployeeForm
        employeeId={userId ? userId : ""}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </PageLayout>
  );
};

export default EmployeeScreen;
