import { DateTime } from "luxon";

class DateFormat {
  standardDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy à HH'h'mm");
  }

  standardDateIso(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
  }

  standardDateTimeIso(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd'T'HH:mm:ss");
  }
}

export default new DateFormat();
