import { createEffect } from "effector";
import { restGetUniqueHandler, restListHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import { AppInfosResponseDto } from "@state/app/response/app.infos.response.dto";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import { EndpointGroupDetailsResponseDto } from "@state/app/response/endpoint.group.details.response.dto";
import { FeatureDetailsResponseDto } from "@state/app/response/feature.details.response.dto";

export const requestAppInfos = createEffect({
  handler: restGetUniqueHandler<AppInfosResponseDto>(
    configuration.backendBaseUrl + "/app/infos",
  ),
});

export const requestGetEndpoints = createEffect({
  handler: restListHandler<EndpointGroupDetailsResponseDto>(
    configuration.backendBaseUrl + "/app/endpoints",
  ),
});

export const requestGetProfiles = createEffect({
  handler: restListHandler<ProfileDetailsResponseDto>(
    configuration.backendBaseUrl + "/app/profiles",
  ),
});

export const requestGetFeatures = createEffect({
  handler: restListHandler<FeatureDetailsResponseDto>(
    configuration.backendBaseUrl + "/app/features",
  ),
});
