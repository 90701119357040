import { OptionGroupType } from "@type/form/field.types";

export const EVENTS_GROUP_TYPE: OptionGroupType[] = [
  {
    id: "AUTH",
    label: "events.labels.groups.auth",
    options: [
      {
        value: "SUCCESSFUL_CONNECTION",
        label: "events.labels.event-types.SUCCESSFUL_CONNECTION",
      },
    ],
  },
  {
    id: "USERS",
    label: "events.labels.groups.users",
    options: [
      {
        value: "USER_ENABLING",
        label: "events.labels.event-types.USER_ENABLING",
      },
      {
        value: "USER_DISABLING",
        label: "events.labels.event-types.USER_DISABLING",
      },
    ],
  },
];
