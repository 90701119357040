import i18n from "i18next";
import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { showConfirm } from "@components/modals/ConfirmModal";
import { requestExecuteTask } from "@state/tasks/TaskEffects";
import { TaskItemResponseDto } from "@state/tasks/dto/response/task.item.response.dto";
import TableButton from "@components/buttons/TableButton";
import { alignRight } from "@utils/Constant";
import DateFormat from "@utils/DateFormat";
import { ColumnType } from "antd/es/table/interface";
import { toastError, toastSuccess } from "@utils/toast-helper";

export const columns = (): ColumnType<TaskItemResponseDto>[] => [
  {
    title: i18n.t<string>("tasks.list.columns.taskId"),
    key: "taskId",
    dataIndex: "taskId",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("tasks.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("tasks.list.columns.lastExecution"),
    key: "lastExecution",
    dataIndex: "lastExecution",
    ellipsis: false,
    render: (record: string) => {
      return <span>{DateFormat.standardDate(new Date(record))}</span>;
    },
  },
  {
    title: i18n.t<string>("tasks.list.columns.nextExecution"),
    key: "nextExecution",
    dataIndex: "nextExecution",
    ellipsis: false,
    render: (record: string) => {
      return <span>{DateFormat.standardDate(new Date(record))}</span>;
    },
  },
  {
    title: i18n.t<string>("tasks.list.columns.actions"),
    key: "execute",
    fixed: alignRight,
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: TaskItemResponseDto) => (
      <div className="d-inline-flex">
        <TableButton
          icon={<SettingOutlined />}
          tooltipTitle={i18n.t("tasks.execute.tooltip")}
          onClick={() => {
            showConfirm(
              i18n.t("tasks.execute.modal", {
                task: record.name,
              }),
              () => {
                void requestExecuteTask(record.taskId)
                  .then(() => {
                    toastSuccess(
                      i18n.t("tasks.execute.successfulStart", {
                        task: record.name,
                      }),
                    );
                  })
                  .catch(() => {
                    toastError(
                      i18n.t("tasks.execute.errorOnStart", {
                        task: record.name,
                      }),
                    );
                  });
              },
            );
          }}
          buttonClassName="btn-primary me-1 btn-icon"
        />
      </div>
    ),
  },
];
