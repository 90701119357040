import { FunctionComponent, MouseEvent } from "react";
import { Button } from "antd";

interface BasicButtonProps {
  type?: "button" | "submit" | "reset";
  icon?: JSX.Element;
  variant?: string;
  text?: string | JSX.Element;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any;
  className?: string;
  isLoading?: boolean;
  dataTest?: string;
}

const BasicButton: FunctionComponent<BasicButtonProps> = ({
  type = "button",
  icon,
  variant = "primary",
  text = "",
  disabled = false,
  onClick = undefined,
  isLoading = false,
  className = "",
  dataTest = "",
}) => {
  return (
    <Button
      htmlType={type}
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
      className={`btn-${variant} ${text === "" ? "btn-icon" : ""} ${className}`}
      data-test={`${dataTest || "button"}`}
      data-alt={text !== "" ? text : "button"}
    >
      <div className="d-flex align-items-center justify-content-center position-relative text-hidden">
        {icon && <div className={`icon ${text ? "me-2" : ""}`}>{icon}</div>}
        {text !== "" && <div className="text text-ellipsis">{text}</div>}
      </div>
    </Button>
  );
};

export default BasicButton;
