import { FunctionComponent, useCallback, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { toastError } from "@utils/toast-helper";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { EventItemResponseDto } from "@state/events/dto/response/event.item.response.dto";
import { requestGetEvents } from "@state/events/EventEffects";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult, formValidateTriggers } from "@utils/Constant";
import { Form, Row, Table, TablePaginationConfig } from "antd";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@views/events/EventsColumns";
import Col from "antd/es/grid/col";
import FilterCard from "@components/cards/FilterCard";
import { useForm } from "antd/es/form/Form";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { EVENTS_GROUP_TYPE } from "@utils/enums/event.enum";

const ScreensScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  const [form] = useForm();

  const [events, setEvents] =
    useState<Pageable<EventItemResponseDto>>(emptySearchResult);

  const [eventTypes, setEventTypes] = useState<string[]>([]);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);

  const [sorter, setSorter] = useState<
    SorterResult<EventItemResponseDto> | SorterResult<EventItemResponseDto>[]
  >({
    columnKey: "date",
    order: "descend",
  });

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<EventItemResponseDto>
      | SorterResult<EventItemResponseDto>[],
  ) => {
    if ((sorter as SorterResult<EventItemResponseDto>).column) {
      setSorter(sorter);
    }
  };

  const fetchEvents = useCallback(() => {
    setDataHasLoaded(false);
    requestGetEvents({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: {
        eventType: eventTypes,
      },
    })
      .catch(() => {
        toastError(t("events.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t, sorter, eventTypes]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    return requestGetEvents.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setEvents(result.data);
      }
    });
  });

  const resetFields = () => {
    form.resetFields();
  };

  const onChangeType = (e: string | string[]): void => {
    setEventTypes([...e]);
  };

  return (
    <PageLayout container noContentInCard={false} title={t("events.title")}>
      <>
        <FilterCard resetFields={resetFields} className="mt-5">
          <Form
            form={form}
            {...formValidateTriggers}
            layout="vertical"
            className="mt-3"
          >
            <Row className="d-flex" gutter={16}>
              <Col xs={24} sm={10}>
                <SelectFormField
                  mode="multiple"
                  showLabel
                  module="events.list"
                  field="eventType"
                  optionGroups={EVENTS_GROUP_TYPE}
                  onChange={onChangeType}
                />
              </Col>
            </Row>
          </Form>
        </FilterCard>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: dataHasLoaded
              ? t("table.emptyText.noData")
              : t("table.emptyText.loading"),
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns()}
          dataSource={events.content}
          pagination={{
            total: events.totalElements,
            pageSize: events.size,
            current: events.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: handleChangeLimit,
          }}
          showSorterTooltip={false}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          data-test="events-list"
        />
      </>
    </PageLayout>
  );
};

export default ScreensScreen;
