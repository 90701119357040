import login from "./login.json";
import dashboard from "./dashboard.json";
import menu from "./menu.json";
import header from "./header.json";
import users from "./users.json";
import buttons from "./buttons.json";
import tasks from "./tasks.json";
import errors from "./errors.json";
import accesscontrol from "./accesscontrol.json";
import table from "./table.json";
import forms from "./forms.json";
import banners from "./banners.json";
import footer from "./footer.json";
import error from "./error.json";
import events from "./events.json";
import breadcrumbs from "./breadcrumbs.json";
import employees from "./employees.json";
import bankAdmins from "./bankAdmins.json";
import merchants from "./merchants.json";
import employeesManagers from "./employeesManagers.json";
import records from "./records.json";

const translationFR = {
  ...login,
  ...dashboard,
  ...header,
  ...menu,
  ...users,
  ...buttons,
  ...tasks,
  ...errors,
  ...accesscontrol,
  ...table,
  ...forms,
  ...banners,
  ...events,
  ...footer,
  ...error,
  ...breadcrumbs,
  ...employees,
  ...bankAdmins,
  ...merchants,
  ...employeesManagers,
  ...records,
};

export default translationFR;
