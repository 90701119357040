import { ColumnsType } from "antd/lib/table/interface";
import i18n from "i18next";
import { EndpointDetailsResponseDto } from "@state/app/response/endpoint.details.response.dto";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import EndpointMethodTag from "@components/tags/EndpointMethodTag";
import EndpointVersionTag from "@components/tags/EndpointVersionTag";
import EndpointLabelTag from "@components/tags/EndpointLabelTag";

function isProfileAccreditedToEndpoint(
  profile: ProfileDetailsResponseDto,
  endpoint: EndpointDetailsResponseDto,
): boolean {
  if (profile.name === "SUPER_ADMIN" || !endpoint.permissions) {
    return true;
  }

  return profile.accreditations
    .map((p) => `${p.feature}_${p.right}`)
    .includes(endpoint.permissions);
}

export const columns = (
  profiles: ProfileDetailsResponseDto[],
): ColumnsType<EndpointDetailsResponseDto> => [
  {
    title: i18n.t<string>("accesscontrol.endpoints.list.columns.methods"),
    key: "methods",
    ellipsis: false,
    dataIndex: "methods",
    width: 100,
    align: "right",
    render: (methods: string[]) => (
      <EndpointMethodTag method={methods.toString()} />
    ),
  },
  {
    title: i18n.t<string>("accesscontrol.endpoints.list.columns.names"),
    key: "names",
    ellipsis: false,
    render: (endpoint: EndpointDetailsResponseDto) => (
      <EndpointLabelTag endpoint={endpoint} />
    ),
  },
  {
    title: i18n.t<string>("accesscontrol.endpoints.list.columns.versions"),
    key: "versions",
    ellipsis: false,
    dataIndex: "versions",
    width: 100,
    align: "center",
    render: (versions: string[]) => (
      <EndpointVersionTag version={versions.toString()} />
    ),
  },
  ...profiles.map((p) => ({
    title: i18n.t(`users.labels.profiles.${p.name}`),
    key: p.name,
    ellipsis: true,
    width: 150,
    render: (endpoint: EndpointDetailsResponseDto) =>
      isProfileAccreditedToEndpoint(p, endpoint) ? (
        <CheckCircleFilled className="text-success" />
      ) : (
        <CloseCircleFilled className="text-danger" />
      ),
  })),
];
