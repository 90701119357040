import configuration from "@utils/Config";
import { createEffect } from "effector";
import { restDetailsHandler } from "@utils/rest/RestClient";

const baseUrl = configuration.backendBaseUrl;
const e2eUrl = baseUrl + "/e2e/";

export const requestSelectUser = createEffect({
  handler: restDetailsHandler<void>(`${e2eUrl}users/select`),
});
