import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";

const validateCharacter = (
  password: string,
  validation: PasswordStrengthConstraintsDetailsResponseDto,
): boolean => {
  return password?.length >= validation.minCharacters;
};

const validateUpperCase = (
  password: string,
  validation: PasswordStrengthConstraintsDetailsResponseDto,
): boolean => {
  return (
    password?.replace(/[^A-Z]/g, "").length >= validation.minUpperCaseLetters
  );
};

const validateLowerCase = (
  password: string,
  validation: PasswordStrengthConstraintsDetailsResponseDto,
): boolean => {
  return (
    password?.replace(/[^a-z]/g, "").length >= validation.minLowerCaseLetters
  );
};

const validateNumber = (
  password: string,
  validation: PasswordStrengthConstraintsDetailsResponseDto,
): boolean => {
  return password?.replace(/[^0-9]/g, "").length >= validation.minDigits;
};

const validateSpecial = (
  password: string,
  validation: PasswordStrengthConstraintsDetailsResponseDto,
): boolean => {
  return (
    password?.replace(/[^&"(\-_)=ù*,;:!~#{\[|`^@\]}%µ?./§£$+<>'\\]/g, "")
      .length >= validation.minSpecialCharacters
  );
};

const validate = (
  password: string,
  validation: PasswordStrengthConstraintsDetailsResponseDto,
): boolean => {
  return (
    validateCharacter(password, validation) &&
    validateUpperCase(password, validation) &&
    validateLowerCase(password, validation) &&
    validateNumber(password, validation) &&
    validateSpecial(password, validation)
  );
};

export const passwordHelper = {
  validate,
  validateCharacter,
  validateUpperCase,
  validateLowerCase,
  validateNumber,
  validateSpecial,
};
