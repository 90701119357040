import { ColumnsType } from "antd/lib/table/interface";
import i18n from "i18next";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import { FeatureDetailsResponseDto } from "@state/app/response/feature.details.response.dto";
import FeatureLabelTag from "@components/tags/FeatureLabelTag";
import FeatureRightTag from "@components/tags/FeatureRightTag";

function getRightsAccreditedToFeatureForProfile(
  profile: ProfileDetailsResponseDto,
  feature: FeatureDetailsResponseDto,
): string[] {
  if (profile.name === "SUPER_ADMIN") {
    return feature.rights.sort();
  }

  return profile.accreditations
    .filter((p) => feature.code === p.feature)
    .map((p) => p.right)
    .sort();
}

export const columns = (
  profiles: ProfileDetailsResponseDto[],
): ColumnsType<FeatureDetailsResponseDto> => [
  {
    title: i18n.t<string>("accesscontrol.features.list.columns.names"),
    key: "name",
    ellipsis: false,
    render: (feature: FeatureDetailsResponseDto) => (
      <FeatureLabelTag feature={feature} />
    ),
  },
  ...profiles.map((p) => ({
    title: i18n.t<string>(`users.labels.profiles.${p.name}`),
    key: p.name,
    ellipsis: true,
    render: (feature: FeatureDetailsResponseDto) => (
      <ul className="list-style-none ps-0 mb-0">
        {getRightsAccreditedToFeatureForProfile(p, feature).map((right) => (
          <li key={right} className="mb-1">
            <FeatureRightTag right={right} />
          </li>
        ))}
      </ul>
    ),
  })),
];
