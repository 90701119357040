import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { recordDetailBreadcrumb } from "@utils/Breadcrumbs";
import RecordForm from "@views/records/details/RecordForm";
import PageLayout from "@components/layouts/PageLayout";

const RecordScreen: FunctionComponent = () => {
  const { recordId } = useParams<{ recordId: string }>();

  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout
      title={t(editMode ? "records.edit.title" : "records.details.title")}
      breadcrumbs={recordDetailBreadcrumb}
      container
      noContentInCard={false}
    >
      <RecordForm
        recordId={recordId ? recordId : ""}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </PageLayout>
  );
};

export default RecordScreen;
