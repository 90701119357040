import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";
import {
  ParsedResponse,
  ServerResponseParser,
} from "@utils/rest/ServerResponseParse";
import { logout } from "@state/auth/AuthEvents";
import logger from "@utils/logger";
import { DEFAULT_500_ERROR } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";

type RestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface DataWithId {
  id: string;
}

interface SearchDto {
  [index: string]: any;
}

const executeCall = <U>(fetchPromise: Promise<Response>) => {
  return fetchPromise
    .then((response: Response) => {
      logger.debug(`HTTP response ${response.status}`);
      if (response.status === 401) {
        logout();
      }
      if (response.status === 500) {
        return DEFAULT_500_ERROR;
      }
      return new ServerResponseParser<U>().parseResponse(response);
    })
    .catch((err) => {
      console.log("default rest call error", err);
      return DEFAULT_500_ERROR;
    });
};

export const makeRestCall = <T, U>(
  url: string,
  method: RestMethod,
  data?: T,
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => controller.abort(), 15000);

  const headers: HeadersInit = {
    ["Content-Type"]: "application/json",
  };

  const fetchPromise = fetch(url, {
    method,
    signal,
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
    headers,
  });
  return executeCall<U>(fetchPromise);
};

export const makeRestMultipartCall = <U>(
  url: string,
  method: RestMethod,
  data: FormData,
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => controller.abort(), 15000);

  const fetchPromise = fetch(url, {
    method,
    signal,
    credentials: "include",
    body: data,
  });

  return executeCall<U>(fetchPromise);
};

const buildSearchParameters = <T extends SearchDto>(dto?: T): string => {
  return dto
    ? Object.keys(dto)
        .map((key, index) =>
          dto[key] !== undefined
            ? `${index !== 0 ? "&" : ""}${key}=${String(dto[key])}`
            : "",
        )
        .join("")
    : "";
};

const addSlashIfNeeded = (urlPortion: string) => {
  if (!urlPortion) {
    return urlPortion;
  }
  return urlPortion.startsWith("/") ? urlPortion : "/" + urlPortion;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandlerPagination = <T, U extends SearchDto = {}>(
  endpointUrl: string,
): ((data: {
  page: number;
  limit: number;
  sorter?: SorterResult<any> | SorterResult<any>[];
  dto?: U;
}) => Promise<ParsedResponse<T>>) => {
  return ({
    page,
    limit,
    sorter,
    dto,
  }: {
    page: number;
    limit: number;
    sorter?: SorterResult<any> | SorterResult<any>[];
    dto?: U;
  }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    let order;
    switch (Array.isArray(sorter) ? sorter[0].order : sorter?.order) {
      case "ascend":
        order = ",ASC";
        break;
      case "descend":
        order = ",DESC";
        break;
      default:
        order = "";
        break;
    }
    return makeRestCall<void, T>(
      `${endpointUrl}?page=${page}&size=${limit}&sort=${
        (order &&
          (Array.isArray(sorter) ? sorter[0].columnKey : sorter?.columnKey)) ||
        ""
      }${order}&${searchParameters}`,
      "GET",
    );
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandler = <T, U extends SearchDto = {}>(
  endpointUrl: string,
): ((data: {
  sorter?: SorterResult<any>;
  dto?: U;
}) => Promise<ParsedResponse<T[]>>) => {
  return ({ sorter, dto }: { sorter?: SorterResult<any>; dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    let order;
    switch (sorter?.order) {
      case "ascend":
        order = ",ASC";
        break;
      case "descend":
        order = ",DESC";
        break;
      default:
        order = "";
        break;
    }
    return makeRestCall<void, T[]>(
      `${endpointUrl}?sort=${
        (order && sorter?.columnKey) || ""
      }${order}&${searchParameters}`,
      "GET",
    );
  };
};

export const restDetailsHandler = <T>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  id: string;
  upperEntityId?: string;
}) => Promise<ParsedResponse<T>>) => {
  return (data: { id: string; upperEntityId?: string }) =>
    makeRestCall<void, T>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : data.id) +
        addSlashIfNeeded(suffixUrl ? suffixUrl : "") +
        addSlashIfNeeded(data.upperEntityId ? data.id : ""),
      "GET",
    );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restGetUniqueHandler = <T, U extends SearchDto = {}>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: { dto?: U }) => Promise<ParsedResponse<T>>) => {
  return ({ dto }: { dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    const suffix = addSlashIfNeeded(suffixUrl ? suffixUrl : "");
    return makeRestCall<void, T>(
      `${endpointUrl + suffix}?${searchParameters}`,
      "GET",
    );
  };
};

export const restIdListHandler = <T>(
  endpointUrl: string,
): ((id: string) => Promise<ParsedResponse<T[]>>) => {
  return (id: string) => makeRestCall<void, T[]>(endpointUrl + id, "GET");
};

export const restCreationHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
  suffix2Url?: string,
): ((data: {
  upperEntityId?: string;
  upperEntity2Id?: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; upperEntity2Id?: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : "") +
        addSlashIfNeeded(suffixUrl ? suffixUrl : "") +
        addSlashIfNeeded(data.upperEntity2Id ? data.upperEntity2Id : "") +
        addSlashIfNeeded(suffix2Url ? suffix2Url : ""),
      "POST",
      data.dto,
    );
};

export const restCreationMultipartHandler = <U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: { dto: FormData }) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; dto: FormData }) =>
    makeRestMultipartCall<U>(
      endpointUrl + addSlashIfNeeded(suffixUrl ? suffixUrl : ""),
      "POST",
      data.dto,
    );
};

export const restDeletionHandler = <T extends DataWithId>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  dto: T;
}) => Promise<ParsedResponse<void>>) => {
  return (data: { upperEntityId?: string; dto: T }) =>
    makeRestCall<T, void>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : "") +
        addSlashIfNeeded(suffixUrl ? suffixUrl : "") +
        addSlashIfNeeded(data.dto.id),
      "DELETE",
      data.dto,
    );
};

export const restUpdateHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : data.id) +
        addSlashIfNeeded(suffixUrl ? suffixUrl : "") +
        addSlashIfNeeded(data.upperEntityId ? data.id : ""),
      "PUT",
      data.dto,
    );
};

export const restPatchHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : data.id) +
        addSlashIfNeeded(suffixUrl ? suffixUrl : "") +
        addSlashIfNeeded(data.upperEntityId ? data.id : ""),
      "PATCH",
      data.dto,
    );
};

export const restPostHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  dto?: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; dto?: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : "") +
        addSlashIfNeeded(suffixUrl ? suffixUrl : ""),
      "POST",
      data.dto,
    );
};

export const restWorkflowHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        addSlashIfNeeded(data.upperEntityId ? data.upperEntityId : "") +
        addSlashIfNeeded(suffixUrl ? suffixUrl : "") +
        addSlashIfNeeded(data.id) +
        "/workflow",
      "POST",
      data.dto,
    );
};

export const restFieldUpdateHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        addSlashIfNeeded(data.id) +
        addSlashIfNeeded(suffixUrl ? suffixUrl : ""),
      "PATCH",
      data.dto,
    );
};

export const restIncreaseOrderHandler = <T extends DataWithId>(
  endpointUrl: string,
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(
      endpointUrl + addSlashIfNeeded(data.id) + "/increaseOrder",
      "POST",
    );
};

export const restDecreaseOrderHandler = <T extends DataWithId>(
  endpointUrl: string,
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(
      endpointUrl + addSlashIfNeeded(data.id) + "/decreaseOrder",
      "POST",
    );
};
