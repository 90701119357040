import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import { UserStatus } from "@utils/enums/user.status.enum";
import BasicTag from "@components/tags/BasicTag";
import { MerchantItemResponseDto } from "@state/merchants/dto/response/merchant.item.response.dto";
import { tooltipDateRender } from "@components/tooltips/CustomTooltip";
import { order } from "@utils/Constant";

export const columns = (): ColumnsType<MerchantItemResponseDto> => [
  {
    title: i18n.t("merchants.list.columns.contratName"),
    key: "merchantEntity.corporateName",
    dataIndex: "corporateName",
    ellipsis: false,
    defaultSortOrder: order,
    sorter: true,
  },
  {
    title: i18n.t<string>("merchants.list.columns.contratRef"),
    key: "contratRef",
    dataIndex: "contratReference",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("merchants.list.columns.email"),
    key: "email",
    dataIndex: "email",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("merchants.list.columns.firstName"),
    key: "firstName",
    dataIndex: "firstName",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("merchants.list.columns.lastName"),
    key: "lastName",
    dataIndex: "lastName",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("merchants.list.columns.phone"),
    key: "phone",
    dataIndex: "phone",
    ellipsis: false,
  },
  {
    title: i18n.t("merchants.list.columns.createdAt"),
    key: "userEntity.createdAt",
    dataIndex: "createdAt",
    ellipsis: false,
    defaultSortOrder: order,
    sorter: true,
    render: tooltipDateRender,
  },
  {
    title: i18n.t("merchants.list.columns.lastConnection"),
    key: "lastConnectionDate",
    dataIndex: "lastConnectionDate",
    ellipsis: false,
    render: tooltipDateRender,
  },
  {
    title: i18n.t<string>("merchants.list.columns.status"),
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    render: (status: string) => (
      <BasicTag
        variant={UserStatus[status] && UserStatus[status].variant}
        label={i18n.t(UserStatus[status] && UserStatus[status].label)}
        className="d-flex justify-content-center"
      />
    ),
  },
];
