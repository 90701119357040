import { FunctionComponent, useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import SubmitButton from "@components/buttons/SubmitButton";
import InputFormField from "@components/inputs/InputFormField";
import { passwordHelper } from "@utils/password-helper";
import PasswordValidationTooltip from "@components/tooltips/PasswordValidationTooltip";
import { requestRegistration } from "@state/auth/AuthEffects";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { formValidateTriggers } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

interface RegistrationFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const defaultPasswordStrengthConstraints = {
  minCharacters: 8,
  minLowerCaseLetters: 1,
  minUpperCaseLetters: 1,
  minDigits: 1,
  minSpecialCharacters: 1,
} as PasswordStrengthConstraintsDetailsResponseDto;

const RegistrationScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const InitialValues = {
    email: "",
    password: "",
    passwordConfirmation: "",
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      passwordHelper.validate(value, defaultPasswordStrengthConstraints)
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  useEffect(() => {
    return requestRegistration.done.watch(({ result }) => {
      const emailField = form.getFieldValue("email") as string;
      if (result.ok) {
        toastSuccess(
          t("registration.form.messages.success", { login: emailField }),
        );
        navigate(ROUTES.auth.login.generate());
      } else if (result.responseCode === 409) {
        toastError(
          t("registration.form.messages.conflict", { login: emailField }),
        );
      } else {
        toastError(t("registration.form.messages.error"));
      }
      setButtonLoading(false);
    });
  });

  const handleSubmit = (values: RegistrationFormData) => {
    setButtonLoading(true);
    void requestRegistration({
      dto: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      },
    });
  };

  return (
    <PageLayout
      noSideMenu
      title={t("registration.title")}
      container
      containerSize="sm"
      noContentInCard={false}
    >
      <>
        <Form
          onFinish={handleSubmit}
          initialValues={InitialValues}
          form={form}
          {...formValidateTriggers}
        >
          <InputFormField
            module="registration.form"
            field="firstName"
            required
          />
          <InputFormField
            module="registration.form"
            field="lastName"
            required
          />
          <InputFormField
            module="registration.form"
            field="email"
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /^([\w-\.\+]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/i,
                ),
                message: t("registration.form.fields.email.errors.invalid"),
              },
            ]}
          />
          <InputFormField
            module="registration.form"
            field="password"
            type="password"
            required
            onChange={onChange}
          />
          {passwordValidationFailed && (
            <PasswordValidationTooltip
              password={password}
              strengthConstraints={defaultPasswordStrengthConstraints}
            />
          )}
          <InputFormField
            module="registration.form"
            field="passwordConfirmation"
            type="password"
            required
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t(
                      "registration.form.fields.passwordConfirmation.errors.unmatch",
                    ),
                  );
                },
              }),
            ]}
          />

          <div className="d-flex align-items-center justify-content-center mb-3">
            <SubmitButton
              module="registration"
              isSubmitting={buttonLoading}
              label="submit"
            />
          </div>
        </Form>
      </>
    </PageLayout>
  );
};

export default RegistrationScreen;
