import i18n from "i18next";
import { alignRight } from "@utils/Constant";
import { CheckOutlined, StopOutlined, ReloadOutlined } from "@ant-design/icons";
import React from "react";
import TableButton from "@components/buttons/TableButton";
import { showConfirm } from "@components/modals/ConfirmModal";
import {
  requestReinitPeerPassword,
  requestUpdateUserStatus,
} from "@state/users/UserEffects";
import { ColumnsType } from "antd/lib/table/interface";
import { UserStatus, UserStatusEnum } from "@utils/enums/user.status.enum";
import { toastError, toastSuccess } from "@utils/toast-helper";
import BasicTag from "@components/tags/BasicTag";
import { AuthentifiedContext } from "@type/auth/auth.types";
import { BankAdminsItemResponseDto } from "@state/bankAdmins/dto/response/bank.admin.item.response.dto";
import { ADMINS_PROFILES, ProfileEnum } from "@utils/enums/profile.enum";

export const columns = (
  authentifiedContext: AuthentifiedContext | null,
): ColumnsType<BankAdminsItemResponseDto> => [
  {
    title: i18n.t<string>("bankAdmins.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
    render: (text: string) => {
      return <span data-test="table-item">{text}</span>;
    },
  },
  {
    title: i18n.t<string>("bankAdmins.list.columns.email"),
    key: "email",
    dataIndex: "email",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("bankAdmins.list.columns.status"),
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    render: (status: string) => (
      <BasicTag
        variant={UserStatus[status] && UserStatus[status].variant}
        label={i18n.t(UserStatus[status] && UserStatus[status].label)}
        className="d-flex justify-content-center"
      />
    ),
  },
  {
    title: i18n.t<string>("bankAdmins.list.columns.profile"),
    key: "profile",
    dataIndex: "profile",
    ellipsis: false,
    render: (profile: string) => {
      let variant = "success";
      const adminProfileArray = ADMINS_PROFILES.filter(
        (arrayProfile) => arrayProfile.value === profile,
      );
      if (adminProfileArray && adminProfileArray.length === 1) {
        const adminProfile = adminProfileArray[0];
        if (adminProfile.value === ProfileEnum.BANK_ADMIN) {
          variant = "danger";
        }
        if (adminProfile.value === ProfileEnum.REPORTING) {
          variant = "primary";
        }
        return (
          <BasicTag
            variant={variant}
            label={i18n.t(adminProfile.label)}
            className="d-flex justify-content-center"
          />
        );
      } else {
        return <span />;
      }
    },
  },
  {
    title: i18n.t<string>("bankAdmins.list.columns.actions"),
    key: "actions",
    fixed: alignRight,
    className: "it-actions",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: BankAdminsItemResponseDto) => (
      <div className="d-inline-flex">
        {authentifiedContext?.user?.id !== record.id && (
          <TableButton
            icon={
              record.status !== UserStatusEnum.ACTIVE ? (
                <CheckOutlined />
              ) : (
                <StopOutlined />
              )
            }
            tooltipTitle={i18n.t(
              `users.list.actions.${
                record.status !== UserStatusEnum.ACTIVE ? "enable" : "disable"
              }`,
            )}
            onClick={() => {
              showConfirm(
                i18n.t(
                  `users.edit.modals.${
                    record.status !== UserStatusEnum.ACTIVE
                      ? "enable"
                      : "disable"
                  }.title`,
                  {
                    user: record.name,
                  },
                ),
                () => {
                  requestUpdateUserStatus({
                    id: record.id,
                    dto: {
                      status:
                        record.status !== UserStatusEnum.ACTIVE
                          ? UserStatusEnum.ACTIVE
                          : UserStatusEnum.DISABLED,
                    },
                  })
                    .then((resp) => {
                      if (resp.ok) {
                        toastSuccess(
                          i18n.t("users.list.messages.change-status-done"),
                        );
                        // void notifyUserStatusUpdate({
                        //   user: record,
                        //   status: resp.data ? resp.data.status : "",
                        // });
                      }
                    })
                    .catch(() => toastError("An error occurred"));
                },
              );
            }}
            buttonClassName={`btn-icon me-1 ${
              record.status !== UserStatusEnum.ACTIVE
                ? "btn-success"
                : "btn-danger"
            }`}
          />
        )}
        {(record.status === UserStatusEnum.ACTIVE ||
          record.status === UserStatusEnum.INIT) &&
          authentifiedContext?.user?.id !== record.id && (
            <TableButton
              icon={<ReloadOutlined />}
              tooltipTitle={i18n.t("users.list.actions.reinitPassword")}
              onClick={() => {
                showConfirm(
                  i18n.t("users.edit.modals.reinitPassword.title", {
                    user: record.name,
                  }),
                  () => {
                    requestReinitPeerPassword({
                      id: record.id,
                    })
                      .then((resp) => {
                        if (resp.ok) {
                          toastSuccess(
                            i18n.t("users.list.messages.reinit-password-done"),
                          );
                        } else {
                          toastSuccess(
                            i18n.t("users.list.messages.reinit-password-error"),
                          );
                        }
                      })
                      .catch(() => toastError("An error occurred"));
                  },
                );
              }}
              buttonClassName="btn-primary btn-icon"
            />
          )}
      </div>
    ),
  },
];
