import { OptionType } from "@type/form/field.types";

export enum UserStatusEnum {
  INIT = "INIT",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export interface UserStatusType {
  [key: string]: {
    value: string;
    label: string;
    variant: string;
  };
}

export const UserStatus: UserStatusType = {
  INIT: {
    value: UserStatusEnum.INIT,
    label: "users.labels.status.INIT",
    variant: "grey",
  },
  ACTIVE: {
    value: UserStatusEnum.ACTIVE,
    label: "users.labels.status.ACTIVE",
    variant: "success",
  },
  DISABLED: {
    value: UserStatusEnum.DISABLED,
    label: "users.labels.status.DISABLED",
    variant: "danger",
  },
  INACTIVE: {
    value: UserStatusEnum.INACTIVE,
    label: "users.labels.status.INACTIVE",
    variant: "black",
  },
  PENDING: {
    value: UserStatusEnum.PENDING,
    label: "users.labels.status.PENDING",
    variant: "grey",
  },
};

export const USERS_STATUS = [
  {
    value: UserStatusEnum.INIT,
    label: "users.labels.status.INIT",
  },
  {
    value: UserStatusEnum.ACTIVE,
    label: "users.labels.status.ACTIVE",
  },
  {
    value: UserStatusEnum.DISABLED,
    label: "users.labels.status.DISABLED",
  },
  {
    value: UserStatusEnum.INACTIVE,
    label: "users.labels.status.INACTIVE",
  },
] as OptionType[];
