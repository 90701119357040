import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import { SpinIcon } from "@components/icons/SpinIcon";
import { Table } from "antd";
import { FeatureDetailsResponseDto } from "@state/app/response/feature.details.response.dto";
import { columns } from "@components/lists/ProfilesColumns";
import { useTranslation } from "react-i18next";
import { requestGetFeatures } from "@state/app/AppEffects";
import { toastError } from "@utils/toast-helper";

interface ProfilesScreenProps {
  profiles: ProfileDetailsResponseDto[];
}

const ProfilesScreen: FunctionComponent<ProfilesScreenProps> = ({
  profiles,
}) => {
  const { t } = useTranslation();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [features, setFeatures] = useState<FeatureDetailsResponseDto[]>();

  const fetchFeatures = useCallback(() => {
    setDataHasLoaded(false);
    requestGetFeatures({})
      .catch(() => {
        toastError(t("features.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t]);

  useEffect(() => {
    fetchFeatures();
  }, [fetchFeatures]);

  useEffect(() => {
    return requestGetFeatures.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDataHasLoaded(true);
        setFeatures(result.data);
      }
    });
  });

  return (
    <>
      <Table
        key={"profiles"}
        style={{ whiteSpace: "break-spaces" }}
        locale={{
          emptyText: dataHasLoaded
            ? t("table.emptyText.noData")
            : t("table.emptyText.loading"),
        }}
        loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
        columns={columns(profiles)}
        pagination={false}
        dataSource={features}
        showSorterTooltip={false}
        rowKey={(record) => record.code}
        rowClassName="cursor-pointer"
        className="mt-4"
      />
    </>
  );
};

export default ProfilesScreen;
