import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
} from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Field, OptionType } from "@type/form/field.types";
import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { UserStatus } from "@utils/enums/user.status.enum";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import { formHelper } from "@utils/form-helper";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import BasicTag from "@components/tags/BasicTag";
import InputFormField from "@components/inputs/InputFormField";
import MailFormField from "@components/inputs/MailFormField";
import BasicButton from "@components/buttons/BasicButton";
import { EmployeeSaveRequestDto } from "@state/employees/dto/request/employee.save.request.dto";
import { Merchant } from "@type/auth/auth.types";
import { EmployeeDetailResponseDto } from "@state/employees/dto/response/employee.detail.response.dto";
import { ProfileEnum } from "@utils/enums/profile.enum";
import SearchFormField from "@components/inputs/SearchFormField";
import { requestGetMerchant } from "@state/merchants/MerchantEffects";
import {
  requestCreateEmployeesManager,
  requestGetEmployeesManager,
  requestUpdateEmployeesManager,
} from "@state/employeesManagers/dto/employeesManagerEffects";

export interface Props {
  userId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const { confirm } = Modal;

const prefix = "employeesManagers.edit.form.main";

interface EmployeesManagerFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  merchants: string[];
  profile: string;
}

const EmployeesManagerForm: FunctionComponent<Props> = (props: Props) => {
  // PROPS
  const { userId, editMode, setEditMode } = props;

  // GENERIC HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // STATES
  const [employeesManager, setEmployeesManager] =
    useState<EmployeeDetailResponseDto | null>(null);

  const [fields, setFields] = useState<Field[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [merchant, setMerchant] = useState<{ merchants: Merchant[] }>();

  const [merchantIds, setMerchantIds] = useState<string[]>([]);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t("employeesManagers.edit.modals.save.title"),
        content: t("employeesManagers.edit.modals.save.content"),
        okText: <span data-test="confirm-button">{t("buttons.yes")}</span>,
        cancelText: <span data-test="cancel-button">{t("buttons.no")}</span>,
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  // EFFECTS
  useEffect(() => {
    if (userId) {
      void requestGetEmployeesManager({ id: userId });
    }
  }, [userId]);

  useEffect(() => {
    return requestGetEmployeesManager.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        const employee = result.data;
        setEmployeesManager(employee);
        setMerchant(employee);
        setMerchantIds(
          employee.merchants.map((merchant: Merchant): string => merchant.id),
        );
        setFields([
          {
            name: ["firstName"],
            value: employee.firstName,
          },
          {
            name: ["lastName"],
            value: employee.lastName,
          },
          {
            name: ["email"],
            value: employee.email,
          },
          {
            name: ["phoneNumber"],
            value: employee.phoneNumber,
          },
          {
            name: ["merchants"],
            value: employee.merchants.map(
              (merchant: Merchant): OptionType => ({
                label: merchant.corporateName,
                value: merchant.id,
              }),
            ),
          },
        ]);
      } else {
        toastError(t("users.errors.not-found"));
      }
    });
  });

  useEffect(() => {
    return requestCreateEmployeesManager.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t("employeesManagers.add.messages.success"));
        navigate(ROUTES.employeesManager.list.generate());
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "employees.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  useEffect(() => {
    return requestUpdateEmployeesManager.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        setEmployeesManager(result.data);
        toastSuccess(t("employees.edit.messages.success"));
        navigate(ROUTES.employeesManager.list.generate());
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "employees.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  useEffect(() => {
    return requestGetMerchant.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const merchant = result.data;
        setMerchant(merchant);
        setMerchantIds(
          merchant.merchants.map(
            (merchant: Merchant): string => merchant.merchantId,
          ),
        );
        setFields([
          {
            name: ["merchant"],
            value: merchant.firstName + " " + merchant.lastName,
          },
          {
            name: ["merchants"],
            value: merchant.merchants.map(
              (merchant: Merchant): OptionType => ({
                label: merchant.corporateName,
                value: merchant.merchantId,
              }),
            ),
          },
        ]);
      } else {
        toastError(t("merchants.errors.not-found"));
      }
    });
  });

  const handleSubmit = (values: EmployeesManagerFormData) => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setLoading(true);
        const employeesManagerToSave: EmployeeSaveRequestDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          merchantIds: merchantIds,
          profile: ProfileEnum.EMPLOYEES_MANAGER,
        };
        console.log("employeesManagerToSave", employeesManagerToSave);
        if (userId) {
          void requestUpdateEmployeesManager({
            id: userId,
            dto: employeesManagerToSave,
          });
        } else {
          void requestCreateEmployeesManager({
            dto: employeesManagerToSave,
          });
        }
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  const onSearch = (value: string) => {
    void requestGetMerchant({
      id: value,
    });
  };

  const mapMerchantToOption = (merchant: Merchant): OptionType => {
    return {
      label: merchant.corporateName,
      value: merchant.merchantId || merchant.id,
    };
  };

  const getMerchantOptions = (): OptionType[] => {
    const merchants = merchant?.merchants;
    if (merchants != undefined && merchants.length > 0) {
      return merchants.map(mapMerchantToOption);
    }
    return [];
  };

  return (
    <>
      {!getResponseReceived && userId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !userId || employeesManager ? (
        <Form
          {...formValidateTriggers}
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
            <h3 className="flex-grow-1 text-secondary mb-3">
              {t(`${prefix}.informationsTitle`)}
            </h3>
            {userId && employeesManager && (
              <div className="d-flex align-items-center justify-content-end flex-wrap">
                <BasicTag
                  dataTest="user-status"
                  variant={
                    UserStatus[employeesManager.status] &&
                    UserStatus[employeesManager.status].variant
                  }
                  label={t(
                    UserStatus[employeesManager.status] &&
                      UserStatus[employeesManager.status].label,
                  )}
                  className="me-3 mb-3 px-5"
                />
              </div>
            )}
          </div>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="firstName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="lastName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <MailFormField
                showLabel
                type="email"
                module={prefix}
                field="email"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="phoneNumber"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_rule, value) {
                      if (
                        /^((?:\+|00)(33|262|590|594|596)|0)\s*[6|7]\s*\d{8}$/.test(
                          value as string,
                        ) ||
                        /^((?:\+|00)(33|262|590|594|596)|0)\s*[6|7]\s*\d{8}$/.test(
                          getFieldValue("phoneNumber") as string,
                        )
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t(
                          "employeesManagers.edit.form.main.fields.phoneNumber.errors.malformed",
                        ),
                      );
                    },
                  }),
                ]}
              />
            </Col>
          </Row>
          {editMode && (
            <>
              <h3 className="flex-grow-1 text-secondary mb-3 mt-3">
                {t(`${prefix}.contratRefsTitle`)}
              </h3>
              <p>{t(`${prefix}.instructions`)}</p>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <SearchFormField
                    showLabel
                    module={prefix}
                    field="contratRef"
                    emptyErrorMessage={t("forms.errors.mandatory")}
                    readOnly={!editMode}
                    maxLength={FIELD_LENGTH.CONTRATREF}
                    onSearch={onSearch}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <InputFormField
                    showLabel
                    module={prefix}
                    field="merchant"
                    required={true}
                    emptyErrorMessage={t("forms.errors.mandatory")}
                    readOnly
                  />
                </Col>
              </Row>
            </>
          )}
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              {editMode ? (
                <Form.Item
                  key="employees_manager_form_merchants"
                  name="merchants"
                  label={
                    <span data-test="input-label" data-test-for={"merchants"}>
                      {t(
                        "employeesManagers.edit.form.main.fields.merchants.label",
                      )}
                      &nbsp;
                    </span>
                  }
                  required={true}
                  initialValue={employeesManager?.merchants?.map(
                    (merchant: Merchant): string => merchant.corporateName,
                  )}
                >
                  <Select
                    mode="multiple"
                    disabled={!editMode}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={t(
                      "employeesManagers.edit.form.main.fields.merchants.placeholder",
                    )}
                    onChange={(value) => setMerchantIds(value)}
                    options={getMerchantOptions()}
                    defaultValue={merchantIds}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  key="employees_manager_form_merchants_readonly"
                  label={
                    <span data-test="input-label" data-test-for={"merchants"}>
                      {t(
                        "employeesManagers.edit.form.main.fields.merchants.label",
                      )}
                      &nbsp;
                    </span>
                  }
                  initialValue={employeesManager?.merchants
                    ?.map(
                      (merchant: Merchant): string => merchant.corporateName,
                    )
                    .join(", ")}
                >
                  <Input
                    disabled={true}
                    className="readonly-form-field"
                    value={employeesManager?.merchants
                      .map(
                        (merchant: Merchant): string => merchant.corporateName,
                      )
                      .join(", ")}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-end flex-wrap">
            {editMode && setEditMode && (
              <>
                <Popconfirm
                  title={t("forms.actions.modals.cancel.content")}
                  okText={t("buttons.yes")}
                  cancelText={t("buttons.no")}
                  onConfirm={() => {
                    window.location.reload();
                  }}
                  placement="topRight"
                  icon={<QuestionCircleOutlined />}
                  className="mt-2"
                >
                  <Button>
                    <StopOutlined /> {t("buttons.cancel")}
                  </Button>
                </Popconfirm>
              </>
            )}
            {editMode ? (
              <BasicButton
                type="submit"
                variant="primary"
                icon={<SaveOutlined />}
                text={t("buttons.save")}
                isLoading={loading}
                className="ms-2 mt-2"
              />
            ) : userId ? (
              <>
                {setEditMode && (
                  <BasicButton
                    variant="primary"
                    icon={<EditOutlined />}
                    text={t("buttons.edit")}
                    onClick={() => setEditMode(true)}
                    className="ms-2 mt-2"
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </Form>
      ) : (
        <div>{t("users.errors.not-found")}</div>
      )}
    </>
  );
};

export default EmployeesManagerForm;
