import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import {
  requestUpdatePassword,
  requestGetPasswordStrengthConstraints,
} from "@state/auth/AuthEffects";
import { passwordHelper } from "@utils/password-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { logout } from "@state/auth/AuthEvents";
import { formHelper } from "@utils/form-helper";
import { Form } from "antd";
import { formValidateTriggers } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import PasswordValidationTooltip from "@components/tooltips/PasswordValidationTooltip";
import BasicButton from "@components/buttons/BasicButton";
import SubmitButton from "@components/buttons/SubmitButton";
import { ROUTES } from "@utils/Routes";

interface UpdatePasswordFormData {
  previousCredential: string;
  credential: string;
  confirmCredential: string;
}

const UpdatePasswordForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();

  const InitialValues = {
    previousCredential: "",
    credential: "",
    confirmCredential: "",
  };

  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordStrengthConstraints, setPasswordStrengthConstraints] =
    useState<PasswordStrengthConstraintsDetailsResponseDto>();

  useEffect(() => {
    void requestGetPasswordStrengthConstraints({ id: "" }).then((result) => {
      setPasswordStrengthConstraints(result.data);
    });
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      (passwordStrengthConstraints &&
        passwordHelper.validate(value, passwordStrengthConstraints))
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const handleSubmit = (values: UpdatePasswordFormData) => {
    requestUpdatePassword({
      dto: {
        previousCredential: values.previousCredential,
        credential: values.credential,
        confirmCredential: values.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(t("reset-password.messages.success"));
          logout();
          navigate(ROUTES.auth.login.generate());
        } else {
          formHelper.handleFormErrors("set-password.fields", form, result);
        }
      })
      .catch((error: Error) => {
        toastError(error.message);
      });
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={InitialValues}
        {...formValidateTriggers}
      >
        <InputFormField
          module="set-password"
          type="password"
          field="previousCredential"
          required
        />
        <InputFormField
          onChange={onChange}
          module="set-password"
          type="password"
          field="credential"
          required
        />
        {passwordValidationFailed && passwordStrengthConstraints && (
          <PasswordValidationTooltip
            password={password}
            strengthConstraints={passwordStrengthConstraints}
          />
        )}
        <InputFormField
          module="set-password"
          type="password"
          field="confirmCredential"
          className="mb-3"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("credential") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("set-password.fields.credential.errors.unmatch"),
                );
              },
            }),
          ]}
          required
        />
        <div className="d-flex align-items-center justify-content-center mt-2">
          <div className="form-group mb-0">
            <BasicButton
              text={t("buttons.back")}
              variant="tertiary"
              className="mt-2 me-2"
              onClick={() => navigate(ROUTES.admin.account.infos.generate())}
            />
          </div>
          <div className="form-group mb-0">
            <SubmitButton
              module="set-password"
              isSubmitting={false}
              label="save"
              className="mt-2"
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default UpdatePasswordForm;
