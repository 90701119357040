import { createStore } from "effector";
import {
  authentifiedContextEvent,
  logout,
  setUser,
  clearUser,
} from "@state/auth/AuthEvents";
import { AuthentifiedContext, AuthentifiedUser } from "@type/auth/auth.types";
import connectLocalStorage, { Holder } from "effector-localstorage";
import logger from "@utils/logger";
import { requestLogout } from "@state/auth/AuthEffects";

const authentifiedLocalStorage: Holder | null = connectLocalStorage(
  "authentifiedStore",
).onError((err) => console.log(err));

export const authentifiedStore = createStore<AuthentifiedContext | null>(
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  authentifiedLocalStorage?.init(0),
  /* eslint-enable @typescript-eslint/no-unsafe-argument */
);

authentifiedStore
  .on(authentifiedContextEvent, (state, payload) => {
    return payload === undefined
      ? null
      : {
          ...payload,
        };
  })
  .on<AuthentifiedUser>(
    setUser,
    (state, payload: AuthentifiedUser): AuthentifiedContext => {
      return {
        ...state,
        user: { ...payload },
      };
    },
  )
  .on(clearUser, (state) => ({
    ...state,
    user: undefined,
  }));

authentifiedStore.watch(authentifiedLocalStorage);

logout.watch(() => {
  void requestLogout({})
    .then((response) => {
      if (response.ok) {
        localStorage.clear();
        sessionStorage.clear();
        clearUser();
      }
    })
    .catch((err) => {
      logger.error(err);
    });
});
