import { FunctionComponent } from "react";

interface DetailItemProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  className?: string;
}

const DetailItem: FunctionComponent<DetailItemProps> = ({
  title,
  description,
  className = "",
}) => {
  return (
    <div className={`app-item-detail ${className}`}>
      <h3 className="item-title">{title}</h3>
      <div className="item-description">{description}</div>
    </div>
  );
};

export default DetailItem;
