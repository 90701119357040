import React from "react";
import { EndpointDetailsResponseDto } from "@state/app/response/endpoint.details.response.dto";

export interface Props {
  endpoint: EndpointDetailsResponseDto;
}

const EndpointLabelTag: React.FunctionComponent<Props> = (props: Props) => {
  const { endpoint } = props;

  return (
    <>
      <div className="text-primary font-weight-bold">{endpoint.urls}</div>
      <div className="text-dark-grey">{endpoint.description}</div>
    </>
  );
};

export default EndpointLabelTag;
