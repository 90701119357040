import { FunctionComponent } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@utils/Routes";
import BankAdminForm from "@views/bankAdmin/details/BankAdminForm";

const BankAdminAddScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t("bankAdmins.add.title")}
      returnRoute={ROUTES.bankAdmins.list.generate()}
    >
      <>
        <BankAdminForm editMode />
      </>
    </PageLayout>
  );
};

export default BankAdminAddScreen;
