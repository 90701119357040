import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import FilterCard from "@components/cards/FilterCard";
import { Form, Row, Table, TablePaginationConfig } from "antd";
import { emptySearchResult, formValidateTriggers } from "@utils/Constant";
import Col from "antd/es/grid/col";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { USERS_STATUS } from "@utils/enums/user.status.enum";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@components/lists/EmployeeManagersColumns";
import { useForm } from "antd/es/form/Form";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { Pageable } from "@type/pagination/pagination.types";
import { toastError } from "@utils/toast-helper";
import { employeesManagersBreadcrumb } from "@utils/Breadcrumbs";
import InputFormField from "@components/inputs/InputFormField";
import { requestGetEmployeesManagers } from "@state/employeesManagers/dto/employeesManagerEffects";
import BasicButton from "@components/buttons/BasicButton";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ROUTES } from "@utils/Routes";
import { useNavigate } from "react-router-dom";
import { EmployeesManagerItemResponseDto } from "@state/employeesManagers/dto/response/employees.manager.response.dto";
import { authentifiedStore } from "@state/auth/AuthStore";
import { ProfileEnum } from "@utils/enums/profile.enum";

const EmployeesManagersScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = authentifiedStore.getState()?.user;

  const [form] = useForm();

  const searchTimeout = useRef<number>();
  const [filter, setFilter] = useState<string>("");

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [status, setStatus] = useState<string[]>([]);
  const [sorter, setSorter] = useState<
    | SorterResult<EmployeesManagerItemResponseDto>
    | SorterResult<EmployeesManagerItemResponseDto>[]
  >({
    columnKey: "userEntity.createdAt",
    order: "descend",
  });

  const [employeesManager, setEmployeesManager] =
    useState<Pageable<EmployeesManagerItemResponseDto>>(emptySearchResult);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<EmployeesManagerItemResponseDto>
      | SorterResult<EmployeesManagerItemResponseDto>[],
  ) => {
    if ((sorter as SorterResult<EmployeesManagerItemResponseDto>).column) {
      setSorter(sorter);
    }
  };

  const fetchEmployeesManagers = useCallback(() => {
    setDataHasLoaded(false);
    requestGetEmployeesManagers({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: { multipleFieldFilter: filter, status: status },
    })
      .catch(() => {
        toastError(t("employeesManagers.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t, filter, sorter, status]);

  useEffect(() => {
    fetchEmployeesManagers();
  }, [fetchEmployeesManagers]);

  useEffect(() => {
    return requestGetEmployeesManagers.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setEmployeesManager(result.data);
      }
    });
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value?.replace("+", "%2B");
    setFilter(value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setFilter(value), 500);
  };

  const resetFields = () => {
    setFilter("");
    setStatus([]);
    form.resetFields();
  };

  return (
    <PageLayout
      title={t("employeesManagers.title")}
      breadcrumbs={employeesManagersBreadcrumb}
      container
      noContentInCard={false}
    >
      <>
        <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
          <h3 className="flex-grow-1 text-secondary mb-3">
            {t("employeesManagers.list.title")}
          </h3>
          {currentUser && currentUser.profile == ProfileEnum.BANK_ADMIN && (
            <div className="d-flex align-items-center justify-content-end flex-wrap">
              <BasicButton
                variant="secondary"
                icon={<PlusCircleOutlined />}
                text={t("employeesManagers.actions.add")}
                onClick={() => navigate(ROUTES.employeesManager.add.generate())}
                className="ms-2 mb-3"
              />
            </div>
          )}
        </div>
        <FilterCard resetFields={resetFields} className="mt-5">
          <Form
            form={form}
            {...formValidateTriggers}
            layout="vertical"
            className="mt-3"
          >
            <Row className="d-flex" gutter={16}>
              <Col xs={24} sm={10}>
                <InputFormField
                  showLabel
                  module="employeesManagers.list"
                  field="search"
                  onChange={onChange}
                />
              </Col>
              <Col xs={24} sm={6}>
                <SelectFormField
                  mode="multiple"
                  showLabel
                  module="employeesManagers.list"
                  field="status"
                  options={USERS_STATUS}
                  onChange={(value) => {
                    setStatus(
                      Array.isArray(value)
                        ? value
                        : value != undefined
                        ? [value]
                        : [],
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </FilterCard>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: dataHasLoaded
              ? t("table.emptyText.noData")
              : t("table.emptyText.loading"),
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns()}
          dataSource={employeesManager.content}
          pagination={{
            total: employeesManager.totalElements,
            pageSize: employeesManager.size,
            current: employeesManager.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: handleChangeLimit,
          }}
          showSorterTooltip={false}
          onChange={handleTableChange}
          rowKey={(record) =>
            String(record.contratReference) + " " + String(record.email)
          }
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () => {
                if (
                  currentUser &&
                  currentUser.profile == ProfileEnum.BANK_ADMIN
                ) {
                  navigate(
                    ROUTES.employeesManager.details.generate(String(record.id)),
                  );
                }
              },
            };
          }}
          data-test="employees-managers-list"
        />
      </>
    </PageLayout>
  );
};

export default EmployeesManagersScreen;
