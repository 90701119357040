import { Button, Form, Modal } from "antd";
import Otp from "@components/modals/Otp";
import { FunctionComponent, useState } from "react";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { AuthResult } from "@type/auth/auth.types";
import { requestGetUserInfo, requestOtp } from "@state/auth/AuthEffects";
import { setUser } from "@state/auth/AuthEvents";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formValidateTriggers } from "@utils/Constant";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

function getOtpTyped(field: string, size: number): string {
  return [...Array(size)]
    .map(
      (e, i) =>
        document.getElementById(`${field}-${i}`)?.getAttribute("value") || "",
    )
    .join("");
}

interface Props {
  userId: string;
  visible: boolean;
  onCancel?: () => void;
}

const FIELD_NAME = "otpKey";

const OtpMailModal: FunctionComponent<Props> = (props: Props) => {
  const { onCancel, visible, userId } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleOk = (complete?: string): void => {
    const value = `${getOtpTyped(FIELD_NAME, complete ? 5 : 6)}${
      complete ? complete : ""
    }`;
    handleSubmitOtp(value);
    setTimeout(() => form.resetFields(), 1000);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleAuthentication = () => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
        navigate(ROUTES.admin.home.generate());
      }
    });
  };

  const handleSubmitOtp = (value: string): void => {
    setIsLoading(true);
    requestOtp({
      dto: {
        userId: userId,
        otp: value,
      },
    })
      .then((authResult: ParsedResponse<AuthResult>) => {
        if (authResult.responseCode === 200 && authResult.data) {
          handleAuthentication();
        } else {
          toastError(t("login.messages.error"));
        }
      })
      .catch(() => {
        toastError(t("login.messages.error"));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Modal
        title={t<string>("otp.mail.title")}
        data-test={"modal-show"}
        visible={visible}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={() => handleCancel()}>
            {t("otp.mail.buttons.cancel")}
          </Button>,
          <Button
            key="send"
            onClick={() => form.submit()}
            className="btn-primary"
          >
            {t("otp.mail.buttons.send")}
          </Button>,
        ]}
      >
        <p>{t("otp.mail.content")}</p>
        <>
          <Form form={form} onFinish={handleOk} {...formValidateTriggers}>
            <Otp field={FIELD_NAME} size={6} onComplete={handleOk} />
          </Form>
        </>
      </Modal>
    </>
  );
};

export default OtpMailModal;
