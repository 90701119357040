import BasicCard from "@components/cards/BasicCard";
import { useNavigate } from "react-router-dom";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DashboardCardProps {
  link: string;
  icon: JSX.Element;
  text: string;
}
const DashboardCard: FunctionComponent<DashboardCardProps> = ({
  link,
  icon,
  text,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <BasicCard className="m-3 card-dashboard" onClick={() => navigate(link)}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="mt-4">{icon}</div>
        <div className="my-4 dashboard-card-text">{t(text)}</div>
      </div>
    </BasicCard>
  );
};

export default DashboardCard;
