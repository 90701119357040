import { toast } from "react-toastify";
import { ReactElement } from "react";

export const toastInfo = (content: string | undefined): void => {
  if (!content) {
    toast.info(computeContent("An error occurred", "info"));
  } else {
    toast.info(computeContent(content, "info"));
  }
};

export const toastSuccess = (content: string | undefined): void => {
  if (!content) {
    toast.success(computeContent("An error occurred", "success"));
  } else {
    toast.success(computeContent(content, "success"));
  }
};

export const toastWarning = (content: string | undefined): void => {
  if (!content) {
    toast.warning(computeContent("An error occurred", "warning"));
  } else {
    toast.warning(computeContent(content, "warning"));
  }
};

export const toastError = (content: string | undefined): void => {
  if (!content) {
    toast.error(computeContent("An error occurred", "error"));
  } else {
    toast.error(computeContent(content, "error"));
  }
};

const computeContent = (
  content: string,
  type: "info" | "success" | "error" | "warning",
): ReactElement => {
  return <span data-test={`toast-${type}`}>{content}</span>;
};
