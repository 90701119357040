import React, { FunctionComponent } from "react";
import { Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";

export interface BreadcrumbsItem {
  icon?: JSX.Element;
  link?: string;
  label?: string;
}

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbsItem[] | null;
  className?: string;
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbs,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <Breadcrumb className={`app-breadcrumbs ${className}`}>
      {breadcrumbs?.map((item, index) => (
        <React.Fragment key={index}>
          {item.link ? (
            <Breadcrumb.Item href={item.link}>
              {item.icon}
              <span className={item.icon ? "ms-2" : ""}>
                {item.label && t(item.label)}
              </span>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>
              {item.icon}
              <span className={item.icon ? "ms-2" : ""}>
                {item.label && t(item.label)}
              </span>
            </Breadcrumb.Item>
          )}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
