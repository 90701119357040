import { FunctionComponent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

interface SubmitButtonProps {
  module: string;
  isSubmitting?: boolean;
  label?: string;
  className?: string;
  disabled?: boolean;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  module,
  label,
  isSubmitting = false,
  className = "",
  disabled = false,
}: SubmitButtonProps): ReactElement => {
  const { t } = useTranslation();
  const submitButtonLabel = label
    ? `${module}.form.actions.${label}`
    : "forms.buttons.submit.defaultLabel";

  return (
    <BasicButton
      type="submit"
      isLoading={isSubmitting}
      text={t(submitButtonLabel)}
      className={`${className}`}
      disabled={disabled}
    />
  );
};

export default SubmitButton;
