import React, { useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BasicButton from "src/components/buttons/BasicButton";
import { requestForgotPassword } from "@state/auth/AuthEffects";
import PageLayout from "@components/layouts/PageLayout";
import SubmitButton from "@components/buttons/SubmitButton";
import MailFormField from "@components/inputs/MailFormField";
import { formValidateTriggers } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import logger from "@utils/logger";

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const InitialValues = {
    email: "",
  };

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);

    requestForgotPassword({ dto: { email: values.email } })
      .then(() => {
        toastSuccess(
          t("forgot-password.messages.success", { login: values.email }),
        );
        logger.info("Connect");
      })
      .catch((error: Error) => toastError(error.message))
      .finally(() => setLoading(false));
  };

  return (
    <PageLayout
      noSideMenu
      title={t("forgot-password.title")}
      container
      containerSize="sm"
      noContentInCard={false}
    >
      <>
        <Form
          onFinish={handleSubmit}
          initialValues={InitialValues}
          {...formValidateTriggers}
        >
          <MailFormField
            module="forgot-password"
            field="email"
            type="email"
            className="mb-3"
            required
          />
          <div className="d-flex align-items-center justify-content-center mt-2">
            <div className="form-group mb-0">
              <BasicButton
                text={t("buttons.back")}
                variant="primary-outlined"
                className="mt-2 me-2"
                onClick={() => navigate(ROUTES.auth.login.generate())}
              />
            </div>
            <div className="form-group mb-0">
              <SubmitButton
                module="forgot-password"
                isSubmitting={loading}
                label="reset"
                className="mt-2"
                disabled={loading}
              />
            </div>
          </div>
        </Form>
      </>
    </PageLayout>
  );
};

export default ForgotPasswordScreen;
