import { FunctionComponent } from "react";

interface BasicBadgeProps {
  number: number;
  variant?: string;
  className?: string;
}

const BasicBadge: FunctionComponent<BasicBadgeProps> = ({
  number,
  variant = "",
  className = "",
}) => {
  return (
    <div className={`app-badge badge-${variant} ${className}`}>{number}</div>
  );
};

export default BasicBadge;
