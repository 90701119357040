import { createEffect } from "effector";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restPostHandler,
} from "@utils/rest/RestClient";
import { AuthentifiedUser, AuthResult } from "@type/auth/auth.types";
import configuration from "src/utils/Config";
import { ResetPasswordRequestDto } from "@state/auth/dto/request/reset.password.request.dto";
import { ValidateTokenRequestDto } from "@state/auth/dto/request/validate.token.request.dto";
import { ForgotPasswordRequestDto } from "@state/auth/dto/request/forgot.password.request.dto";
import { LoginRequestDto } from "@state/auth/dto/request/login.request.dto";
import { RegistrationRequestDto } from "@state/auth/dto/request/registration.request.dto";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { OtpRequestDto } from "@state/auth/dto/request/otp.request.dto";
import { ULinkRequestDto } from "@state/auth/dto/request/ulink.request.dto";
import { UpdatePasswordRequestDto } from "@state/auth/dto/request/update.password.request.dto";
import { ConfirmRegistrationRequestDto } from "@state/auth/dto/request/confirm.registration.request.dto";

const userInfoUrl = "/auth/me";

export const requestGetUserInfo = createEffect({
  handler: restGetUniqueHandler<AuthentifiedUser>(
    configuration.backendBaseUrl + userInfoUrl,
  ),
});

export const requestForgotPassword = createEffect({
  handler: restPostHandler<ForgotPasswordRequestDto, void>(
    configuration.backendBaseUrl + "/account/forgot-password",
  ),
});
export const requestResetPassword = createEffect({
  handler: restPostHandler<ResetPasswordRequestDto, void>(
    configuration.backendBaseUrl + "/account/reset-password",
  ),
});
export const requestUpdatePassword = createEffect({
  handler: restPostHandler<UpdatePasswordRequestDto, void>(
    configuration.backendBaseUrl + "/users/update-password",
  ),
});

export const requestValidateResetToken = createEffect({
  handler: restPostHandler<
    ValidateTokenRequestDto,
    PasswordStrengthConstraintsDetailsResponseDto
  >(configuration.backendBaseUrl + "/account/validate-reset-token"),
});

export const requestGetPasswordStrengthConstraints = createEffect({
  handler: restDetailsHandler<PasswordStrengthConstraintsDetailsResponseDto>(
    configuration.backendBaseUrl + "/account/password-strength-constraints",
  ),
});

export const requestLogin = createEffect({
  handler: restPostHandler<LoginRequestDto, AuthResult>(
    configuration.backendBaseUrl + "/auth/login",
  ),
});

export const requestOtp = createEffect({
  handler: restPostHandler<OtpRequestDto, AuthResult>(
    configuration.backendBaseUrl + "/auth/otp",
  ),
});

export const requestRegistration = createEffect({
  handler: restPostHandler<RegistrationRequestDto, void>(
    `${configuration.backendBaseUrl}/auth/registration`,
  ),
});

export const requestConfirmRegistration = createEffect({
  handler: restPostHandler<ConfirmRegistrationRequestDto, void>(
    `${configuration.backendBaseUrl}/auth/registration/confirm`,
  ),
});

export const requestLogout = createEffect({
  handler: restPostHandler<void, void>(
    configuration.backendBaseUrl + "/auth/logout",
  ),
});

export const requestULink = createEffect({
  handler: restPostHandler<ULinkRequestDto, AuthResult>(
    configuration.backendBaseUrl + "/auth/u-link",
  ),
});
