import { FunctionComponent, useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import BasicButton from "../buttons/BasicButton";
import {
  requestResetPassword,
  requestValidateResetToken,
} from "@state/auth/AuthEffects";
import { useForm } from "antd/lib/form/Form";
import { logout } from "@state/auth/AuthEvents";
import { extractStringFrom } from "@utils/query-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { passwordHelper } from "@utils/password-helper";
import PageLayout from "@components/layouts/PageLayout";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import PasswordValidationTooltip from "@components/tooltips/PasswordValidationTooltip";
import { formHelper } from "@utils/form-helper";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { formValidateTriggers } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

interface SetPasswordProps {
  isCreation?: boolean;
}

interface SetPasswordFormData {
  credential: string;
  confirmCredential: string;
}

const SetPasswordComponent: FunctionComponent<SetPasswordProps> = (
  props: SetPasswordProps,
) => {
  const { isCreation } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();

  const InitialValues = {
    credential: "",
    confirmCredential: "",
  };

  const [tokenValue, setTokenValue] = useState<string | null | undefined>();
  const [userIdValue, setUserIdValue] = useState<string | null | undefined>();
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordStrengthConstraints, setPasswordStrengthConstraints] =
    useState<PasswordStrengthConstraintsDetailsResponseDto>();

  function getParams(): {
    token: string | undefined;
    userId: string | undefined;
  } {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get("token");
    const userId = searchParams.get("userId");

    const tokenValue = extractStringFrom(token);
    const userIdValue = extractStringFrom(userId);
    return { token: tokenValue, userId: userIdValue };
  }

  useEffect(() => {
    const { token, userId } = getParams();
    // remove token from url to prevent http referer leakage
    navigate(window.location.pathname, { replace: true });

    void requestValidateResetToken({
      dto: {
        token: token || "",
        userId: userId || "",
      },
    }).then((result) => {
      if (result.ok) {
        setTokenValue(token);
        setUserIdValue(userId);
      } else {
        toastError(t("registration.form.messages.timeout"));
        navigate(ROUTES.auth.login.generate());
      }
    });
  }, [navigate, t]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      (passwordStrengthConstraints &&
        passwordHelper.validate(value, passwordStrengthConstraints))
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const handleSubmit = (values: SetPasswordFormData) => {
    requestResetPassword({
      dto: {
        token: tokenValue || "",
        userId: userIdValue || "",
        credential: values.credential,
        confirmCredential: values.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(
            t(`${isCreation ? "create" : "reset"}-password.messages.success`),
          );
          logout();
          navigate(ROUTES.auth.login.generate());
        } else {
          formHelper.handleFormErrors(
            "users.edit.form.main.fields",
            form,
            result,
          );
        }
      })
      .catch((error: Error) => {
        toastError(error.message);
      });
  };

  useEffect(() => {
    return requestValidateResetToken.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPasswordStrengthConstraints(result.data);
      }
    });
  });

  return (
    <PageLayout
      noSideMenu
      title={t(`${isCreation ? "create" : "reset"}-password.title`)}
      container
      containerSize="sm"
      noContentInCard={false}
    >
      <>
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={InitialValues}
          {...formValidateTriggers}
        >
          <InputFormField
            onChange={onChange}
            module="set-password"
            type="password"
            field="credential"
            required
          />
          {passwordValidationFailed && passwordStrengthConstraints && (
            <PasswordValidationTooltip
              password={password}
              strengthConstraints={passwordStrengthConstraints}
            />
          )}
          <InputFormField
            module="set-password"
            type="password"
            field="confirmCredential"
            className="mb-3"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("credential") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t("set-password.fields.credential.errors.unmatch"),
                  );
                },
              }),
            ]}
            required
          />
          <div className="d-flex align-items-center justify-content-center mt-2">
            <div className="form-group mb-0">
              <BasicButton
                text={t("buttons.back")}
                variant="tertiary"
                className="mt-2 me-2"
                onClick={() => navigate(ROUTES.auth.login.generate())}
              />
            </div>
            <div className="form-group mb-0">
              <SubmitButton
                module="set-password"
                isSubmitting={false}
                label="save"
                className="mt-2"
              />
            </div>
          </div>
        </Form>
      </>
    </PageLayout>
  );
};

export default SetPasswordComponent;
