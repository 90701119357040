import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restPatchHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { EmployeeSearchCriteria } from "@state/employees/dto/request/employee.search.criteria";
import { EmployeeItemResponseDto } from "@state/employees/dto/response/employee.item.response.dto";
import { EmployeeDetailResponseDto } from "@state/employees/dto/response/employee.detail.response.dto";
import { EmployeeSaveRequestDto } from "@state/employees/dto/request/employee.save.request.dto";
import { UserWorkflowRequestDto } from "@state/users/dto/request/user.workflow.request.dto";

const baseUrl = configuration.backendBaseUrl;
const employeesUrl = baseUrl + "/employees";

export const requestGetEmployees = createEffect({
  handler: restListHandlerPagination<
    Pageable<EmployeeItemResponseDto>,
    EmployeeSearchCriteria
  >(employeesUrl),
});

export const requestGetEmployee = createEffect({
  handler: restDetailsHandler<EmployeeDetailResponseDto>(employeesUrl),
});

export const requestCreateEmployee = createEffect({
  handler: restCreationHandler<
    EmployeeSaveRequestDto,
    EmployeeDetailResponseDto
  >(employeesUrl),
});

export const requestUpdateEmployee = createEffect({
  handler: restPatchHandler<EmployeeSaveRequestDto, EmployeeDetailResponseDto>(
    employeesUrl,
  ),
});

export const requestUpdateEmployeeStatus = createEffect({
  handler: restUpdateHandler<UserWorkflowRequestDto, { status: string }>(
    employeesUrl,
    "/status",
  ),
});
