import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { emptySearchResult, formValidateTriggers } from "@utils/Constant";
import { Pageable } from "@type/pagination/pagination.types";
import { useTranslation } from "react-i18next";
import { Form, Row, Table, TablePaginationConfig } from "antd";
import PageLayout from "@components/layouts/PageLayout";
import { SpinIcon } from "@components/icons/SpinIcon";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import BasicButton from "@components/buttons/BasicButton";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { ROUTES } from "@utils/Routes";
import { toastError } from "@utils/toast-helper";
import Col from "antd/es/grid/col";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { USERS_STATUS } from "@utils/enums/user.status.enum";
import FilterCard from "@components/cards/FilterCard";
import { useForm } from "antd/es/form/Form";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { columns } from "@components/lists/BankAdminsColumns";
import {
  requestGetBankAdmins,
  requestUpdateBankAdminStatus,
} from "@state/bankAdmins/BankAdminEffects";
import { BankAdminsItemResponseDto } from "@state/bankAdmins/dto/response/bank.admin.item.response.dto";
import { bankAdminsBreadcrumb } from "@utils/Breadcrumbs";
import InputFormField from "@components/inputs/InputFormField";

const BankAdminsScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authentifiedContext = useStore(authentifiedStore);

  const [form] = useForm();

  const searchTimeout = useRef<number>();
  const [filter, setFilter] = useState<string>("");

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [status, setStatus] = useState<string[]>([]);
  const [profile] = useState<string[]>(["BANK_ADMIN"]);
  const [sorter, setSorter] = useState<
    | SorterResult<BankAdminsItemResponseDto>
    | SorterResult<BankAdminsItemResponseDto>[]
  >({
    columnKey: "firstName",
    order: "ascend",
  });

  const [users, setUsers] =
    useState<Pageable<BankAdminsItemResponseDto>>(emptySearchResult);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<BankAdminsItemResponseDto>
      | SorterResult<BankAdminsItemResponseDto>[],
  ) => {
    if ((sorter as SorterResult<BankAdminsItemResponseDto>).column) {
      setSorter(sorter);
    }
  };

  const fetchUsers = useCallback(() => {
    setDataHasLoaded(false);
    requestGetBankAdmins({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: { multipleFieldFilter: filter, profile: profile, status: status },
    })
      .catch(() => {
        toastError(t("users.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t, filter, sorter, profile, status]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    return requestGetBankAdmins.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setUsers(result.data);
      }
    });
  });

  useEffect(() => {
    return requestUpdateBankAdminStatus.done.watch(() => {
      fetchUsers();
    });
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setFilter(value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setFilter(value), 500);
  };

  const resetFields = () => {
    setFilter("");
    setStatus([]);
    form.resetFields();
  };

  return (
    <PageLayout
      title={t("bankAdmins.title")}
      breadcrumbs={bankAdminsBreadcrumb}
      container
      noContentInCard={false}
    >
      <>
        <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
          <h3 className="flex-grow-1 text-secondary mb-3">
            {t("bankAdmins.list.title")}
          </h3>
          <div className="d-flex align-items-center justify-content-end flex-wrap">
            <BasicButton
              variant="secondary"
              icon={<PlusCircleOutlined />}
              text={t("bankAdmins.actions.add")}
              onClick={() => navigate(ROUTES.bankAdmins.add.generate())}
              className="ms-2 mb-3"
            />
          </div>
        </div>
        <FilterCard resetFields={resetFields} className="mt-5">
          <Form
            form={form}
            {...formValidateTriggers}
            layout="vertical"
            className="mt-3"
          >
            <Row className="d-flex" gutter={16}>
              <Col xs={24} sm={10}>
                <InputFormField
                  showLabel
                  module="bankAdmins.list"
                  field="search"
                  onChange={onChange}
                />
              </Col>
              <Col xs={24} sm={6}>
                <SelectFormField
                  mode="multiple"
                  showLabel
                  module="bankAdmins.list"
                  field="status"
                  options={USERS_STATUS}
                  onChange={(value) => {
                    setStatus(
                      Array.isArray(value)
                        ? value
                        : value != undefined
                        ? [value]
                        : [],
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </FilterCard>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: dataHasLoaded
              ? t("table.emptyText.noData")
              : t("table.emptyText.loading"),
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(authentifiedContext)}
          dataSource={users.content}
          pagination={{
            total: users.totalElements,
            pageSize: users.size,
            current: users.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: handleChangeLimit,
          }}
          showSorterTooltip={false}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.bankAdmins.details.generate(record.id)),
            };
          }}
          data-test="users-list"
        />
      </>
    </PageLayout>
  );
};

export default BankAdminsScreen;
