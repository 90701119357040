import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import { recordsBreadcrumb } from "@utils/Breadcrumbs";
import { ROUTES } from "@utils/Routes";
import FilterCard from "@components/cards/FilterCard";
import { Form, Row, Table, TablePaginationConfig } from "antd";
import { emptySearchResult, formValidateTriggers } from "@utils/Constant";
import Col from "antd/es/grid/col";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@components/lists/RecordsColumns";
import { useForm } from "antd/es/form/Form";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { Pageable } from "@type/pagination/pagination.types";
import { toastError } from "@utils/toast-helper";
import { RecordItemResponseDto } from "@state/records/dto/response/record.item.response.dto";
import { requestGetRecords } from "@state/records/RecordEffects";
import { RECORDS_STATUS } from "@utils/enums/record.status.enum";
import InputFormField from "@components/inputs/InputFormField";

const RecordsScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = useForm();

  const searchTimeout = useRef<number>();
  const [filter, setFilter] = useState<string>("");
  const [filterMerchant, setFilterMerchant] = useState<string>("");

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [status, setStatus] = useState<string[]>([]);
  const [sorter, setSorter] = useState<
    SorterResult<RecordItemResponseDto> | SorterResult<RecordItemResponseDto>[]
  >({
    columnKey: "createdAt",
    order: "descend",
  });

  const [records, setRecords] =
    useState<Pageable<RecordItemResponseDto>>(emptySearchResult);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<RecordItemResponseDto>
      | SorterResult<RecordItemResponseDto>[],
  ) => {
    if ((sorter as SorterResult<RecordItemResponseDto>).column) {
      setSorter(sorter);
    }
  };

  const fetchRecords = useCallback(() => {
    setDataHasLoaded(false);
    requestGetRecords({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: {
        multipleFieldFilterCustomer: filter,
        status: status,
        multipleFieldFilterMerchant: filterMerchant,
      },
    })
      .catch(() => {
        toastError(t("records.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t, filter, sorter, status, filterMerchant]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  useEffect(() => {
    return requestGetRecords.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setRecords(result.data);
      }
    });
  });

  const onChangeFilterCustomer = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value?.replace("+", "%2B");
    setFilter(value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setFilter(value), 500);
  };

  const onChangeFilterMerchant = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setFilterMerchant(value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(
      () => setFilterMerchant(value),
      500,
    );
  };

  const resetFields = () => {
    setFilterMerchant("");
    setFilter("");
    setStatus([]);
    form.resetFields();
  };

  return (
    <PageLayout
      title={t("records.title")}
      breadcrumbs={recordsBreadcrumb}
      container
      noContentInCard={false}
    >
      <>
        <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
          <h3 className="flex-grow-1 text-secondary mb-3">
            {t("records.list.title")}
          </h3>
        </div>
        <FilterCard resetFields={resetFields} className="mt-5">
          <Form
            form={form}
            {...formValidateTriggers}
            layout="vertical"
            className="mt-3"
          >
            <Row className="d-flex" gutter={16}>
              <Col xs={24} sm={9}>
                <InputFormField
                  showLabel
                  module="records.list"
                  field="searchCustomer"
                  onChange={onChangeFilterCustomer}
                />
              </Col>
              <Col xs={24} sm={9}>
                <InputFormField
                  showLabel
                  module="records.list"
                  field="searchMerchant"
                  onChange={onChangeFilterMerchant}
                />
              </Col>
              <Col xs={24} sm={6}>
                <SelectFormField
                  mode="multiple"
                  showLabel
                  module="records.list"
                  field="status"
                  options={RECORDS_STATUS}
                  onChange={(value) => {
                    setStatus(
                      Array.isArray(value)
                        ? value
                        : value != undefined
                        ? [value]
                        : [],
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </FilterCard>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: dataHasLoaded
              ? t("table.emptyText.noData")
              : t("table.emptyText.loading"),
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns()}
          dataSource={records.content}
          pagination={{
            total: records.totalElements,
            pageSize: records.size,
            current: records.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: handleChangeLimit,
          }}
          showSorterTooltip={false}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(ROUTES.records.details.generate(record.id));
              },
            };
          }}
          data-test="records-list"
        />
      </>
    </PageLayout>
  );
};

export default RecordsScreen;
