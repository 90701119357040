import { FunctionComponent, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import Section from "@components/section/Section";
import BasicButton from "@components/buttons/BasicButton";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import BasicTag from "@components/tags/BasicTag";
import BasicBadge from "@components/badges/BasicBadge";
import BasicDivider from "@components/divider/BasicDivider";
import BasicCard from "@components/cards/BasicCard";
import BasicAlert from "@components/alerts/BasicAlert";
import { useTranslation } from "react-i18next";
import { Form, Tooltip } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { PROFILES } from "@utils/enums/profile.enum";
import BasicModal from "@components/modals/BasicModal";
import DetailItem from "@components/items/DetailItem";
import { formValidateTriggers } from "@utils/Constant";
import SearchFormField from "@components/inputs/SearchFormField";
import { toastError, toastSuccess, toastInfo } from "@utils/toast-helper";
import DatePickerFormField from "@components/inputs/DatePickerFormField";
import RangePickerFormField from "@components/inputs/RangePickerFormField";
import CardSection from "@components/section/CardSection";
import { componentBreadcrumb } from "@utils/Breadcrumbs";

const ComponentsScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const isAuthentified = useStore(authentifiedStore);
  const [isBasicModalVisible, setIsBasicModalVisible] =
    useState<boolean>(false);

  return (
    <PageLayout
      noSideMenu={!isAuthentified || !isAuthentified.user}
      title="Composants - Titre page"
      subtitle="Sous-titre page"
      breadcrumbs={componentBreadcrumb}
      container
      noContentInCard
    >
      <>
        <Section title="Sections">
          <div>Section content (any content)</div>
        </Section>
        <CardSection title="Card sections">
          <div>Card section content (any content)</div>
        </CardSection>
        <Section title="Boutons">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton icon={<SearchOutlined />} className="me-3 mb-3" />
            <BasicButton
              icon={<SearchOutlined />}
              text="Primary"
              className="me-3 mb-3"
            />
            <BasicButton text="Primary" className="me-3 mb-3" />
            <BasicButton
              variant="primary-outlined"
              text="Primary outlined"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="secondary"
              text="Secondary"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="tertiary"
              text="Tertiary"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="success"
              text="Success"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="warning"
              text="Warning"
              className="me-3 mb-3"
            />
            <BasicButton variant="danger" text="Danger" className="me-3 mb-3" />
            <BasicButton variant="black" text="Black" className="me-3 mb-3" />
            <BasicButton variant="white" text="White" className="me-3 mb-3" />
            <BasicButton variant="grey" text="Grey" className="me-3 mb-3" />
            <BasicButton
              variant="dark-grey"
              text="Dark grey"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="light-grey"
              text="Light grey"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="transparent"
              text="Transparent"
              className="mb-3"
            />
          </div>
        </Section>
        <Section title="Badges">
          <div className="d-flex align-items-center flex-wrap">
            <BasicBadge number={1} className="me-3 mb-3" />
            <BasicBadge variant="primary" number={12} className="me-3 mb-3" />
            <BasicBadge variant="secondary" number={24} className="me-3 mb-3" />
            <BasicBadge variant="tertiary" number={36} className="me-3 mb-3" />
            <BasicBadge variant="success" number={48} className="me-3 mb-3" />
            <BasicBadge variant="warning" number={60} className="me-3 mb-3" />
            <BasicBadge variant="danger" number={72} className="me-3 mb-3" />
            <BasicBadge variant="white" number={84} className="me-3 mb-3" />
            <BasicBadge variant="grey" number={96} className="me-3 mb-3" />
            <BasicBadge variant="dark-grey" number={99} className="me-3 mb-3" />
            <BasicBadge variant="light-grey" number={99} className="mb-3" />
          </div>
        </Section>
        <Section title="Tags">
          <div className="d-flex align-items-center flex-wrap">
            <BasicTag label="Default" className="me-3 mb-3" />
            <BasicTag variant="primary" label="Primary" className="me-3 mb-3" />
            <BasicTag
              variant="secondary"
              label="Secondary"
              className="me-3 mb-3"
            />
            <BasicTag
              variant="tertiary"
              label="Tertiary"
              className="me-3 mb-3"
            />
            <BasicTag variant="success" label="Success" className="me-3 mb-3" />
            <BasicTag variant="warning" label="Warning" className="me-3 mb-3" />
            <BasicTag variant="danger" label="Danger" className="me-3 mb-3" />
            <BasicTag variant="white" label="White" className="me-3 mb-3" />
            <BasicTag variant="grey" label="Grey" className="me-3 mb-3" />
            <BasicTag
              variant="dark-grey"
              label="Dark grey"
              className="me-3 mb-3"
            />
            <BasicTag
              variant="light-grey"
              label="Light grey"
              className="mb-3"
            />
          </div>
        </Section>
        <Section title="Dividers">
          <div className="d-flex align-items-center flex-wrap">
            <BasicDivider className="me-3" />
            <BasicDivider variant="primary" className="me-3 mb-3" />
            <BasicDivider variant="secondary" className="me-3 mb-3" />
            <BasicDivider variant="tertiary" className="me-3 mb-3" />
            <BasicDivider variant="success" className="me-3 mb-3" />
            <BasicDivider variant="warning" className="me-3 mb-3" />
            <BasicDivider variant="danger" className="me-3 mb-3" />
            <BasicDivider variant="white" className="me-3 mb-3" />
            <BasicDivider variant="grey" className="me-3 mb-3" />
            <BasicDivider variant="dark-grey" className="me-3 mb-3" />
            <BasicDivider variant="light-grey" className="mb-3" />
          </div>
        </Section>
        <Section title="Cards">
          <>
            <BasicCard className="mb-3">
              <>Basic card</>
            </BasicCard>
            <BasicCard onClick={() => console.log("clickable")}>
              <>Basic clickable card</>
            </BasicCard>
          </>
        </Section>
        <Section title="Alerts">
          <>
            <BasicAlert text="Default alert" className="mb-3" />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Default alert with icon"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Primary alert"
              variant="primary"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Primary lighten alert"
              variant="primary-lighten"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Secondary alert"
              variant="secondary"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Secondary lighten alert"
              variant="secondary-lighten"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Tertiary alert"
              variant="tertiary"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Success alert"
              variant="success"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Warning alert"
              variant="warning"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Danger alert"
              variant="danger"
              className="mb-3"
            />
          </>
        </Section>
        <Section title="Toasts">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton
              variant="primary"
              text="Info toast"
              onClick={() => toastInfo("Info toast")}
              className="me-3 mb-3"
            />
            <BasicButton
              variant="success"
              text="Success toast"
              onClick={() => toastSuccess("Success toast")}
              className="me-3 mb-3"
            />
            <BasicButton
              variant="danger"
              text="Error toast"
              onClick={() => toastError("Error toast")}
              className="mb-3"
            />
          </div>
        </Section>
        <Section title="Inputs">
          <Form {...formValidateTriggers} layout="vertical">
            <InputFormField
              showLabel
              module="users.edit.form.main"
              field="firstName"
              required
              emptyErrorMessage={t("forms.errors.mandatory")}
            />
            <InputFormField
              showLabel
              module="users.edit.form.main"
              field="lastName"
              required
              emptyErrorMessage={t("forms.errors.mandatory")}
              readOnly
            />
            <SelectFormField
              showLabel
              module="users.edit.form.main"
              field="profile"
              required
              options={PROFILES}
            />
            <SelectFormField
              module="users.edit.form.main"
              field="profile"
              required
              options={PROFILES}
              mode="multiple"
            />
            <SelectFormField
              showLabel
              module="users.edit.form.main"
              field="email"
              required
              readOnly
              options={PROFILES}
            />
            <SearchFormField
              maxLength={100}
              module="users.edit.form.main"
              field="email"
            />
            <DatePickerFormField module="users.edit.form.main" field="date" />
            <RangePickerFormField module="users.edit.form.main" field="range" />
          </Form>
        </Section>
        <Section title="Modals">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton
              text="Basic modal"
              onClick={() => setIsBasicModalVisible(true)}
              className="me-3 mb-3"
            />
            <BasicModal
              title="Basic Modal"
              visible={isBasicModalVisible}
              onOk={() => setIsBasicModalVisible(false)}
              onCancel={() => setIsBasicModalVisible(false)}
              buttonText="Confirmer"
            >
              <div>Basic modal content</div>
            </BasicModal>
          </div>
        </Section>
        <Section title="Items">
          <>
            <DetailItem
              title="Detail item title"
              description="Detail item description"
              className="mb-3"
            />
          </>
        </Section>
        <Section title="Tooltips">
          <>
            <Tooltip placement="top" title="Tooltip content">
              <span className="text-secondary">
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          </>
        </Section>
      </>
    </PageLayout>
  );
};

export default ComponentsScreen;
