import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { MENU } from "@utils/enums/menu.enum";
import { ROUTES } from "@utils/Routes";
import { logout } from "@state/auth/AuthEvents";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { ProfileEnum } from "@utils/enums/profile.enum";
import { isProfileAmong } from "@utils/profile-helper";

interface SideMenuProps {
  closableOnDesktop: boolean;
  noHeader: boolean;
  className?: string;
}

const SideMenu: FunctionComponent<SideMenuProps> = ({
  closableOnDesktop,
  noHeader,
  className = "",
}) => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const [selectedParentKey, setSelectedParentKey] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] =
    useState<boolean>(closableOnDesktop);
  const profile: ProfileEnum | undefined = authentifiedContext?.user?.profile;

  useEffect(() => {
    MENU.forEach((item) => {
      if (item.link && window.location.pathname === item.link) {
        setSelectedParentKey(item.key);
        setSelectedKey("");
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (child.link && window.location.pathname === child.link) {
            setSelectedParentKey(item.key);
            setSelectedKey(child.key);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <div
        className={`app-sidemenu-overlay ${
          isMobileSideMenuOpen ? "d-block" : "d-none"
        } ${noHeader ? "" : "sidemenu-with-header"}`}
        onClick={() => setIsMobileSideMenuOpen(false)}
      />
      <BasicButton
        variant="side-menu"
        icon={isMobileSideMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
        onClick={() => setIsMobileSideMenuOpen(!isMobileSideMenuOpen)}
        className={closableOnDesktop ? "sidemenu-closable-on-desktop" : ""}
      />
      <div
        className={`app-sidemenu d-flex flex-column ${
          isMobileSideMenuOpen ? "mobile-sidemenu-open" : ""
        } ${closableOnDesktop ? "sidemenu-closable-on-desktop" : ""} ${
          noHeader ? "" : "sidemenu-with-header"
        } ${className}`}
      >
        <div className="sidemenu-container flex-grow-1 d-flex flex-column">
          <div className="links flex-grow-1 d-flex flex-column">
            {profile &&
              MENU.filter((item) => isProfileAmong(profile, item.profiles)).map(
                (item) =>
                  item.children && item.children.length > 0 ? (
                    <Collapse
                      key={item.key}
                      className={`sidemenu-link-collapse ${
                        selectedParentKey === item.key
                          ? "sidemenu-link-collapse-active"
                          : ""
                      }`}
                      expandIconPosition="right"
                    >
                      <Collapse.Panel
                        header={
                          <div className="d-flex align-items-center">
                            <div className="icon me-3">{item.icon}</div>
                            <div className="text">{t(item.text)}</div>
                          </div>
                        }
                        key={item.key}
                      >
                        <>
                          {item.children.map((child) => (
                            <div
                              key={child.key}
                              className={`collapse-link ${
                                selectedKey === child.key
                                  ? "collapse-link-active"
                                  : ""
                              }`}
                            >
                              <Link
                                to={child.link}
                                className="d-flex align-items-center"
                              >
                                <div className="icon me-3">{child.icon}</div>
                                <div className="text">{t(child.text)}</div>
                              </Link>
                            </div>
                          ))}
                        </>
                      </Collapse.Panel>
                    </Collapse>
                  ) : item.link ? (
                    <Link
                      to={item.link}
                      key={item.key}
                      className={`link d-flex align-items-center ${
                        selectedParentKey === item.key ? "link-active" : ""
                      }`}
                    >
                      <div className="icon me-3">{item.icon}</div>
                      <div className="text">{t(item.text)}</div>
                    </Link>
                  ) : (
                    <div
                      key={item.key}
                      className="link d-flex align-items-center"
                    >
                      <div className="icon me-3">{item.icon}</div>
                      <div className="text">{t(item.text)}</div>
                    </div>
                  ),
              )}
          </div>
          <div className="user-container">
            {authentifiedContext && authentifiedContext.user ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Link to={ROUTES.admin.account.infos.generate()}>
                  <div className="font-weight-bold text-white">
                    {`${authentifiedContext.user.firstName} ${authentifiedContext.user.lastName}`}
                  </div>
                </Link>
                <div className="font-weight-bold text-white mb-3">
                  (
                  {t(
                    `users.labels.profiles.${authentifiedContext.user.profile}`,
                  )}
                  )
                </div>
                <Link
                  to={ROUTES.auth.login.generate()}
                  onClick={() => logout()}
                >
                  {t("login.actions.logout")}
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
