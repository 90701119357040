import { ChangeEvent, FunctionComponent } from "react";
import { Form, Input } from "antd";

interface Props {
  field: string;
  size: number;
  onComplete?: (value?: string) => void;
}

const Otp: FunctionComponent<Props> = (props: Props) => {
  const { field, size, onComplete } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const [fieldName, fieldIndex] = id.split("-");

    // Check if they hit the max character length
    if (value.length >= 1) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < size) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[id=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`,
        ) as HTMLInputElement;

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
          nextSibling.setSelectionRange(0, nextSibling.value.length);
        } else {
          onComplete && onComplete(e.target.value);
        }
      }
    }
  };

  return (
    <>
      <div className="otpKey-container">
        {[...Array(size)].map((e, i) => (
          <Form.Item
            name={`${field}-${i}`}
            key={`${field}-${i}`}
            className="ms-1 me-1"
          >
            <Input
              id={`${field}-${i}`}
              min={0}
              max={9}
              maxLength={1}
              onChange={handleChange}
              data-test="input-text"
              className="otpKey"
              autoComplete="off"
            />
          </Form.Item>
        ))}
      </div>
    </>
  );
};

export default Otp;
