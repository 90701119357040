import i18n from "i18next";

export interface FieldErrors {
  [field: string]: string;
}

export interface FieldError {
  type: string;
  field: string;
  message: string;
  errorCode: number;
}

export interface ParsedResponse<T> {
  data: T | undefined;
  responseCode: number | undefined;
  errorCode?: number | undefined;
  errorMessage: string | undefined;
  fieldErrors?: FieldError[];
  globalErrors?: FieldError[];
  loginAttempts?: number;
  maxLoginAttempts?: number;
  ok: boolean;
}

export interface ErrorResponse {
  error: string;
  validationErrors: FieldError[];
  errorCode?: number | undefined;
  message?: any;
  status: number;
  loginAttempts?: number;
  maxLoginAttempts?: number;
}

export class ServerResponseParser<T> {
  parseResponse(response: Response): Promise<ParsedResponse<T>> {
    if (response.ok) {
      if (response.status === 204) {
        return Promise.resolve({
          responseCode: response.status,
          data: undefined,
          ok: true,
        } as ParsedResponse<T>);
      }

      return response.text().then((text: string) => {
        const data = (text && JSON.parse(text)) as T;
        return {
          responseCode: response.status,
          errorMessage: undefined,
          errorCode: undefined,
          data,
          ok: true,
        };
      });
    } else {
      return response.text().then((text: string) => {
        const json = (text && JSON.parse(text)) as ErrorResponse;
        const result = {
          responseCode: response.status,
          data: undefined,
          errorCode: json.errorCode,
          ok: false,
          loginAttempts: json.loginAttempts,
          maxLoginAttempts: json.maxLoginAttempts,
        };
        if (response.status === 400) {
          return {
            ...result,
            errorMessage: json.message
              ? json.message
              : i18n.t("errors.bad-request"),
            fieldErrors: json.validationErrors.filter(
              (error) => error.type === "FIELD",
            ),
            globalErrors: json.validationErrors.filter(
              (error) => error.type === "GLOBAL",
            ),
          };
        } else if (response.status === 422) {
          const fieldErrors: FieldErrors = {};
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
          fieldErrors["file"] = `422.${json.message[0].id}`;
          return {
            ...result,
            errorMessage: i18n.t("errors.unprocessable-entity"),
            fieldErrors: [],
          };
        } else {
          return {
            ...result,
            errorMessage: json.message
              ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                (json.error ? json.error + " : " : "") + json.message
              : json.error,
          };
        }
      });
    }
  }
}
