import { FunctionComponent } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import UserForm from "@views/users/details/UserForm";
import { ROUTES } from "@utils/Routes";

const UserAddScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t("users.add.title")}
      returnRoute={ROUTES.admin.users.list.generate()}
    >
      <>
        <UserForm editMode />
      </>
    </PageLayout>
  );
};

export default UserAddScreen;
