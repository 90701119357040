import {
  restCreationHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { BankAdminsItemResponseDto } from "@state/bankAdmins/dto/response/bank.admin.item.response.dto";
import { BankAdminSearchCriteria } from "@state/bankAdmins/dto/request/bank.admin.search.criteria";
import { BankAdminStatusUpdateResponseDto } from "@state/bankAdmins/dto/response/bank.admin.status.update.response.dto";
import { BankAdminDetailsResponseDto } from "@state/bankAdmins/dto/response/bank.admin.details.response.dto";
import { BankAdminSaveRequestDto } from "@state/bankAdmins/dto/request/bank.admin.save.request.dto";
import { BankAdminSelfUpdateRequestDto } from "@state/bankAdmins/dto/request/bank.admin.self.update.request.dto";
import { BankAdminWorkflowRequestDto } from "@state/bankAdmins/dto/request/bank.admin.workflow.request.dto";

const baseUrl = configuration.backendBaseUrl;
const bankAdminsUrl = baseUrl + "/bankAdmins";

export const requestGetBankAdmins = createEffect({
  handler: restListHandlerPagination<
    Pageable<BankAdminsItemResponseDto>,
    BankAdminSearchCriteria
  >(bankAdminsUrl),
});

export const notifyBankAdminStatusUpdateHandler = (): ((data: {
  user: BankAdminsItemResponseDto;
  status: string;
}) => BankAdminStatusUpdateResponseDto) => {
  return (data: { user: BankAdminsItemResponseDto; status: string }) => ({
    userId: data.user.id,
    status: data.status,
  });
};

export const notifyBankAdminStatusUpdate = createEffect({
  handler: notifyBankAdminStatusUpdateHandler(),
});

export const requestGetBankAdmin = createEffect({
  handler: restDetailsHandler<BankAdminDetailsResponseDto>(bankAdminsUrl),
});

export const requestCreateBankAdmin = createEffect({
  handler: restCreationHandler<
    BankAdminSaveRequestDto,
    BankAdminDetailsResponseDto
  >(bankAdminsUrl),
});

export const requestUpdateBankAdmin = createEffect({
  handler: restUpdateHandler<
    BankAdminSaveRequestDto,
    BankAdminDetailsResponseDto
  >(bankAdminsUrl),
});

export const requestSelfUpdateBankAdmin = createEffect({
  handler: restUpdateHandler<
    BankAdminSelfUpdateRequestDto,
    BankAdminDetailsResponseDto
  >(bankAdminsUrl, "/me"),
});

export const requestUpdateBankAdminStatus = createEffect({
  handler: restUpdateHandler<BankAdminWorkflowRequestDto, { status: string }>(
    bankAdminsUrl,
    "/status",
  ),
});

export const requestReinitBankAdminPassword = createEffect({
  handler: restDetailsHandler<void>(bankAdminsUrl, "/reinit-password"),
});
