import { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formHelper } from "@utils/form-helper";

export interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: { [key in string]: string } | string;
  disabled?: boolean;
}

const InputFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName = "",
    fieldClassName = "",
    onChange,
    maxLength,
    type,
    addonAfter,
    addonBefore,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onInput,
    initialValue,
    disabled,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formHelper.geti18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  return (
    <Form.Item
      name={`${fieldPrefix}${String(field)}`}
      key={key}
      label={
        readOnly || showLabel ? (
          <span
            data-test="input-label"
            data-test-for={String(field)}
            className={labelClassName}
          >
            {t(`${module}.fields.${i18nField}.label`)}&nbsp;
            {labelTooltip && <Tooltip title={labelTooltip} />}
          </span>
        ) : (
          ""
        )
      }
      rules={[
        {
          required: isRequired,
          message: (
            <span data-test={`error-${String(field)}`}>
              {emptyErrorMessage
                ? emptyErrorMessage
                : t("forms.errors.mandatory")}
            </span>
          ),
          whitespace: isRequired,
        },
        ...rules,
      ]}
      className={className}
      initialValue={initialValue}
    >
      <Input
        disabled={readOnly || disabled}
        className={`${readOnly ? "readonly-form-field" : ""} ${fieldClassName}`}
        placeholder={
          placeholder
            ? placeholder
            : t(`${module}.fields.${i18nField}.placeholder`)
        }
        id={key}
        onChange={onChange}
        maxLength={maxLength}
        type={type ? type : "text"}
        addonAfter={addonAfter ? addonAfter : ""}
        addonBefore={addonBefore ? addonBefore : ""}
        onInput={onInput}
        data-test="input-text"
        data-test-for={field}
      />
    </Form.Item>
  );
};

export default InputFormField;
