import { FieldData, InternalNamePath } from "rc-field-form/lib/interface";
import i18n from "i18next";
import { NamePath } from "antd/lib/form/interface";
import { FieldError, ParsedResponse } from "@utils/rest/ServerResponseParse";
import { FormInstance } from "antd";
import { toastError } from "@utils/toast-helper";

const replaceKey = (originalName: NamePath, keys: string[]): string => {
  const name = originalName.toString();
  const fields = name.split(".");
  const key = fields.find((field: string): boolean => !isNaN(Number(field)));
  if (key) {
    const index = fields.findIndex((field: string): boolean => field === key);
    fields[index] = keys[parseInt(key)];
    return fields.join(".");
  }
  return name;
};

function addErrorsToFieldsData(
  module: string,
  fieldsData: FieldData[],
  fieldsErrors: FieldError[],
  keys?: string[],
): FieldData[] {
  const newFieldsData = [...fieldsData];
  fieldsErrors.forEach((fieldError: FieldError) => {
    const fieldData = fieldsData.find(
      (f: FieldData) => f.name === fieldError.field,
    );
    const fieldDataIndex = fieldsData.findIndex(
      (f: FieldData) => f.name === fieldError.field,
    );
    if (fieldData) {
      newFieldsData.splice(fieldDataIndex, 0, {
        ...fieldData,
        name: fieldError.field,
        errors: [
          i18n.t(
            `${module}.fields.${fieldError.field}.errors.${fieldError.errorCode}`,
          ),
        ],
      });
    } else {
      newFieldsData.push({
        name: fieldError.field,
        errors: [
          i18n.t(
            `${module}.fields.${fieldError.field}.errors.${fieldError.errorCode}`,
          ),
        ],
      });
    }
  });
  if (keys) {
    return newFieldsData.map((fieldData: FieldData) => ({
      name: replaceKey(fieldData.name, keys),
      errors: fieldData.errors,
    }));
  } else {
    return newFieldsData;
  }
}

const generateRndKeyInteger = (): number => {
  return Math.floor(Math.random() * 100000);
};

const buildFormErrors = (
  module: string,
  errors?: FieldError[],
): FieldData[] => {
  return (errors || []).map((error) => ({
    name: error.field,
    errors: [i18n.t(`${module}.${error.field}.errors.${error.errorCode}`)],
  }));
};

const handleFormErrors = (
  module: string,
  form: FormInstance,
  result: ParsedResponse<any>,
): void => {
  if (result.fieldErrors && result.fieldErrors.length > 0) {
    const errors = buildFormErrors(module, result.fieldErrors);
    form.setFields(errors);
  }
  if (result.globalErrors && result.globalErrors.length > 0) {
    result.globalErrors.forEach((error) => toastError(error.message));
  } else {
    toastError(result.errorMessage);
  }
};

const geti18nFieldFromField = (field: string | InternalNamePath): string => {
  return typeof field === "string"
    ? field.split(".")[field.split(".").length - 1]
    : geti18nFieldFromFieldArray(field);
};

const geti18nFieldFromFieldArray = (field: InternalNamePath): string => {
  return Array.isArray(field)
    ? (field[field.length - 1] as string).replace(/[0-9]/g, "")
    : "";
};

export const formHelper = {
  addErrorsToFieldsData,
  generateRndKeyInteger,
  buildFormErrors,
  handleFormErrors,
  geti18nFieldFromField,
};
