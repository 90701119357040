import React from "react";
import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";

interface PropsType {
  module: string;
  field: string | InternalNamePath;
  mustBeChecked?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  onChange?: (e: any) => void;
  fieldPrefix?: string;
  label?: string;
  className?: string;
  rules?: Rule[];
  disabled?: boolean;
  initialValue?: boolean;
}

const CheckboxFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    module,
    field,
    mustBeChecked,
    readOnly,
    emptyErrorMessage,
    onChange,
    fieldPrefix = "",
    label,
    className = "",
    rules = [],
    disabled,
    initialValue,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!mustBeChecked; // !(!var) => return false if var = false | null | undefined

  const i18nField =
    typeof field === "string"
      ? field.split(".")[field.split(".").length - 1]
      : Array.isArray(field)
      ? (field[field.length - 1] as string).replace(/[0-9]/g, "")
      : "";

  const key = `field_${i18nField}`.replace(",", "_");

  return (
    <>
      <Form.Item
        name={fieldPrefix ? `${fieldPrefix}${field.toString()}` : field}
        key={fieldPrefix + key}
        initialValue={initialValue}
        valuePropName="checked"
        rules={
          isRequired
            ? [
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          emptyErrorMessage
                            ? emptyErrorMessage
                            : t("forms.errors.mandatory"),
                        ),
                },
                ...rules,
              ]
            : [...rules]
        }
        className={className}
      >
        <Checkbox
          disabled={readOnly || disabled}
          className={readOnly ? " readonly-form-field" : ""}
          id={fieldPrefix + key}
          onChange={onChange}
        >
          <span>{label ? label : t(`${module}.fields.${i18nField}`)}</span>
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default CheckboxFormField;
