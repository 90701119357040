import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import EndpointsScreen from "@views/accesscontrol/EndpointsScreen";
import { requestGetProfiles } from "@state/app/AppEffects";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import ProfilesScreen from "@views/accesscontrol/ProfilesScreen";
import { toastError } from "@utils/toast-helper";

const AccessControlScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  const [profiles, setProfiles] = useState<ProfileDetailsResponseDto[]>([]);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [type, setType] = useState<string>("profiles");

  const handleChangeType = (e: RadioChangeEvent) => {
    const newType: string = e.target.value;
    setType(newType);
  };

  const fetchProfiles = useCallback(() => {
    requestGetProfiles({})
      .catch(() => {
        toastError(t("accesscontrol.endpoints.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t]);

  useEffect(() => {
    return requestGetProfiles.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDataHasLoaded(true);
        setProfiles(result.data);
      }
    });
  });

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t("accesscontrol.title")}
    >
      <>
        {dataHasLoaded && (
          <>
            <Radio.Group
              defaultValue={type}
              buttonStyle="solid"
              onChange={handleChangeType}
              className="mb-3"
            >
              <Radio.Button value="profiles">
                {t("accesscontrol.types.profiles")}
              </Radio.Button>
              <Radio.Button value="endpoints">
                {t("accesscontrol.types.endpoints")}
              </Radio.Button>
            </Radio.Group>

            {type === "profiles" && <ProfilesScreen profiles={profiles} />}
            {type === "endpoints" && <EndpointsScreen profiles={profiles} />}
          </>
        )}
      </>
    </PageLayout>
  );
};

export default AccessControlScreen;
