import { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Popconfirm, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CheckOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { Field } from "@type/form/field.types";
import InputFormField from "@components/inputs/InputFormField";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import { ADMINS_PROFILES } from "@utils/enums/profile.enum";
import MailFormField from "@components/inputs/MailFormField";
import { formHelper } from "@utils/form-helper";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { authentifiedStore } from "@state/auth/AuthStore";
import { ROUTES } from "@utils/Routes";
import { UserStatus, UserStatusEnum } from "@utils/enums/user.status.enum";
import { showConfirm } from "@components/modals/ConfirmModal";
import i18n from "i18next";
import BasicTag from "@components/tags/BasicTag";
import {
  requestCreateBankAdmin,
  requestGetBankAdmin,
  requestUpdateBankAdmin,
  requestUpdateBankAdminStatus,
} from "@state/bankAdmins/BankAdminEffects";
import { BankAdminSaveRequestDto } from "@state/bankAdmins/dto/request/bank.admin.save.request.dto";
import { BankAdminDetailsResponseDto } from "@state/bankAdmins/dto/response/bank.admin.details.response.dto";
import { SelectFormField } from "@components/inputs/SelectFormField";

export interface Props {
  userId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const { confirm } = Modal;

const prefix = "users.edit.form.main";

interface BankAdminFormData {
  firstName: string;
  lastName: string;
  email: string;
  profile: string;
}

const BankAdminForm: FunctionComponent<Props> = (props: Props) => {
  // PROPS
  const { userId, editMode, setEditMode } = props;

  // GENERIC HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // STATES
  const [bankAdmin, setBankAdmin] =
    useState<BankAdminDetailsResponseDto | null>(null);

  const [fields, setFields] = useState<Field[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const currentUser = authentifiedStore.getState()?.user;

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      const firstNameField = form.getFieldValue("firstName") as string;
      const lastNameField = form.getFieldValue("lastName") as string;
      const profileField = t(
        `users.labels.profiles.${form.getFieldValue("profile") as string}`,
      );

      confirm({
        title: t("users.edit.modals.save.title"),
        content: t("users.edit.modals.save.content", {
          user: `${firstNameField} ${lastNameField}`,
          profile: profileField,
        }),
        okText: <span data-test="confirm-button">{t("buttons.yes")}</span>,
        cancelText: <span data-test="cancel-button">{t("buttons.no")}</span>,
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const updateUserStatus = () => {
    showConfirm(
      i18n.t(
        `users.edit.modals.${
          bankAdmin?.status !== UserStatusEnum.ACTIVE ? "enable" : "disable"
        }.title`,
        {
          user: `${bankAdmin?.firstName ?? ""}  ${bankAdmin?.lastName ?? ""}`,
        },
      ),
      () => {
        const targetStatus =
          bankAdmin?.status !== UserStatusEnum.ACTIVE
            ? UserStatusEnum.ACTIVE
            : UserStatusEnum.DISABLED;

        void requestUpdateBankAdminStatus({
          id: bankAdmin?.id ?? "",
          dto: {
            status: targetStatus,
          },
        }).then((resp) => {
          if (resp.ok) {
            toastSuccess(i18n.t("users.list.messages.change-status-done"));
            bankAdmin && setBankAdmin({ ...bankAdmin, status: targetStatus });
          }
        });
      },
    );
  };

  // EFFECTS
  useEffect(() => {
    if (userId) {
      void requestGetBankAdmin({ id: userId });
    }
  }, [userId]);

  useEffect(() => {
    return requestGetBankAdmin.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        const bankAdmin = result.data;
        setBankAdmin(bankAdmin);
        setFields([
          {
            name: ["firstName"],
            value: bankAdmin.firstName,
          },
          {
            name: ["lastName"],
            value: bankAdmin.lastName,
          },
          {
            name: ["email"],
            value: bankAdmin.email,
          },
          {
            name: ["profile"],
            value: bankAdmin.profile,
          },
        ]);
      } else {
        toastError(t("users.errors.not-found"));
      }
    });
  });

  useEffect(() => {
    return requestCreateBankAdmin.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t("users.add.messages.success"));
        navigate(ROUTES.bankAdmins.details.generate(result.data.id));
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "users.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  useEffect(() => {
    return requestUpdateBankAdmin.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        setBankAdmin(result.data);
        toastSuccess(t("users.edit.messages.success"));
        navigate(ROUTES.bankAdmins.details.generate(result.data.id));
        setEditMode && setEditMode(false);
      } else {
        formHelper.handleFormErrors(
          "users.edit.form.main.fields",
          form,
          result,
        );
      }
    });
  });

  const handleSubmit = (values: BankAdminFormData) => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setLoading(true);
        const bankAdminToSave: BankAdminSaveRequestDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          profile: values.profile,
        };
        if (userId) {
          void requestUpdateBankAdmin({
            id: userId,
            dto: bankAdminToSave,
          });
        } else {
          void requestCreateBankAdmin({
            dto: bankAdminToSave,
          });
        }
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <>
      {!getResponseReceived && userId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !userId || bankAdmin ? (
        <Form
          {...formValidateTriggers}
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <div className="d-flex align-items-end justify-content-end flex-wrap-reverse">
            <h3 className="flex-grow-1 text-secondary mb-3">
              {t(`${prefix}.title`)}
            </h3>
            {userId && bankAdmin && (
              <div className="d-flex align-items-center justify-content-end flex-wrap">
                <BasicTag
                  dataTest="user-status"
                  variant={
                    UserStatus[bankAdmin.status] &&
                    UserStatus[bankAdmin.status].variant
                  }
                  label={t(
                    UserStatus[bankAdmin.status] &&
                      UserStatus[bankAdmin.status].label,
                  )}
                  className="me-3 mb-3 px-5"
                />
              </div>
            )}
          </div>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="firstName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module={prefix}
                field="lastName"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <MailFormField
                showLabel
                type="email"
                module={prefix}
                field="email"
                required={true}
                emptyErrorMessage={t("forms.errors.mandatory")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={12}>
              {editMode ? (
                <SelectFormField
                  key="bank_admin_form_profiles"
                  showLabel
                  module={prefix}
                  field="profile"
                  required={true}
                  options={ADMINS_PROFILES}
                />
              ) : (
                <SelectFormField
                  key="bank_admin_form_profiles_readonly"
                  showLabel
                  module={prefix}
                  field="profile"
                  required={true}
                  readOnly={true}
                  options={ADMINS_PROFILES}
                />
              )}
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-end flex-wrap">
            {editMode && setEditMode && (
              <>
                <Popconfirm
                  title={t("forms.actions.modals.cancel.content")}
                  okText={t("buttons.yes")}
                  cancelText={t("buttons.no")}
                  onConfirm={() => {
                    setEditMode(false);
                  }}
                  placement="topRight"
                  icon={<QuestionCircleOutlined />}
                  className="mt-2"
                >
                  <Button>
                    <StopOutlined /> {t("buttons.cancel")}
                  </Button>
                </Popconfirm>
              </>
            )}
            {editMode ? (
              <BasicButton
                type="submit"
                variant="primary"
                icon={<SaveOutlined />}
                text={t("buttons.save")}
                isLoading={loading}
                className="ms-2 mt-2"
              />
            ) : userId ? (
              <>
                {setEditMode && (
                  <>
                    {userId != currentUser?.id &&
                      (UserStatusEnum.ACTIVE === bankAdmin?.status ? (
                        <BasicButton
                          variant="danger"
                          icon={<StopOutlined />}
                          text={t("buttons.disable")}
                          onClick={updateUserStatus}
                          className="ms-2 mt-2"
                        />
                      ) : (
                        <BasicButton
                          variant="success"
                          icon={<CheckOutlined />}
                          text={t("buttons.enable")}
                          onClick={updateUserStatus}
                          className="ms-2 mt-2"
                        />
                      ))}
                    <BasicButton
                      variant="primary"
                      icon={<EditOutlined />}
                      text={t("buttons.edit")}
                      onClick={() => setEditMode(true)}
                      className="ms-2 mt-2"
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </Form>
      ) : (
        <div>{t("users.errors.not-found")}</div>
      )}
    </>
  );
};
export default BankAdminForm;
