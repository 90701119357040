import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

type Props = {
  content: string | ReactNode;
  type?: "danger" | "warning" | "info";
  onClose: () => void;
};

const BasicBanner = (props: Props): JSX.Element => {
  const { content, type = "danger", onClose } = props;
  const { t } = useTranslation();
  return (
    <div id="banner" className={`alert-${type} banner`}>
      <div className="container">
        <i className="fa fa-times close" onClick={onClose} />
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="my-2">{content}</div>
          <BasicButton
            className="float-right small my-2"
            text={t("banners.buttons.ok")}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicBanner;
