import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restDetailsHandler,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { MerchantSearchCriteria } from "@state/merchants/dto/request/merchant.search.criteria";
import { MerchantItemResponseDto } from "@state/merchants/dto/response/merchant.item.response.dto";
import { MerchantDetailResponseDto } from "@state/merchants/dto/response/merchant.detail.response.dto";

const baseUrl = configuration.backendBaseUrl + "/admin";
const merchantsUrl = baseUrl + "/merchants";

export const requestGetMerchants = createEffect({
  handler: restListHandlerPagination<
    Pageable<MerchantItemResponseDto>,
    MerchantSearchCriteria
  >(merchantsUrl),
});

export const requestGetMerchant = createEffect({
  handler: restDetailsHandler<MerchantDetailResponseDto>(merchantsUrl),
});
