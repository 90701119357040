import { ROUTES } from "@utils/Routes";
import {
  ClockCircleOutlined,
  GroupOutlined,
  HomeOutlined,
  TeamOutlined,
  ShopOutlined,
  UnorderedListOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { ProfileEnum } from "@utils/enums/profile.enum";

export const MENU = [
  {
    key: "home",
    link: ROUTES.admin.home.generate(),
    icon: <HomeOutlined />,
    text: "menu.home",
    profiles: [],
  },
  {
    key: "components",
    link: ROUTES.admin.components.generate(),
    icon: <GroupOutlined />,
    text: "menu.components",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
  {
    key: "users",
    link: ROUTES.admin.users.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.users",
    profiles: [ProfileEnum.SUPER_ADMIN, ProfileEnum.USERS_MANAGER],
  },
  {
    key: "employees",
    link: ROUTES.merchant.employees.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.employees",
    profiles: [ProfileEnum.MERCHANT, ProfileEnum.EMPLOYEES_MANAGER],
  },
  {
    key: "bankAdmins",
    link: ROUTES.bankAdmins.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.bankAdmins",
    profiles: [ProfileEnum.BANK_ADMIN],
  },
  {
    key: "merchants",
    link: ROUTES.merchant.list.generate(),
    icon: <ShopOutlined />,
    text: "menu.merchants",
    profiles: [ProfileEnum.BANK_ADMIN, ProfileEnum.REPORTING],
  },
  {
    key: "employeesManagers",
    link: ROUTES.employeesManager.list.generate(),
    icon: <ShopOutlined />,
    text: "menu.employeesManagers",
    profiles: [ProfileEnum.BANK_ADMIN, ProfileEnum.REPORTING],
  },
  {
    key: "records",
    link: ROUTES.records.list.generate(),
    icon: <FolderOutlined />,
    text: "menu.records",
    profiles: [ProfileEnum.BANK_ADMIN, ProfileEnum.REPORTING],
  },
  {
    key: "tasks",
    link: ROUTES.admin.tasks.list.generate(),
    icon: <ClockCircleOutlined />,
    text: "menu.tasks",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
  {
    key: "endpoints",
    link: ROUTES.admin.endpoints.generate(),
    icon: <UnorderedListOutlined />,
    text: "menu.endpoints",
    profiles: [ProfileEnum.SUPER_ADMIN],
  },
] as {
  key: string;
  link?: string;
  icon?: JSX.Element;
  text: string;
  profiles: ProfileEnum[];
  children: {
    key: string;
    link: string;
    icon?: JSX.Element;
    text: string;
    profiles: ProfileEnum[];
  }[];
}[];
