import { OptionType } from "@type/form/field.types";

/* eslint-disable @typescript-eslint/naming-convention */
export enum ProfileEnum {
  SUPER_ADMIN = "SUPER_ADMIN",
  USERS_MANAGER = "USERS_MANAGER",
  GUEST = "GUEST",
  MERCHANT = "MERCHANT",
  EMPLOYEES_MANAGER = "EMPLOYEES_MANAGER",
  EMPLOYEE = "EMPLOYEE",
  BANK_ADMIN = "BANK_ADMIN",
  REPORTING = "REPORTING",
}

export const PROFILES = [
  {
    value: ProfileEnum.SUPER_ADMIN,
    label: "users.labels.profiles.SUPER_ADMIN",
  },
  {
    value: ProfileEnum.USERS_MANAGER,
    label: "users.labels.profiles.USERS_MANAGER",
  },
  {
    value: ProfileEnum.MERCHANT,
    label: "users.labels.profiles.MERCHANT",
  },
  {
    value: ProfileEnum.EMPLOYEE,
    label: "users.labels.profiles.EMPLOYEE",
  },
  {
    value: ProfileEnum.BANK_ADMIN,
    label: "users.labels.profiles.BANK_ADMIN",
  },
  {
    value: ProfileEnum.REPORTING,
    label: "users.labels.profiles.REPORTING",
  },
  {
    value: ProfileEnum.GUEST,
    label: "users.labels.profiles.GUEST",
  },
] as OptionType[];

export const EMPLOYEES_PROFILES = [
  {
    value: ProfileEnum.EMPLOYEES_MANAGER,
    label: "users.labels.profiles.EMPLOYEES_MANAGER",
  },
  {
    value: ProfileEnum.EMPLOYEE,
    label: "users.labels.profiles.EMPLOYEE",
  },
] as OptionType[];

export const ADMINS_PROFILES = [
  {
    value: ProfileEnum.BANK_ADMIN,
    label: "users.labels.profiles.BANK_ADMIN",
  },
  {
    value: ProfileEnum.REPORTING,
    label: "users.labels.profiles.REPORTING",
  },
] as OptionType[];
