import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { EmployeesManagerItemResponseDto } from "./response/employees.manager.response.dto";
import { EmployeesManagerSearchCriteria } from "./request/employees.manager.search.criteria";
import { EmployeeSaveRequestDto } from "@state/employees/dto/request/employee.save.request.dto";
import { EmployeeDetailResponseDto } from "@state/employees/dto/response/employee.detail.response.dto";

const baseUrl = configuration.backendBaseUrl + "/admin";
const employeesManagerUrl = baseUrl + "/employeesManagers";

export const requestGetEmployeesManagers = createEffect({
  handler: restListHandlerPagination<
    Pageable<EmployeesManagerItemResponseDto>,
    EmployeesManagerSearchCriteria
  >(employeesManagerUrl),
});

export const requestGetEmployeesManager = createEffect({
  handler: restDetailsHandler<EmployeeDetailResponseDto>(employeesManagerUrl),
});

export const requestCreateEmployeesManager = createEffect({
  handler: restCreationHandler<
    EmployeeSaveRequestDto,
    EmployeeDetailResponseDto
  >(employeesManagerUrl),
});

export const requestUpdateEmployeesManager = createEffect({
  handler: restPatchHandler<EmployeeSaveRequestDto, EmployeeDetailResponseDto>(
    employeesManagerUrl,
  ),
});
