enum RoutePrefixes {
  BACKOFFICE = "/bo",
  USERS = "/users",
  ACCOUNT = "/account",
  EMPLOYEES = "/employees",
  EMPLOYEESMANAGERS = "/employeesManagers",
  BANKADMINS = "/bankAdmins",
  RECORDS = "/records",
  MERCHANTS = "/merchants",
}

export const ROUTES = {
  public: {
    home: {
      generate: (): string => "/",
    },
  },
  admin: {
    home: {
      generate: (): string => `${RoutePrefixes.BACKOFFICE}`,
    },
    account: {
      infos: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.ACCOUNT}/me`,
      },
      updatePassword: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.ACCOUNT}/me/update-password`,
      },
    },
    components: {
      generate: (): string => `${RoutePrefixes.BACKOFFICE}/components`,
    },
    endpoints: {
      generate: (): string => `${RoutePrefixes.BACKOFFICE}/endpoints`,
    },
    events: {
      list: {
        generate: (): string => `${RoutePrefixes.BACKOFFICE}/events`,
      },
    },
    users: {
      add: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.USERS}/add`,
      },
      details: {
        generate: (id: string): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.USERS}/${id}`,
      },
      imports: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.USERS}/import`,
      },
      list: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.USERS}`,
      },
    },
    tasks: {
      list: {
        generate: (): string => `${RoutePrefixes.BACKOFFICE}/tasks`,
      },
    },
  },
  merchant: {
    employees: {
      list: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.EMPLOYEES}`,
      },
      add: {
        generate: (): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.EMPLOYEES}/add`,
      },
      details: {
        generate: (id: string): string =>
          `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.EMPLOYEES}/${id}`,
      },
    },
    list: {
      generate: (): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.MERCHANTS}`,
    },
  },
  employeesManager: {
    list: {
      generate: (): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.EMPLOYEESMANAGERS}`,
    },
    add: {
      generate: (): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.EMPLOYEESMANAGERS}/add`,
    },
    details: {
      generate: (id: string): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.EMPLOYEESMANAGERS}/${id}`,
    },
  },
  records: {
    list: {
      generate: (): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.RECORDS}`,
    },
    details: {
      generate: (id: string): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.RECORDS}/${id}`,
    },
  },
  bankAdmins: {
    list: {
      generate: (): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.BANKADMINS}`,
    },
    add: {
      generate: (): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.BANKADMINS}/add`,
    },
    details: {
      generate: (id: string): string =>
        `${RoutePrefixes.BACKOFFICE}${RoutePrefixes.BANKADMINS}/${id}`,
    },
  },
  auth: {
    login: {
      generate: (options?: { callback: string }): string =>
        `/login${options && options.callback ? "?c=" + options.callback : ""}`,
    },
    forgotPassword: {
      generate: (): string => "/forgot-password",
    },
    initPassword: {
      generate: (): string => "/init-password",
    },
    resetPassword: {
      generate: (): string => "/reset-password",
    },
    registration: {
      generate: (): string => "/registration",
    },
    registrationConfirmation: {
      generate: (): string => "/registration/confirm",
    },
    uLink: {
      generate: (): string => "/u-link",
    },
  },
  cookie: {
    generate: (): string => "/cookies",
  },
  maintenance: {
    generate: (): string => "/maintenance",
  },
  unreachable: {
    generate: (): string => "/unreachable",
  },
  e2e: {
    users: {
      generate: (): string => "/e2e/users",
    },
  },
};
