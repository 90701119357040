import configuration from "@utils/Config";
import { restListHandlerPagination } from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { EventItemResponseDto } from "@state/events/dto/response/event.item.response.dto";
import { EventSearchCriteria } from "@state/events/dto/request/event.search.criteria";
import { createEffect } from "effector";

const baseUrl = configuration.backendBaseUrl;
const eventsUrl = baseUrl + "/events/";

export const requestGetEvents = createEffect({
  handler: restListHandlerPagination<
    Pageable<EventItemResponseDto>,
    EventSearchCriteria
  >(eventsUrl),
});
