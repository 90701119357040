import React from "react";
import { FeatureDetailsResponseDto } from "@state/app/response/feature.details.response.dto";
import { useTranslation } from "react-i18next";

export interface Props {
  feature: FeatureDetailsResponseDto;
}

const FeatureLabelTag: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { feature } = props;

  return (
    <>
      <div className="text-primary font-weight-bold">
        {t(`users.labels.features.${feature.code}`)}
      </div>
    </>
  );
};

export default FeatureLabelTag;
