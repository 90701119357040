import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { authentifiedStore } from "@state/auth/AuthStore";
import MyAccountForm from "@views/users/details/MyAccountForm";
import { ROUTES } from "@utils/Routes";

const MyAccountScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  const currentUser = authentifiedStore.getState()?.user;

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t(editMode ? "users.edit.title" : "users.details.title")}
      returnRoute={ROUTES.admin.home.generate()}
    >
      <>
        {currentUser ? (
          <MyAccountForm
            originAuthUser={currentUser}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        ) : (
          <></>
        )}
      </>
    </PageLayout>
  );
};

export default MyAccountScreen;
