import { FunctionComponent } from "react";

interface BasicTagProps {
  label: string;
  variant?: string;
  className?: string;
  dataTest?: string;
}

const BasicTag: FunctionComponent<BasicTagProps> = ({
  label,
  variant = "",
  className = "",
  dataTest = "tag",
}) => {
  return (
    <div
      className={`app-tag tag-${variant} ${className}`}
      data-test={dataTest}
      data-alt={`${label || "basic-tag"}`}
      style={{ textAlign: "center" }}
    >
      {label}
    </div>
  );
};

export default BasicTag;
