import { FunctionComponent } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { DASHBOARD_MENU } from "@utils/enums/dashboard.enum";
import DashboardCard from "@components/cards/DashboardCard";
import { isProfileAmong } from "@utils/profile-helper";
import { ProfileEnum } from "@utils/enums/profile.enum";

const DashboardScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const profile: ProfileEnum | undefined =
    authentifiedContext?.user?.profile || undefined;

  return (
    <PageLayout container noContentInCard={false} title={t("dashboard.title")}>
      <>
        <div className="text-center">{t("dashboard.introduction")}</div>
        <div className="d-flex align-items-center justify-content-center flex-wrap mt-2">
          {profile &&
            DASHBOARD_MENU.filter((item) =>
              isProfileAmong(profile, item.profiles),
            ).map((item) => (
              <DashboardCard
                key={item.key}
                link={item.link}
                icon={item.icon}
                text={item.text}
              />
            ))}
        </div>
      </>
    </PageLayout>
  );
};

export default DashboardScreen;
