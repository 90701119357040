import { FunctionComponent } from "react";
import UpdatePasswordForm from "@components/forms/UpdatePasswordForm";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@utils/Routes";

const UpdatePasswordScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t("reset-password.title")}
      returnRoute={ROUTES.admin.account.infos.generate()}
    >
      <UpdatePasswordForm />
    </PageLayout>
  );
};

export default UpdatePasswordScreen;
